import { ButtonNew, Card, Chip, Icon, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { Link, createRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  type DropResult,
  Droppable,
  type DroppableProvided,
} from "react-beautiful-dnd";

import { importPortfolioRoute } from "./import-portfolio";
import { whatsNextRoute } from "./whats-next";

import { updateUserProfile } from "../../../services/userProfile";
import { requiresAuth } from "../../../utils/auth";
import { assetForUser } from "../../../utils/imageHandler";
import { useCurrentUserProfile } from "../../../utils/queries/userProfile";
import { getEmbedURL } from "../../../utils/validation";
import { BottomNavigation } from "../components/BottomNavigation";
import { onboardingRoute } from "../route";

export const reviewPortfolioRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "review-portfolio",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const { data: profile } = useCurrentUserProfile();
    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });

    const [projects, setProjects] = useState(profile?.highlights);

    useEffect(() => {
      setProjects(profile?.highlights);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.highlights]);

    if (!profile) return null;

    const handleDrop = (droppedItem: DropResult) => {
      if (!droppedItem.destination) {
        return;
      }
      const highlights = [...(projects ?? [])];
      const [reorderedItem] = highlights.splice(droppedItem.source.index, 1);

      if (droppedItem.destination) {
        highlights.splice(droppedItem.destination.index, 0, reorderedItem);
      }
      setProjects(highlights);
      profileMutation.mutate({ highlights });
    };
    return (
      <div className="flex h-dvh flex-col">
        <div className="grid size-full overflow-auto p-6 md:px-10 md:py-20">
          <div className="mx-auto mt-1 md:max-w-4xl">
            <Typography
              weight="bold"
              className="text-center text-4xl md:text-6xl"
            >
              Review your Imported Projects
            </Typography>
            <div className="mt-6 grid gap-6">
              <Typography size="lg" className="text-center">
                Here&apos;s the lineup we&apos;ve pulled in! Take a look, and
                remember, this is just your launchpad. Any fine-tuning,
                additions, or omissions can be done once you&apos;re settled in
                your new dashboard.
              </Typography>

              <Typography size="lg" className="text-center">
                Can&apos;t find a project? You&apos;ll be able to add it once
                you&apos;re in.
              </Typography>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="project-order">
                  {(provided: DroppableProvided) => (
                    <div
                      className="grid gap-7 pb-40"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {projects?.map((project, i) => (
                        <Draggable
                          key={project.title + i}
                          draggableId={project.title + i}
                          index={i}
                        >
                          {(providedDrag: DraggableProvided) => (
                            <div
                              ref={providedDrag.innerRef}
                              {...providedDrag.dragHandleProps}
                              {...providedDrag.draggableProps}
                              className="flex gap-3"
                            >
                              <div className="w-full">
                                <Card
                                  size="sm"
                                  type="outlined"
                                  className="relative inline-grid min-w-full rounded-2xl"
                                  uninteractive={true}
                                >
                                  <div className="flex justify-between">
                                    <Typography
                                      weight="bold"
                                      size="2xl"
                                      className="line-clamp-1"
                                    >
                                      {project.title}
                                    </Typography>
                                    <ButtonNew
                                      variant="ghost"
                                      theme="primary"
                                      onClick={() => {
                                        const highlights = [
                                          ...(projects ?? []),
                                        ];
                                        highlights.splice(i, 1);
                                        setProjects(highlights);
                                        profileMutation.mutate({ highlights });
                                      }}
                                    >
                                      <Icon.XCircle size={24} />
                                    </ButtonNew>
                                  </div>
                                  <div className="flex flex-col gap-6 md:flex-row">
                                    {!project.assets[0] ? (
                                      <div className="flex h-[223px] w-[400px] bg-carbon-200" />
                                    ) : project.assets[0].type === "video" ? (
                                      <iframe
                                        width="400"
                                        height="223"
                                        src={getEmbedURL(
                                          project.assets[0].source,
                                        )}
                                        title={project.assets[0].source}
                                      />
                                    ) : (
                                      <img
                                        src={assetForUser(
                                          project.assets[0].source,
                                          400,
                                          223,
                                        )}
                                        alt="Uploaded content"
                                        className="aspect-video h-[223px] w-[400px]"
                                      />
                                    )}
                                    <div className="grid gap-6">
                                      <div>
                                        <Typography size="xl" weight="medium">
                                          Deliverables and Skills
                                        </Typography>
                                        <div className="mt-2 flex flex-wrap gap-2">
                                          {[
                                            ...(project?.deliverables ?? []),
                                            ...(project?.skills ?? []),
                                          ].length > 0 ? (
                                            [
                                              ...(project?.deliverables ?? []),
                                              ...(project?.skills ?? []),
                                            ]?.map((s, i) => (
                                              <Chip
                                                key={`.${i}.${s}`}
                                                variant="primary-outlined"
                                                label={s}
                                              />
                                            ))
                                          ) : (
                                            <Chip
                                              variant="primary-outlined"
                                              label="N/A"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <Typography size="xl" weight="medium">
                                          Industry
                                        </Typography>
                                        <div className="mt-2 flex flex-wrap gap-2">
                                          <Chip
                                            variant="primary-outlined"
                                            label={project?.industry ?? "N/A"}
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <Typography size="xl" weight="medium">
                                          Brand
                                        </Typography>
                                        <div className="mt-2 flex flex-wrap gap-2">
                                          <Chip
                                            variant="primary-outlined"
                                            label={project?.company ?? "N/A"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>

        <BottomNavigation activeStep={3}>
          <div className="flex items-center justify-between p-6">
            <Link to={importPortfolioRoute.to}>
              <ButtonNew size="sm" variant="ghost">
                Back
              </ButtonNew>
            </Link>

            <nav className="flex gap-4">
              <Link to={whatsNextRoute.to}>
                <ButtonNew size="sm">Finish</ButtonNew>
              </Link>
            </nav>
          </div>
        </BottomNavigation>
      </div>
    );
  },
});
