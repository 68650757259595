// We discriminate the `node` prop before applying the remaining props to the
// resulting tag. Therefore, `node` is always defined, but never used.

import { Heading, Text } from "@radix-ui/themes";
import cx from "classnames";
import { type FC, type PropsWithChildren } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import rehypeColorChip from "../plugins/rehype-color-chip";
import rehypeSuperscript from "../plugins/rehype-superscript";

export const Markdown: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <ReactMarkdown
    rehypePlugins={[rehypeRaw, remarkGfm, rehypeColorChip, rehypeSuperscript]}
    className={cx("whitespace-pre-wrap [word-break:break-word]", className)}
    components={{
      h1: ({ node, ...props }) => (
        <Heading as="h1" size="6" {...props} color={undefined} />
      ),
      h2: ({ node, ...props }) => (
        <Heading as="h2" size="5" {...props} color={undefined} />
      ),
      h3: ({ node, ...props }) => (
        <Heading as="h3" size="4" {...props} color={undefined} />
      ),
      h4: ({ node, ...props }) => (
        <Heading as="h4" size="3" {...props} color={undefined} />
      ),
      h5: ({ node, ...props }) => (
        <Heading as="h5" size="2" {...props} color={undefined} />
      ),
      h6: ({ node, ...props }) => (
        <Heading as="h6" size="1" {...props} color={undefined} />
      ),
      strong: ({ node, ...props }) => (
        <Text as="span" weight="bold" {...props} color={undefined} />
      ),
      em: ({ node, ...props }) => (
        <Text as="span" className="italic" {...props} color={undefined} />
      ),
      //@ts-expect-error TS2322: different default prop types, but this is fully compatible
      del: ({ node, ...props }) => <Text {...props} />,
      p: ({ node, ...props }) => (
        <Text as="p" size="3" {...props} color={undefined} />
      ),
      li: ({ node, ...props }) => (
        <li>
          <Text as="span" {...props} color={undefined} />
        </li>
      ),
      ul: ({ node, ...props }) => (
        <ul className="list-disc whitespace-normal pl-4 text-base" {...props} />
      ),
      ol: ({ node, ...props }) => (
        <ol
          className="list-decimal whitespace-normal pl-5 text-base"
          {...props}
        />
      ),
      a: ({ node, ...props }) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a className="puntt-link underline" target="_blank" {...props} />
      ),
      pre: ({ node, ...props }) => (
        <pre className="max-w-56 overflow-auto" {...props} />
      ),
      code: ({ node, ...props }) => (
        <Text size="3" {...props} color={undefined} />
      ),
      hr: ({ node, ...props }) => (
        <hr className="border-carbon-300" {...props} />
      ),
      blockquote: ({ node, ...props }) => (
        <blockquote
          className="whitespace-wrap border-l border-l-carbon-300 pl-6"
          {...props}
        />
      ),
    }}
  >
    {children as string}
  </ReactMarkdown>
);
