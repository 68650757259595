import {
  baseTicketCommentSchema,
  GetTicketCommentsResponse,
} from "@mg/schemas/src/christo/catalyst";
import {
  EnterpriseProfileType,
  TicketCommentDisposition,
} from "@mg/schemas/src/commons";
import {
  type Editor,
  type RecordsDiff,
  type TLRecord,
  type TLSerializedStore,
  type TLShape,
  type TLStoreSnapshot,
  createShapeId,
  isShape,
  type TLShapeId,
  JsonValue,
} from "tldraw";

import { CommentShape } from "../../routes/tickets/components/CommentTool/CommentUtil";
import { isNil } from "../fp";
import { useAppSelector } from "../hooks";
import { useGetUsers } from "../queries/users";
import { store } from "../store";

export function useGetVisibleUsers(
  dataOnly: false,
): ReturnType<typeof useGetUsers>;
export function useGetVisibleUsers(
  dataOnly?: true,
): ReturnType<typeof useGetUsers>["data"];
export function useGetVisibleUsers(dataOnly = true) {
  const user = useAppSelector((state) => state.auth.value);

  const roles = [
    EnterpriseProfileType.ADMIN,
    EnterpriseProfileType.OPS,
    EnterpriseProfileType.CATALYST_CREATIVE,
    EnterpriseProfileType.CATALYST_MARKETING,
    EnterpriseProfileType.CATALYST_REQUESTER,
  ];
  if (user?.role === EnterpriseProfileType.MEANINGFUL_GIGS) {
    roles.push(EnterpriseProfileType.MEANINGFUL_GIGS);
  }

  const mutation = useGetUsers(roles);
  return dataOnly ? mutation?.data : mutation;
}

export const createAndSelectCommentShape = ({
  x,
  y,
  editor,
  originalCoords,
  zoom,
}: {
  x: number;
  y: number;
  editor: Editor;
  originalCoords?: { x?: number; y?: number };
  zoom: number;
}) => {
  const avatarShapeId = createShapeId();
  const commentShapeId = createShapeId();

  editor.createShapes([
    {
      id: avatarShapeId,
      type: "comment-avatar",
      x: originalCoords?.x ?? x,
      y: (originalCoords?.y ?? y) - 25 / zoom,
      props: {
        h: 25,
        w: 25,
      },
      meta: {
        linkedCommentId: commentShapeId,
      },
    },
    {
      id: commentShapeId,
      type: "comment",
      x,
      y: y,
      meta: {
        originalCoords:
          originalCoords?.x != null || originalCoords?.y != null
            ? JSON.stringify(originalCoords)
            : false,
        linkedAvatarId: avatarShapeId,
      },
      props: {
        h: 25,
        w: 300,
      },
    },
  ]);
  editor.setSelectedShapes([avatarShapeId, commentShapeId]);
};

/**
 * Determines whether the comment (box or pin) should be visible on the canvas.
 */
export function isCommentShapeVisible(
  shape: CommentShape,
  userId?: string,
  isPin = false,
) {
  const user = store.getState().auth.value;
  const isSuperUser = user?.role === "ai" || user?.role === "meaningful-gigs";

  // Try block because we have a JSON.parse in here.
  try {
    // If there is no top-level comment, that means we are drafting a new
    // comment.
    if (typeof shape.meta?.comment !== "string") return true;

    const commentObj = JSON.parse(shape.meta.comment);
    const comment = baseTicketCommentSchema.parse(commentObj);

    if (isPin) {
      const isCommentDismissed =
        comment.disposition === TicketCommentDisposition.DISMISSED;

      if (!isSuperUser && isCommentDismissed) return false;
    }

    const createdById = String(
      typeof comment.createdBy === "object"
        ? comment.createdBy._id
        : comment.createdBy,
    );

    return !comment.isPending || createdById === String(userId);
  } catch {
    return true;
  }
}

function getPinCommentId(pin: TLShape): TLShapeId | undefined {
  const comment = pin.meta.comment;
  if (!comment || typeof comment !== "string") return undefined;
  try {
    // TODO: parse with Zod
    const parsed = JSON.parse(comment) as JsonValue;
    if (typeof parsed !== "object" || isNil(parsed) || !("_id" in parsed)) {
      return undefined;
    }
    return parsed._id as TLShapeId;
  } catch {
    return undefined;
  }
}

export function placeComments(
  store: TLStoreSnapshot | TLSerializedStore,
  editor: Editor,
  comments: GetTicketCommentsResponse,
  versionId: string,
) {
  if (!("store" in store) || isNil(store.store)) return;
  const START_MARK = "placeComments() start";
  const END_MARK = "placeComments() end";

  performance.mark(START_MARK);

  // It's possible that some comment shapes and comment avatar pin shapes were
  // saved to the board on older documents before we had shape filtering over
  // Yjs. In this case, we want to play with the pins that exist, and just
  // re-attach their comment boxes accordingly.
  //
  // We can make several assumptions at this point:
  //   1. Comment pins are highly likely (but not required) to have a
  //      `linkedCommentId` on their `meta`. This is used to link the comment
  //      avatar pin to the comment box.
  //   2. It is required that all comment avatar pins must also have their
  //      associated comment box shapes on the canvas present. If the box does
  //      not exist on the canvas, we can find which box shapes do not exist,
  //      and create new shapes with those existing shapeIds.
  //   3. Not all comments for the current board have their pins already on the
  //      canvas. For example, the current board may have 8 total comments, but
  //      there are only 5 pins on the board. Therefore, we need to back-fill
  //      those missing comments.
  //   4. There might be a pin that references a comment that does not exist in
  //      comments array for the ticket. We can call these "orphaned" comments.
  //
  // TODO: handle dismissed and resolved comments.

  const detail: Record<string, unknown> = {};

  // Disposition check should happen before calling this function and pass the
  // filtered comments array into this function.
  const currentVersionComments = comments.filter(
    (c) => c.boardId === versionId,
  );
  detail.currentVersionComments = currentVersionComments;
  const existingPins = Object.values(store.store).filter(
    (record): record is TLShape => {
      return isShape(record) && record.type === "comment-avatar";
    },
  );
  detail.existingPins = existingPins;
  const orphanedPins = existingPins.filter(
    (pin) =>
      !currentVersionComments.find(
        (comment) => comment._id === getPinCommentId(pin),
      ),
  );
  detail.orphanedPins = orphanedPins;
  const unorphanedPins = existingPins.filter((pin) =>
    currentVersionComments.find(
      (comment) => comment._id === getPinCommentId(pin),
    ),
  );
  detail.unorphanedPins = unorphanedPins;

  // Find any comments that don't have pins yet
  const commentsWithoutPins = currentVersionComments.filter(
    (comment) =>
      !unorphanedPins.find((pin) => comment._id === getPinCommentId(pin)) &&
      !isNil(comment.x) &&
      !isNil(comment.y),
  );
  detail.commentsWithoutPins = commentsWithoutPins;

  editor.run(() => {
    // Delete orphaned pins
    if (orphanedPins.length > 0) {
      editor.deleteShapes(orphanedPins.map((pin) => pin.id));
    }

    // For the remaining unorphaned pins that don't have boxes, add the boxes.
    const existingBoxes = Object.values(store.store).filter(
      (record): record is TLShape => {
        return isShape(record) && record.type === "comment";
      },
    );
    detail.existingBoxes = existingBoxes;
    const pinsWithoutBoxes = unorphanedPins.filter(
      (pin) =>
        !existingBoxes.find((box) => box.id === pin.meta.linkedCommentId),
    );
    detail.pinsWithoutBoxes = pinsWithoutBoxes;

    if (pinsWithoutBoxes.length > 0) {
      const shapesToCreate = pinsWithoutBoxes.map((pin) => ({
        id: pin.meta.linkedCommentId as TLShapeId,
        type: "comment",
        x: pin.x + 25,
        y: pin.y,
        meta: {
          linkedAvatarId: pin.id,
          comment: pin.meta.comment,
        },
        props: {
          h: 25,
          w: 300,
        },
      }));
      editor.createShapes(shapesToCreate);
      detail.createdBoxes = shapesToCreate;
    }

    // For the comments that don't have pins yet, add their pins and boxes
    if (commentsWithoutPins.length > 0) {
      const shapesToCreate = commentsWithoutPins.flatMap((comment) => {
        const avatarShapeId = createShapeId();
        const commentShapeId = createShapeId();

        return [
          {
            id: avatarShapeId,
            type: "comment-avatar",
            x: comment.x,
            y: comment.y,
            props: {
              h: 25,
              w: 25,
            },
            meta: {
              linkedCommentId: commentShapeId,
              comment: JSON.stringify(comment),
            },
          },
          {
            id: commentShapeId,
            type: "comment",
            x: comment.x! + 25,
            y: comment.y,
            meta: {
              linkedAvatarId: avatarShapeId,
              comment: JSON.stringify(comment),
            },
            props: {
              h: 25,
              w: 300,
            },
          },
        ];
      });

      editor.createShapes(shapesToCreate);
      detail.createdPins = shapesToCreate;
    }
  });

  performance.mark(END_MARK);

  const measure = performance.measure("Place comments measure", {
    detail,
    start: START_MARK,
    end: END_MARK,
  });

  console.debug(measure);
}

export function stripCommentsFromUpdate(changes: RecordsDiff<TLRecord>) {
  // We do not want to modify `changes.added` or `changes.removed` as this will
  // prevent syncing from new players entering a room with other collaborators
  // already on it.

  const updated = Object.fromEntries(
    Object.entries(changes.updated ?? {}).filter(
      ([_, [from]]) =>
        isShape(from) &&
        from.type !== "comment" &&
        from.type !== "comment-avatar",
    ),
  );

  return {
    added: changes.added,
    updated: Object.keys(updated).length > 0 ? updated : {},
    removed: changes.removed,
  };
}
