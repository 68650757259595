import { getWorkflowsResponseSchema } from "@mg/schemas/src/christo/catalyst";
import { z } from "zod";

import { AI_URL, API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getWorkflows() {
  const json = await smartFetch(`${API_URL}/private/catalyst/workflows`, {
    method: "GET",
  });

  const parsedData = getWorkflowsResponseSchema.parse(json);
  return parsedData;
}

export const askQAQuestionRequestParser = z
  .object({
    query: z.string(),
  })
  .strict();

export type AskQAQuestionRequest = z.infer<typeof askQAQuestionRequestParser>;

export const askQAQuestionResponseParser = z
  .object({
    answer: z.string(),
  })
  .strict();

export type AskQAQuestionResponse = z.infer<typeof askQAQuestionRequestParser>;

export async function askQAQuestion(payload: AskQAQuestionRequest) {
  const json = await smartFetch(
    `${AI_URL}/api/v1/qa`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true,
  );

  const parsedData = askQAQuestionResponseParser.parse(json);
  return parsedData;
}
