import { Button } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";

interface ExpandableProps {
  children: React.ReactNode;
  maxLines?: number;
  size?: "1" | "2" | "3" | "4";
  buttonText?: React.ReactNode;
  onExpand?: (
    content: React.ReactNode,
  ) => Promise<React.ReactNode> | React.ReactNode | void;
}

export function Expandable({
  children,
  maxLines = 4,
  size = "3",
  buttonText = "Click to expand...",
  onExpand,
}: ExpandableProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [content, setContent] = useState(children);

  useEffect(() => {
    const checkTruncation = () => {
      const node = containerRef.current;
      if (node) {
        setIsTruncated(node.scrollHeight > node.clientHeight);
      }
    };

    checkTruncation();

    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [children, isExpanded]);

  const handleClick = async () => {
    setIsExpanded(true);

    if (typeof onExpand === "function") {
      let result = onExpand(content);
      if (result instanceof Promise) {
        result = await result;
      }

      if (result !== undefined) {
        setContent(result);
      }
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        style={
          isExpanded
            ? { whiteSpace: "pre-wrap", minWidth: "0" }
            : {
                // Tailwind can't dynamically create classes from variables, so we need to use inline styles
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: String(maxLines),
                overflow: "hidden",
              }
        }
      >
        {content}
      </div>
      {!isExpanded && isTruncated && (
        <Button
          onClick={handleClick}
          variant="ghost"
          size={size}
          className="cursor-pointer rounded border-none text-left text-puntt-accent-12 transition-colors hover:text-puntt-accent-10 active:text-puntt-accent-11"
        >
          {buttonText}
        </Button>
      )}
    </>
  );
}
