/**
 * Generates a pseudo-random string for DOM ids. This is
 * useful when many of the same type of controlled elements
 * are visible on the current page (e.g. TextFields)
 *
 * @param {string} prefix any string
 */
export function randomId(prefix: string) {
  const id =
    import.meta.env.MODE === "development"
      ? "test"
      : (Math.random() + Math.random() + 1).toString(36).substring(2);

  return `${prefix}-${id}`;
}
