import { Box, Grid, Text } from "@radix-ui/themes";

import { ReactComponent as Wordmark } from "../../images/Logotype.svg";

export default function PunttDashboardLoader() {
  return (
    <Grid gap="2" className="animate-pulse place-content-center text-center">
      <Box>
        <Wordmark className="mx-auto" />
      </Box>

      <Text size="4">Loading&hellip;</Text>
    </Grid>
  );
}
