import {
  DotsThreeOutlineVertical,
  MagnifyingGlass,
  Plus,
} from "@phosphor-icons/react";
import {
  Box,
  Grid,
  Tabs,
  Flex,
  Button,
  TextField,
  IconButton,
  DropdownMenu,
} from "@radix-ui/themes";
import cx from "classnames";
import { useMemo, useState, useRef } from "react";

import DeleteRecordDialog from "./components/DeleteRecordDialog";
import DeleteRecordTypeDialog from "./components/DeleteRecordTypeDialog";
import { InfiniteScrollTrigger } from "./components/InfiniteScrollTrigger";
import { KnowledgeDatabaseHeader } from "./components/KnowledgeDatabaseHeader";
import { KnowledgeDatabaseTable } from "./components/KnowledgeDatabaseTable";
import RecordDialog from "./components/RecordDialog";
import RecordTypeDialog from "./components/RecordTypeDialog";
import { knowledgeDatabaseRoute } from "./route";

import { useUI } from "../../../../contexts/ui";
import {
  errorAnalyticsPayload,
  useAnalytics,
} from "../../../../utils/analytics";
import {
  canMutateKnowledgeDatabaseModels,
  canMutateKnowledgeDatabaseRecords,
} from "../../../../utils/auth";
import { debounce } from "../../../../utils/debounce";
import { isNil } from "../../../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { errorMessage } from "../../../../utils/http";
import {
  type CreateKnowledgeDataModelRequest,
  type KnowledgeDataModel,
  type KnowledgeDataRecord,
  type KnowledgeDataRecordProperty,
} from "../../../../utils/parsers/knowledge-db";
import {
  useCreateModelMutation,
  useCreateRecordMutation,
  useDeleteModelMutation,
  useDeleteRecordMutation,
  useUpdateModelMutation,
  useUpdateRecordMutation,
} from "../../../../utils/queries/knowledge-db";
import { queryClient } from "../../../../utils/queryClient";
import {
  addModel,
  addRecord,
  removeModel,
  removeRecord,
  setSchema,
  updateModel,
  updateRecord,
} from "../../../../utils/slices/knowledge-db";
export default function KnowledgeDatabaseView() {
  const { notify } = useUI();

  const { tab } = knowledgeDatabaseRoute.useSearch();
  const navigate = knowledgeDatabaseRoute.useNavigate();

  const dispatch = useAppDispatch();
  const records = useAppSelector((state) => state.knowledgeDatabase.records);
  const schema = useAppSelector((state) => state.knowledgeDatabase.schema);
  const modelsCount = useAppSelector(
    (state) => state.knowledgeDatabase.models.length,
  );
  // We already check in route.ts, it's not possible to get here and have
  // model be undefined.
  const model = useAppSelector(
    (state) => state.knowledgeDatabase.models.find((m) => m._id === tab)!,
  );

  const createModelMutation = useCreateModelMutation();
  const updateModelMutation = useUpdateModelMutation();
  const deleteModelMutation = useDeleteModelMutation();
  const createRecordMutation = useCreateRecordMutation();
  const deleteRecordMutation = useDeleteRecordMutation();
  const editRecordMutation = useUpdateRecordMutation();

  const analytics = useAnalytics("Knowledge Database");

  const [createModelDialogOpen, setCreateModelDialogOpen] = useState(false);
  const [updateModelDialogOpen, setUpdateModelDialogOpen] = useState(false);
  const [deleteModelDialogOpen, setDeleteModelDialogOpen] = useState(false);
  const [createRecordDialogOpen, setCreateRecordDialogOpen] = useState(false);
  const [editingRecordId, setEditingRecordId] = useState<string>();
  const [deletingRecordId, setDeletingRecordId] = useState<string>();

  function handleCreateModel(model: CreateKnowledgeDataModelRequest) {
    const start = performance.now();

    createModelMutation.mutate(model, {
      async onSuccess(data) {
        if (isNil(data)) return;

        analytics.capture("model_create_success", {
          data,
          duration: performance.now() - start,
          totalModels: modelsCount + 1,
        });

        dispatch(addModel(data));
        setCreateModelDialogOpen(false);

        await queryClient.refetchQueries({
          queryKey: ["knowledge-database-models"],
        });

        navigate({
          search: {
            tab: data._id,
            pageSize: 100,
          },
        });
      },
      async onError(err) {
        const message = await errorMessage(err);
        notify({
          variant: "error",
          title: "Unable to create new model",
          message,
        });

        analytics.capture("model_create_failure", {
          ...(err instanceof Response
            ? { error_message: message }
            : errorAnalyticsPayload(err)),
          duration: performance.now() - start,
          totalModels: modelsCount,
        });
      },
    });
  }

  function handleUpdateModel(model: KnowledgeDataModel) {
    const start = performance.now();

    updateModelMutation.mutate(model, {
      onSuccess(data) {
        if (isNil(data)) return;

        analytics.capture("model_update_success", {
          model,
          duration: performance.now() - start,
          totalModels: modelsCount,
        });

        dispatch(updateModel(data));
        dispatch(setSchema(data.properties));
        setUpdateModelDialogOpen(false);
        queryClient.removeQueries({ queryKey: ["knowledge-database-models"] });
      },
      async onError(err) {
        const message = await errorMessage(err);
        notify({
          variant: "error",
          title: "Unable to update model",
          message,
        });

        analytics.capture("model_update_failure", {
          ...(err instanceof Response
            ? { error_message: message }
            : errorAnalyticsPayload(err)),
          duration: performance.now() - start,
          totalModels: modelsCount,
        });
      },
    });
  }

  function handleDeleteModel(model: KnowledgeDataModel) {
    const start = performance.now();

    deleteModelMutation.mutate(model._id, {
      onSuccess() {
        analytics.capture("model_delete_success", {
          model,
          duration: performance.now() - start,
          totalModels: modelsCount - 1,
        });

        dispatch(removeModel(model));
        setDeleteModelDialogOpen(false);
        queryClient.removeQueries({
          queryKey: ["knowledge-database-models"],
        });
        navigate({
          search: {
            tab: undefined,
            pageSize: 100,
          },
        });
      },
      async onError(err) {
        const message = await errorMessage(err);
        notify({
          variant: "error",
          title: "Unable to delete model",
          message,
        });

        analytics.capture("model_delete_failure", {
          ...(err instanceof Response
            ? { error_message: message }
            : errorAnalyticsPayload(err)),
          duration: performance.now() - start,
          totalModels: modelsCount,
        });
      },
    });
  }

  function handleCreateRecord(properties: KnowledgeDataRecordProperty[]) {
    const start = performance.now();

    createRecordMutation.mutate(
      { modelId: tab!, properties },
      {
        onSuccess(data) {
          if (isNil(data)) return;

          analytics.capture("record_create_success", {
            data,
            modelId: tab,
            duration: performance.now() - start,
          });

          dispatch(addRecord(data));
          setCreateRecordDialogOpen(false);
        },
        async onError(err) {
          const message = await errorMessage(err);
          notify({
            variant: "error",
            title: "Unable to create new record",
            message,
          });

          analytics.capture("record_create_failure", {
            ...(err instanceof Response
              ? { error_message: message }
              : errorAnalyticsPayload(err)),
            properties,
            modelId: tab,
            duration: performance.now() - start,
          });
        },
      },
    );
  }

  function handleDeleteRecord(record: KnowledgeDataRecord) {
    const start = performance.now();

    deleteRecordMutation.mutate(
      { modelId: tab!, recordId: record._id },
      {
        onSuccess() {
          analytics.capture("record_delete_success", {
            record,
            modelId: tab,
            duration: performance.now() - start,
          });

          dispatch(removeRecord(record));
          setEditingRecordId(undefined);
        },
        async onError(err) {
          const message = await errorMessage(err);
          notify({
            variant: "error",
            title: "Unable to delete record",
            message,
          });

          analytics.capture("record_delete_failure", {
            ...(err instanceof Response
              ? { error_message: message }
              : errorAnalyticsPayload(err)),
            modelId: tab,
            record,
            duration: performance.now() - start,
          });
        },
      },
    );
  }

  function handleUpdateRecord(record: KnowledgeDataRecord) {
    const start = performance.now();

    editRecordMutation.mutate(
      { modelId: tab!, record },
      {
        onSuccess(data) {
          if (isNil(data)) return;

          analytics.capture("record_update_success", {
            data,
            modelId: tab,
            duration: performance.now() - start,
          });

          dispatch(updateRecord(data));
          setEditingRecordId(undefined);
        },
        async onError(err) {
          const message = await errorMessage(err);
          notify({
            variant: "error",
            title: "Unable to update record",
            message,
          });

          analytics.capture("record_update_failure", {
            ...(err instanceof Response
              ? { error_message: message }
              : errorAnalyticsPayload(err)),
            record,
            modelId: tab,
            duration: performance.now() - start,
          });
        },
      },
    );
  }

  return (
    <>
      {/* Dialogs go here */}

      {/* Record types */}
      <RecordTypeDialog
        type="create"
        open={createModelDialogOpen}
        onOpenChange={setCreateModelDialogOpen}
        onSubmit={handleCreateModel}
        isSubmitting={createModelMutation.isPending}
      />
      <RecordTypeDialog
        type="edit"
        initialData={model}
        open={updateModelDialogOpen}
        onOpenChange={setUpdateModelDialogOpen}
        onSubmit={handleUpdateModel}
        isSubmitting={updateModelMutation.isPending}
      />
      <DeleteRecordTypeDialog
        open={deleteModelDialogOpen}
        onOpenChange={setDeleteModelDialogOpen}
        onSubmit={handleDeleteModel}
        isSubmitting={deleteModelMutation.isPending}
        model={model}
      />

      {/* Records */}
      <RecordDialog
        type="create"
        isSubmitting={createRecordMutation.isPending}
        open={createRecordDialogOpen}
        onOpenChange={setCreateRecordDialogOpen}
        onSubmit={handleCreateRecord}
        recordTypeName={model.name}
        schema={schema!}
      />

      <DeleteRecordDialog
        isSubmitting={deleteRecordMutation.isPending}
        open={!isNil(deletingRecordId)}
        onOpenChange={() => setDeletingRecordId(undefined)}
        onSubmit={handleDeleteRecord}
        record={records.find((r) => r._id === deletingRecordId)}
      />

      <RecordDialog
        type="edit"
        initialData={records.find((r) => r._id === editingRecordId)}
        isSubmitting={editRecordMutation.isPending}
        open={!isNil(editingRecordId)}
        onOpenChange={() => setEditingRecordId(undefined)}
        onSubmit={handleUpdateRecord}
        recordTypeName={model.name}
        schema={schema!}
      />

      {/* Rest of the content goes here */}
      <Grid
        className={cx("grid-rows-[auto_auto_1fr] overflow-auto px-10 py-4")}
        gap="4"
      >
        <KnowledgeDatabaseHeader />
        <KnowledgeDatabaseTabs
          onCreateModel={() => setCreateModelDialogOpen(true)}
          onUpdateModel={() => setUpdateModelDialogOpen(true)}
          onDeleteModel={() => setDeleteModelDialogOpen(true)}
          onCreate={() => setCreateRecordDialogOpen(true)}
          onDelete={setDeletingRecordId}
          onEdit={setEditingRecordId}
        />
      </Grid>
    </>
  );
}

type KnowledgeDatabaseTabsProps = {
  onCreateModel(): void;
  onUpdateModel(): void;
  onDeleteModel(): void;
  onCreate(): void;
  onDelete(recordId: string): void;
  onEdit(recordId: string): void;
};

function KnowledgeDatabaseTabs(props: KnowledgeDatabaseTabsProps) {
  const {
    onCreateModel,
    onUpdateModel,
    onDeleteModel,
    onCreate,
    onDelete,
    onEdit,
  } = props;
  const { tab, pageSize, q } = knowledgeDatabaseRoute.useSearch();
  const navigate = knowledgeDatabaseRoute.useNavigate();

  const models = useAppSelector((state) => state.knowledgeDatabase.models);
  const currentModel = models.find((m) => m._id === tab)!;
  const schema = useAppSelector((state) => state.knowledgeDatabase.schema);
  const records = useAppSelector((state) => state.knowledgeDatabase.records);
  const hasMore = useAppSelector((state) => state.knowledgeDatabase.hasMore);
  const handleSearch = useMemo(
    () =>
      debounce((q: string) => {
        navigate({
          search(prev) {
            return {
              ...prev,
              q,
            };
          },
          replace: true,
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const canMutateRecord = canMutateKnowledgeDatabaseRecords();
  const canMutateModel = canMutateKnowledgeDatabaseModels();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleFilterClick = (fieldName: string) => {
    if (!searchInputRef.current) return;

    const currentValue = searchInputRef.current.value;
    const filterText = `${fieldName}=`;

    // If there's existing text and it doesn't end with a space, add a space
    const newValue = currentValue
      ? currentValue + (currentValue.endsWith(" ") ? "" : " ") + filterText
      : filterText;

    searchInputRef.current.value = newValue;
    searchInputRef.current.focus();
    handleSearch(newValue);
  };

  return (
    <Tabs.Root
      value={tab}
      onValueChange={(value) =>
        navigate({
          search: {
            tab: value,
            pageSize,
          },
        })
      }
    >
      <Tabs.List className="flex-wrap items-center shadow-none">
        {models.map((m) => (
          <Tabs.Trigger key={m._id} value={m._id}>
            {m.name}
          </Tabs.Trigger>
        ))}

        <DropdownMenu.Root>
          <DropdownMenu.Trigger disabled={!canMutateModel}>
            <IconButton size="1" variant="ghost" color="gray">
              <DotsThreeOutlineVertical />
            </IconButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content>
            <DropdownMenu.Item onClick={() => onCreateModel()}>
              Create Record Type
            </DropdownMenu.Item>
            <DropdownMenu.Item
              disabled={isNil(tab)}
              onClick={() => onUpdateModel()}
            >
              Edit Record Type
            </DropdownMenu.Item>
            <DropdownMenu.Item
              disabled={isNil(tab) || currentModel.isSystem}
              onClick={() => onDeleteModel()}
            >
              Delete Record Type
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Tabs.List>

      <Box pt="3">
        <Flex gap="2" className="mb-4">
          <Button
            size="1"
            variant="outline"
            onClick={onCreate}
            disabled={!canMutateRecord}
          >
            <Plus /> Create Record
          </Button>
          <TextField.Root
            placeholder="Search"
            size="1"
            variant="surface"
            onInput={({ target }) => {
              handleSearch((target as HTMLInputElement).value);
            }}
            defaultValue={q}
            ref={searchInputRef}
          >
            <TextField.Slot side="right">
              <MagnifyingGlass />
            </TextField.Slot>
          </TextField.Root>
        </Flex>
        <KnowledgeDatabaseTable
          onDelete={onDelete}
          onEdit={onEdit}
          data={records}
          onFilterClick={handleFilterClick}
          schema={schema ?? []}
        />

        <InfiniteScrollTrigger
          hasMore={hasMore}
          searchAfter={`${records[records.length - 1]?.updatedAt}-${records[records.length - 1]?._id}`}
        />
      </Box>
    </Tabs.Root>
  );
}
