// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

import ErrorComponent from "./components/ErrorComponent";
import TicketView from "./view";

import PendingComponent from "../../../../components/PendingComponent";
import { SIDEBAR_STORAGE_NAME } from "../../../../components/sidebar/SidebarProvider";
import { getFolderBreadcrumbs } from "../../../../services/folders";
import { fetchTicketComments, getProject } from "../../../../services/projects";
import { isNil } from "../../../../utils/fp";
import { waitForState } from "../../../../utils/hooks";
import { setBreadcrumbs } from "../../../../utils/slices/punttProjects";
import {
  resetTicket,
  setComments,
  setLoading,
  setSelectedVersionIndex,
  setTicket,
} from "../../../../utils/slices/ticket";
import { setDrawerOpen } from "../../../../utils/slices/ui";
import { store } from "../../../../utils/store";
import { authLayoutRoute } from "../../../auth-layout/route";

const ticketSearchParser = z
  .object({
    version: z.coerce.number().min(0).optional(),
    revIndex: z.coerce.number().optional(),
  })
  .transform((data) => {
    // I think `version` makes more sense than `revIndex` because we reverse the
    // index. We are transforming to keep some backwards compatibility for users
    // with share links that went to `revIndex`. Ideally, we could use the
    // pageId from TLDraw, but for now an index works and we can call it
    // `version`.
    if (!isNil(data.revIndex) && isNil(data.version)) {
      return { version: data.revIndex, revIndex: undefined };
    }

    delete data.revIndex;
    return data;
  });

type TicketSearch = z.infer<typeof ticketSearchParser>;

export const query = "all" as const;

export const ticketRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  validateSearch(search: Record<string, unknown>) {
    return ticketSearchParser.parse(search);
  },
  path: "tickets/$ticketId/view",
  async loader({ params, context, location }) {
    const { version } = location.search as TicketSearch;
    const { ticketId } = params;
    const { queryClient } = context;
    // demo purposes only
    if (ticketId == "editabledoc" || ticketId == "editabledoc2") {
      return {};
    }

    store.dispatch(setLoading(true));
    const [ticket, comments] = await Promise.all([
      queryClient.fetchQuery({
        queryKey: ["tickets", ticketId],
        queryFn: () => getProject(ticketId),
        staleTime: 1_000, // avoid double fetches when version is not specified; a bit naive
      }),
      queryClient.fetchQuery({
        queryKey: ["ticket-comments", ticketId, query],
        queryFn: () => fetchTicketComments({ ticketId, query }),
        staleTime: 1_000, // avoid double fetches when version is not specified; a bit naive
      }),
    ]);
    if (ticket.folder) {
      let breadcrumbs: Awaited<ReturnType<typeof getFolderBreadcrumbs>>;
      try {
        breadcrumbs = await queryClient.fetchQuery({
          queryKey: ["breadcrumbs", ticket.folder],
          queryFn: () => getFolderBreadcrumbs(ticket.folder as string),
        });
      } catch (error) {
        // In guest mode, we don't have access to breadcrumbs
        if (error instanceof Response && error.status === 401) {
          breadcrumbs = [];
        } else {
          throw error;
        }
      }
      store.dispatch(setBreadcrumbs(breadcrumbs));
    }
    // TODO: Can we also move `store` into context?
    store.dispatch(setTicket(ticket));
    store.dispatch(setComments(comments));

    // We do not enforce a version to be specified upon entry. Ideally,
    // when the page loads, we just kick you to the latest version. However, if
    // you've specified a version index in the URL search params, that should
    // take precedence and this won't override that.
    if (isNil(version) && ticket.revisionBoards.length > 0) {
      throw redirect({
        to: ticketRoute.to,
        params: { ticketId },
        search: { version: ticket.revisionBoards.length - 1 },
      });
    }

    // By this point, it is not possible for `version` to be undefined.
    store.dispatch(setSelectedVersionIndex(version!));

    // wait to render until redux has finished adding to the store
    await waitForState(store, (state) => !isNil(state.ticket.value));
    store.dispatch(setLoading(false));

    return {};
  },
  onLeave({ params, context }) {
    const { queryClient } = context;
    const { ticketId } = params;

    // TODO: Can we also move `store` into context?
    store.dispatch(setLoading(false));
    store.dispatch(resetTicket());
    queryClient.removeQueries({
      queryKey: ["ticket-comments", ticketId, query],
    });
  },
  onEnter() {
    localStorage.setItem(`app_${SIDEBAR_STORAGE_NAME}`, "false");
    store.dispatch(setDrawerOpen({ app: false }));
  },
  component: TicketView,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
});
