import { TooltipProvider } from "@radix-ui/react-tooltip";
import cx from "classnames";
import {
  type ComponentProps,
  type CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { SidebarContext } from "./useSidebar";

import { isNil } from "../../utils/fp";
import { useIsMobile } from "../useIsMobile";

type ExtendedComponentProps = ComponentProps<"div"> & {
  /**
   * An optional name to differentiate when more than one sidebar are present
   * on the page at once.
   */
  name?: string;
  /**
   * Default open state of the sidebar.
   */
  defaultOpen?: boolean;
  /**
   * Open state of the sidebar (controlled).
   */
  open?: boolean;
  /**
   * Sets open state of the sidebar (controlled).
   */
  onOpenChange?(open: boolean): void;
};

export const SIDEBAR_STORAGE_NAME = "sidebar_state";
export const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7; // 7 days
const SIDEBAR_KEYBOARD_SHORTCUT = "b";
const SIDEBAR_WIDTH = "18.75rem";
const SIDEBAR_WIDTH_ICON = "calc(3.5rem + 1px)"; // we lose 1px because of the rail

export const SidebarProvider = forwardRef<
  HTMLDivElement,
  ExtendedComponentProps
>((props, ref) => {
  const {
    name,
    defaultOpen = true,
    open: openProp,
    onOpenChange: setOpenProp,
    className,
    style,
    children,
    ...rest
  } = props;

  const isMobile = useIsMobile();
  const [openMobile, setOpenMobile] = useState(false);

  // This is the internal state of the sidebar.
  // We use `openProp` and `setOpenProp` for control from outside the component.
  const [_open, _setOpen] = useState(defaultOpen);
  const open = openProp ?? _open;
  const setOpen = useCallback(
    (value: boolean | ((value: boolean) => boolean)) => {
      const openState = typeof value === "function" ? value(open) : value;

      if (setOpenProp) {
        setOpenProp(openState);
      } else {
        _setOpen(openState);
      }

      // This sets the storage to keep the sidebar state.
      localStorage.setItem(
        !isNil(name) ? `${name}_${SIDEBAR_STORAGE_NAME}` : SIDEBAR_STORAGE_NAME,
        String(openState),
      );
    },
    [name, setOpenProp, open],
  );

  // Helper to toggle the sidebar.
  const toggleSidebar = useCallback(
    () =>
      isMobile ? setOpenMobile((open) => !open) : setOpen((open) => !open),
    [isMobile, setOpen, setOpenMobile],
  );

  // Adds a keyboard shortcut to toggle the sidebar
  useEffect(() => {
    const nonEditableInputTypes = new Set([
      "button",
      "checkbox",
      "file",
      "hidden",
      "image",
      "radio",
      "reset",
      "submit",
    ]);

    // usage: isEditableElement(event.target)
    function isEditableElement(element: unknown) {
      if (!element || !(element instanceof HTMLElement)) return false;

      if (element.matches("textarea, select")) {
        const inputElement = element as HTMLTextAreaElement;
        return !inputElement.disabled && !inputElement.readOnly;
      }

      if (element.matches("input")) {
        const inputElement = element as HTMLInputElement;
        return (
          !nonEditableInputTypes.has(inputElement.type) &&
          !inputElement.disabled &&
          !inputElement.readOnly
        );
      }

      const contentEditable = element.closest("[contenteditable]");
      if (
        contentEditable &&
        contentEditable.getAttribute("contenteditable") !== "false"
      )
        return true;

      return false;
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (
        event.key === SIDEBAR_KEYBOARD_SHORTCUT &&
        (event.metaKey || event.ctrlKey) &&
        !isEditableElement(event.target)
      ) {
        event.preventDefault();
        toggleSidebar();
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [toggleSidebar]);

  // We add a state so that we can do `data-state="expanded"` or "collapsed".
  // This makes it easier to style the sidebar with Tailwind classes.
  const state = open ? "expanded" : "collapsed";

  const contextValue = useMemo<SidebarContext>(
    () => ({
      name,
      state,
      open,
      setOpen,
      isMobile,
      openMobile,
      setOpenMobile,
      toggleSidebar,
    }),
    [
      name,
      state,
      open,
      setOpen,
      isMobile,
      openMobile,
      setOpenMobile,
      toggleSidebar,
    ],
  );

  return (
    <SidebarContext.Provider value={contextValue}>
      <TooltipProvider delayDuration={0}>
        <div
          data-testid="sidebar-wrapper"
          style={
            {
              "--sidebar-width": SIDEBAR_WIDTH,
              "--sidebar-width-icon": SIDEBAR_WIDTH_ICON,
              ...style,
            } as CSSProperties
          }
          className={cx(
            "group/sidebar-wrapper flex min-h-svh w-full has-[[data-variant=inset]]:bg-base-white",
            className,
          )}
          ref={ref}
          {...rest}
        >
          {children}
        </div>
      </TooltipProvider>
    </SidebarContext.Provider>
  );
});

SidebarProvider.displayName = "SidebarProvider";
