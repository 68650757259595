// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonNew, Card, Icon, Typography } from "@mg/dali/src";
import { type Asset, type Highlight } from "@mg/schemas/src/commons";
import { useNavigate } from "@tanstack/react-router";
import cx from "classnames";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Fragment, useEffect, useReducer, useRef, useState } from "react";

import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import { type MatchedTerms, findProjectMatches } from "../../../utils/search";

export function Projects({ matchedTerms }: { matchedTerms: MatchedTerms }) {
  const creative = useAppSelector((state) => state.profile.value);
  const [sortedProjects, setSortedProjects] = useState(creative?.highlights);

  useEffect(() => {
    if (Object.keys(matchedTerms)?.some((term) => term.includes("highlight"))) {
      const tempSorted = creative?.highlights
        ?.slice()
        .map((item, idx) => {
          const projectMatchedTerms = findProjectMatches(matchedTerms, idx);
          return { ...item, matchedTerms: projectMatchedTerms };
        })
        .sort((a, b) => {
          if (a.matchedTerms && !b.matchedTerms) {
            return -1;
          } else if (!a.matchedTerms && b.matchedTerms) {
            return 1;
          }
          return 0;
        });
      setSortedProjects(tempSorted);
    } else {
      setSortedProjects(creative?.highlights);
    }
  }, [creative?.highlights, matchedTerms]);
  if (creative == null) {
    return null;
  }
  // @ts-expect-error TS2367: Backend incorrect types
  if (creative.highlights == null || creative.type === "pipeline") {
    return null;
  }

  if (creative.highlights?.length === 0) {
    return (
      <div className="flex h-full flex-col">
        <Typography size="xl" weight="medium" className="mt-4">
          This creative’s portfolio is being processed by AI. Their projects
          will be added within 1-2 days.
        </Typography>
      </div>
    );
  }

  return (
    <div className="grid gap-6" data-testid="portfolio-projects">
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {sortedProjects?.map((p, i) => <Project key={p._id} {...p} />)}
    </div>
  );
}

function Project(
  props: Highlight & {
    matchedTerms?: Record<
      string,
      { value: string; context: string; term: string }[]
    >;
  },
) {
  const navigate = useNavigate();

  function getEmbedURL(url: string) {
    let embedUrl = "";

    // Use URL constructor to parse the url
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname.includes("youtube")) {
      // Check if the URL is already an embed URL
      if (parsedUrl.pathname.includes("embed")) {
        embedUrl = url;
      } else {
        const videoId = parsedUrl.searchParams.get("v");
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (parsedUrl.hostname.includes("vimeo")) {
      // Check if the URL is already an embed URL
      if (parsedUrl.pathname.includes("video")) {
        embedUrl = url;
      } else {
        const videoId = parsedUrl.pathname.split("/")[1];
        embedUrl = `https://player.vimeo.com/video/${videoId}`;
      }
    } else {
      console.error("Invalid URL: The URL should be a YouTube or Vimeo URL.");
    }

    return embedUrl;
  }

  function renderAsset(assets?: Partial<Asset>[]) {
    if (assets == null || assets.length === 0) {
      return null;
    }
    const a = assets[0];

    if (a.type === "image") {
      return (
        <>
          <link rel="preload" as="image" href={assetForUser(a.source, 1280)} />
          <img
            src={assetForUser(a.source, 1280)}
            alt={`Designer ${a.source}`}
            style={{ width: "100%" }}
            data-testid="project-hero"
          />
        </>
      );
    } else if (a.type === "video") {
      return (
        <>
          <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
            <iframe
              src={getEmbedURL(a.source ?? "")}
              className="absolute left-0 top-0 size-full"
              title={`Designer video ${a.source}`}
              frameBorder="0"
              allowFullScreen
              data-testid="project-hero"
            />
          </div>
        </>
      );
    }
  }

  return (
    <Card
      size="sm"
      type="outlined"
      className={cx(
        "relative grid w-full cursor-pointer overflow-hidden rounded-2xl p-0",
        {
          "border-2 border-malachite-600": props.matchedTerms,
        },
      )}
      style={
        props.matchedTerms
          ? { boxShadow: "0px 0px 40px 0px rgba(153, 213, 61, 0.30) inset" }
          : {}
      }
      data-testid="portfolio-project"
      onClick={() =>
        navigate({
          // @ts-expect-error TS2322: TanStack Router types can't handle changing search params without specifying a route, but this component can be used on multiple routes
          search: (prev) => ({
            ...prev,
            proj: props._id,
          }),
        })
      }
    >
      {renderAsset(props.assets)}
      <div className="p-4">
        <Typography
          size="2xl"
          weight="bold"
          className={cx("mb-1", {
            "bg-malachite-100": props.matchedTerms?.title,
          })}
          data-testid="project-title"
        >
          {props.title}
        </Typography>
        <Typography
          size="2xl"
          className={cx({
            "bg-malachite-100 text-base-black": props.matchedTerms?.role,
            "text-carbon-600": !props.matchedTerms?.role,
          })}
          data-testid="project-role"
        >
          {props.role}
        </Typography>
      </div>
    </Card>
  );
}
