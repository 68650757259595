import cx from "classnames";
import { type ComponentProps, forwardRef } from "react";

export const SidebarFooter = forwardRef<HTMLDivElement, ComponentProps<"div">>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        data-sidebar="footer"
        className={cx("flex flex-col gap-2 p-4", className)}
        {...props}
      />
    );
  },
);
SidebarFooter.displayName = "SidebarFooter";
