import { EnterpriseProfileType } from "@mg/schemas/src/commons";
import { Dialog, Button, TextField, Flex, Text } from "@radix-ui/themes";
import { useState } from "react";

import { useUI } from "../contexts/ui";
import { useAnalytics } from "../utils/analytics";
import { useAppSelector } from "../utils/hooks";
import {
  useCreateEnterprise,
  useSwitchEnterprise,
} from "../utils/queries/enterprises";
import { BaseDialogProps } from "../utils/types/dialog";

export function CreateEnterpriseDialog(props: BaseDialogProps) {
  const { open, onOpenChange } = props;
  const setOpen = onOpenChange;
  const [enterpriseName, setEnterpriseName] = useState("");
  const createEnterpriseMutation = useCreateEnterprise();
  const { notify } = useUI();
  const posthog = useAnalytics("CreateEnterpriseDialog");
  const user = useAppSelector((state) => state.auth.value);
  const switchEnterpriseMutation = useSwitchEnterprise();

  if (user?.role !== EnterpriseProfileType.MEANINGFUL_GIGS) {
    return null;
  }

  const handleSave = async () => {
    try {
      const enterprise =
        await createEnterpriseMutation.mutateAsync(enterpriseName);
      posthog.capture("created_new_enterprise", { name: enterpriseName });
      notify({
        title: "Enterprise Created",
        message: `Successfully created enterprise: ${enterpriseName}`,
      });
      setOpen?.(false);
      switchEnterpriseMutation.mutate(enterprise._id as string, {
        onSuccess: () => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      });
    } catch (error) {
      console.error("Failed to create enterprise:", error);
      notify({
        title: "Error",
        message: "Failed to create enterprise. Please try again.",
      });
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content style={{ maxWidth: 450 }}>
        <Dialog.Title>Create new enterprise</Dialog.Title>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Flex direction="column" gap="3">
            <label htmlFor="enterprise-name">
              <Text as="div" size="2" mb="1" weight="bold">
                Enterprise name
              </Text>
              <TextField.Root
                name="enterprise-name"
                value={enterpriseName}
                onChange={(e) => setEnterpriseName(e.target.value)}
              />
            </label>
          </Flex>
          <Flex gap="3" mt="4" justify="between">
            <Dialog.Close>
              <Button type="button" variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button type="submit" disabled={!enterpriseName.trim()}>
              Save
            </Button>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
}
