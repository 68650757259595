import { z } from "zod";

import { UPLOAD_API_URL } from "../config/env";
import { posthogClient } from "../config/posthog";
import { sanitizeFilename } from "../utils/files";
import { smartFetch } from "../utils/http";
export const MAX_FILE_SIZE_MB = 16;
export const MAX_IMAGE_DIMENSION = 6000;

export const presignedPostParser = z.object({
  url: z.string().url(),
  fields: z.object({
    key: z.string(),
    bucket: z.string(),
    "X-Amz-Algorithm": z.string(),
    "X-Amz-Credential": z.string(),
    "X-Amz-Date": z.string(),
    Policy: z.string(),
    "X-Amz-Signature": z.string(),
  }),
});

export type PresignedPost = z.infer<typeof presignedPostParser>;

export async function presignedPost(
  filename: string,
  useAcceleration = false,
  fileType = "image/png",
  isRevisionFile = false,
) {
  const now = performance.now();

  posthogClient.capture("presigned_url_start", {
    filename,
    useAcceleration,
    initiatedAt: now,
  });

  try {
    const json = await smartFetch(UPLOAD_API_URL, {
      method: "POST",
      body: JSON.stringify({
        filename,
        useAcceleration,
        fileType,
        isRevisionFile,
      }),
    });

    const parsedData = presignedPostParser.parse(json);
    const newNow = performance.now();

    posthogClient.capture("presigned_url_complete", {
      response: parsedData,
      elapsed: newNow - now,
    });

    return parsedData;
  } catch (e: unknown) {
    const newNow = performance.now();

    posthogClient.capture("presigned_url_error", {
      error: (e as Error).message,
      stack: (e as Error).stack,
      elapsed: newNow - now,
    });
  }
}

type UploadToS3Payload = {
  url: string;
  payload: FormData;
};

export async function uploadToS3(payload: UploadToS3Payload) {
  // payload should already be validated by this point
  const urlParser = z.string().url();
  urlParser.parse(payload.url);
  const now = performance.now();

  const connection = (navigator as any).connection;
  const file = payload.payload.get("file") as File;
  posthogClient.capture("s3_upload_start", {
    url: payload.url,
    payload: Object.fromEntries(
      Array.from(payload.payload.entries()).filter(([key]) => key !== "file"),
    ),
    initiatedAt: now,
    key: payload.payload.get("key"),
    bucket: payload.payload.get("bucket"),
    fileName: file?.name,
    fileSizeMB: (file?.size || 0) / (1024 * 1024),
    fileType: file?.type,
    lastModified: file?.lastModified,
    fileExtension: file?.name.split(".").pop()?.toLowerCase(),
    // Add properties to report internet upload speed
    connectionType: connection?.type,
    effectiveType: connection?.effectiveType,
    downlink: connection?.downlink,
    rtt: connection?.rtt,
    saveData: connection?.saveData,
  });

  return await fetch(payload.url, {
    method: "POST",
    body: payload.payload,
  }).then(async (response) => {
    let responseText: string | undefined;
    try {
      responseText = await response.text();
    } catch {
      // Could not read response; do nothing
    }

    const analyticsPayload = {
      status: response.status,
      statusText: response.statusText,
      ok: response.ok,
      url: response.url,
      responseText,
    };

    if (!response.ok) {
      const newNow = performance.now();
      posthogClient.capture("s3_upload_error", {
        ...analyticsPayload,
        elapsed: newNow - now,
      });

      throw response;
    }

    const newNow = performance.now();

    posthogClient.capture("s3_upload_complete", {
      ...analyticsPayload,
      elapsed: newNow - now,
    });

    return undefined;
  });
}

export async function updateAvatar(file: File) {
  if (file == null) {
    console.warn("Attempting to update user avatar, but no file was provided");
    return;
  }

  const cleanName = file.name.replace(/[!'()*]/g, "");

  // TD: we might need to manually set headers here since we need auth
  // its working fine without auth
  const response = await presignedPost(cleanName);
  const { fields, url } = response as PresignedPost;

  const payload = new FormData();

  Object.keys(fields).forEach((key) =>
    payload.append(
      key,
      fields[key as keyof PresignedPost["fields"]] as string | Blob,
    ),
  );

  // set `file` last because AWS stops reading keys after it.
  payload.set("file", file, cleanName);

  await fetch(url, { body: payload, method: "POST" });

  return { fields, url };
}

export async function uploadImageAsset(
  file: File,
  downscaleLargeImages = false,
) {
  if (file == null) {
    console.warn("Attempting to upload image asset, but no file was provided");
    return;
  }
  // TODO: Remove image downscaling once we show a loading indicator for files uploaded onto an existing canvas.
  if (downscaleLargeImages && file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    if (file.type.includes("image")) {
      const startTime = performance.now();
      const initialSize = file.size;

      file = await scaleImage(file);

      posthogClient.capture("image_scaled_down", {
        filename: file.name,
        initialSize,
        finalSize: file.size,
        durationMS: performance.now() - startTime,
      });
      console.warn(
        `Downscaling ${file.name} to reduce the file size from ${Math.round(file.size / 1024 / 1024)}MB.`,
      );
    } else {
      console.warn(
        `File ${file.name} is large (${Math.round(file.size / 1024 / 1024)}MB).`,
      );
    }
  }
  const cleanName = sanitizeFilename(file.name);

  const data = await presignedPost(cleanName, true, file.type, true).catch(
    () => {
      throw new Error("An error occurred while uploading the file");
    },
  );
  if (!data) {
    throw new Error("An error occurred while uploading the file");
  }
  const payload = new FormData();

  payload.append("url", data.url);

  Object.keys(data.fields).forEach((key) =>
    payload.append(key, data.fields[key as keyof typeof data.fields]),
  );

  // set `file` last because AWS stops reading keys after it.
  payload.set("file", file, cleanName);

  return payload;
}

export const screenshotURLFromPayload = (payload: FormData) => {
  const screenshotUrl = `https://static.puntt.ai/${payload?.get("key")}`;
  return screenshotUrl;
};

// Organize logic used to modify the payload and pull out the URL
export const urlAndPayloadFromPayload = (payload: FormData) => {
  const url = payload?.get("url") as string;
  payload.delete("url");
  return { url, payload };
};

function scaleImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      // Calculate new dimensions if the image is too large
      if (width > MAX_IMAGE_DIMENSION || height > MAX_IMAGE_DIMENSION) {
        if (width > height) {
          height = Math.floor((height / width) * MAX_IMAGE_DIMENSION);
          width = MAX_IMAGE_DIMENSION;
        } else {
          width = Math.floor((width / height) * MAX_IMAGE_DIMENSION);
          height = MAX_IMAGE_DIMENSION;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          URL.revokeObjectURL(url);
          if (blob) {
            resolve(new File([blob], file.name, { type: file.type }));
          } else {
            reject(new Error("Canvas toBlob failed"));
          }
        }, file.type);
      } else {
        URL.revokeObjectURL(url); // Release the object URL on failure
        reject(new Error("Failed to get canvas context"));
      }
    };

    img.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error("Image load error"));
    };

    img.src = url;
  });
}
