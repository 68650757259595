import {
  CaretRight,
  Gear,
  House,
  Kanban,
  UserCircle,
} from "@phosphor-icons/react";
import {
  Avatar,
  Box,
  DropdownMenu,
  Flex,
  IconButton,
  Link,
  Text,
} from "@radix-ui/themes";
import { Link as NavLink } from "@tanstack/react-router";
import { usePostHog } from "posthog-js/react";

import { FolderTreeView } from "./AppDrawer";
import EnterpriseSwitcher from "./EnterpriseSwitcher";
import { Sidebar } from "./sidebar/Sidebar";
import { SidebarContent } from "./sidebar/SidebarContent";
import { SidebarFooter } from "./sidebar/SidebarFooter";
import { SidebarGroup } from "./sidebar/SidebarGroup";
import { SidebarHeader } from "./sidebar/SidebarHeader";
import { SidebarMenu } from "./sidebar/SidebarMenu";
import { SidebarMenuButton } from "./sidebar/SidebarMenuButton";
import { SidebarMenuItem } from "./sidebar/SidebarMenuItem";
import { SidebarProvider } from "./sidebar/SidebarProvider";
import { SidebarRail } from "./sidebar/SidebarRail";
import { SidebarTrigger } from "./sidebar/SidebarTrigger";
import { useSidebar } from "./sidebar/useSidebar";

import { ReactComponent as Logo } from "../images/Logo-blue.svg";
import { ReactComponent as Wordmark } from "../images/Logotype.svg";
import { indexRoute } from "../routes/index/route";
import { knowledgeDatabaseRoute } from "../routes/settings/routes/knowledge-database/route";
import { knowledgeSettingRoute } from "../routes/settings/routes/knowledgeSettingRoute";
import { usersRoute } from "../routes/settings/routes/usersRoute";
import { ticketsRoute } from "../routes/tickets/route";
import { useAnalytics } from "../utils/analytics";
import {
  canManageBrandGuidelines,
  canAccessKnowledgeDatabase,
  canManageUsers,
  canAccessPunttDashboard,
} from "../utils/auth";
import { getNameInitials } from "../utils/constants";
import { isNil } from "../utils/fp";
import { useAppDispatch, useAppSelector } from "../utils/hooks";
import { assetForUser } from "../utils/imageHandler";
import { logout } from "../utils/slices/auth";
import { setDrawerOpen } from "../utils/slices/ui";

const navLinkClasses =
  "transition-colors [&_button]:transition-colors [&_button]:hover:bg-puntt-neutral-grayA-2";
const activeNavLinkClasses =
  "[&_button]:!bg-puntt-neutral-grayA-3 [&_button]:hover:!bg-puntt-neutral-grayA-4 [&_button]:active:!bg-puntt-neutral-grayA-5";

export function AppSidebar() {
  const posthog = usePostHog();
  const analytics = useAnalytics("AppSidebar");

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.value)!;
  const sidebarOpen = useAppSelector((state) => state.ui.drawerOpen.app);

  return (
    <SidebarProvider
      name="app"
      open={sidebarOpen}
      onOpenChange={(open) => {
        analytics.capture(`sidebar_${open ? "opened" : "closed"}`);
        dispatch(setDrawerOpen({ app: open }));
      }}
    >
      <Sidebar data-testid="app-sidebar">
        <AppSidebarHeader />

        <SidebarContent data-testid="app-sidebar-content">
          <Flex direction="column" className="flex-1">
            <SidebarGroup>
              <SidebarMenu>
                <SidebarMenuItem
                  data-testid="sidebar-menu-item-dashboard"
                  data-auth-trigger="dashboard"
                  hidden={!canAccessPunttDashboard()}
                >
                  <Link size="3" weight="medium" className="puntt-link" asChild>
                    <NavLink
                      to={indexRoute.to}
                      className={navLinkClasses}
                      activeProps={{
                        className: activeNavLinkClasses,
                      }}
                    >
                      <SidebarMenuButton
                        tooltip={
                          <Flex align="center" className="gap-3">
                            <House
                              size={18}
                              color="rgb(var(--puntt-blue-12))"
                            />
                            <Text
                              size="3"
                              className="puntt-link translate-y-1/2 opacity-0 duration-150 group-hover/menu-item-tooltip:translate-y-0 group-hover/menu-item-tooltip:opacity-100"
                            >
                              Home
                            </Text>
                          </Flex>
                        }
                      >
                        <Box className="shrink-0 p-1">
                          <House size={18} color="rgb(var(--puntt-blue-12))" />
                        </Box>
                        Home
                      </SidebarMenuButton>
                    </NavLink>
                  </Link>
                </SidebarMenuItem>

                <SidebarMenuItem
                  data-testid="sidebar-menu-item-projects"
                  data-auth-trigger="projects"
                >
                  <Link size="3" weight="medium" className="puntt-link" asChild>
                    <NavLink
                      to={ticketsRoute.to}
                      className={navLinkClasses}
                      activeProps={{
                        className: activeNavLinkClasses,
                      }}
                    >
                      <SidebarMenuButton
                        tooltip={
                          <Flex align="center" className="gap-3">
                            <Kanban
                              size={18}
                              color="rgb(var(--puntt-blue-12))"
                            />
                            <Text
                              size="3"
                              className="puntt-link translate-y-1/2 opacity-0 duration-150 group-hover/menu-item-tooltip:translate-y-0 group-hover/menu-item-tooltip:opacity-100"
                            >
                              Projects
                            </Text>
                          </Flex>
                        }
                      >
                        <Box className="shrink-0 p-1">
                          <Kanban size={18} color="rgb(var(--puntt-blue-12))" />
                        </Box>
                        Projects
                      </SidebarMenuButton>
                    </NavLink>
                  </Link>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroup>

            <FolderTreeView />
          </Flex>
        </SidebarContent>

        <SidebarFooter data-testid="app-sidebar-footer">
          <SidebarMenu>
            <SidebarMenuItem
              hidden={
                !canAccessKnowledgeDatabase() &&
                !canManageBrandGuidelines() &&
                !canManageUsers()
              }
            >
              <DropdownMenu.Root>
                <DropdownMenu.Trigger className="group/dropdown-menu">
                  <SidebarMenuButton className="group/menu-button !p-0">
                    <IconButton
                      size="1"
                      color="gray"
                      variant="outline"
                      asChild
                      className="p-1"
                    >
                      <Gear />
                    </IconButton>

                    <Flex
                      width="100%"
                      className="rounded-md px-1.5 py-1 transition-colors group-hover/menu-button:bg-puntt-neutral-gray-3 group-data-[state=open]/dropdown-menu:bg-puntt-neutral-gray-4"
                    >
                      <Text
                        color="gray"
                        size="1"
                        weight="medium"
                        className="flex-1 text-left"
                      >
                        Settings
                      </Text>

                      <CaretRight />
                    </Flex>
                  </SidebarMenuButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content side="right">
                  <DropdownMenu.Item hidden={!canManageBrandGuidelines()}>
                    <NavLink to={knowledgeSettingRoute.to}>
                      Knowledge Settings
                    </NavLink>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item hidden={!canAccessKnowledgeDatabase()}>
                    <NavLink
                      to={knowledgeDatabaseRoute.to}
                      search={{ pageSize: 100 }}
                    >
                      Knowledge Database
                    </NavLink>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item hidden={!canManageUsers()}>
                    <NavLink to={usersRoute.to}>Manage Users</NavLink>
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </SidebarMenuItem>

            <SidebarMenuItem
              hidden={!isNil(user.email)}
              data-auth-trigger="login-signup-button"
            >
              <SidebarMenuButton className="group/menu-button !p-0">
                <IconButton
                  size="1"
                  color="blue"
                  variant="outline"
                  asChild
                  className="p-1"
                >
                  <UserCircle />
                </IconButton>

                <Flex
                  width="100%"
                  className="rounded-md px-1.5 py-1 transition-colors group-hover/menu-button:bg-puntt-neutral-gray-3"
                >
                  <Text
                    color="blue"
                    size="1"
                    weight="medium"
                    className="flex-1 whitespace-nowrap text-left"
                  >
                    Log In or Sign Up
                  </Text>
                </Flex>
              </SidebarMenuButton>
            </SidebarMenuItem>

            <SidebarMenuItem hidden={isNil(user.email)}>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger className="group/dropdown-menu">
                  <SidebarMenuButton className="group/menu-button !p-0">
                    <Avatar
                      variant="solid"
                      size="1"
                      src={assetForUser(user.avatar)}
                      fallback={getNameInitials(user.name)}
                    />

                    <Flex
                      width="100%"
                      className="rounded-md px-1.5 py-1 transition-colors group-hover/menu-button:bg-puntt-neutral-gray-3 group-data-[state=open]/dropdown-menu:bg-puntt-neutral-gray-4"
                    >
                      <Text
                        color="gray"
                        size="1"
                        weight="medium"
                        className="flex-1 text-left"
                      >
                        Account
                      </Text>

                      <CaretRight />
                    </Flex>
                  </SidebarMenuButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content side="right">
                  <DropdownMenu.Item
                    onClick={() => {
                      dispatch(logout());
                      posthog.reset();
                    }}
                  >
                    Log Out
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
        <SidebarRail data-testid="app-sidebar-rail" />
        <SidebarTrigger data-testid="app-sidebar-trigger" />
      </Sidebar>
    </SidebarProvider>
  );
}

function AppSidebarHeader() {
  const user = useAppSelector((state) => state.auth.value);
  const { open } = useSidebar();

  return (
    <SidebarHeader
      data-testid="app-sidebar-header"
      data-auth-trigger="sidebar-header"
    >
      <Flex gap="4" justify={open ? "between" : "center"} align="center">
        <NavLink to={indexRoute.to}>
          {open ? (
            <Wordmark className="h-4 max-w-max" />
          ) : (
            <Logo className="size-6" />
          )}
        </NavLink>

        {open && !isNil(user?.email) ? <EnterpriseSwitcher /> : null}
      </Flex>
    </SidebarHeader>
  );
}
