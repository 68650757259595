import { ButtonNew, Typography } from "@mg/dali/src";
// eslint-disable-next-line import/named
import { Link, useNavigate } from "@tanstack/react-router";

import { BottomNavigation } from "./BottomNavigation";

import { useAppSelector } from "../../../utils/hooks";
import { defineRoleRoute } from "../routes/define-role";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Step1Explanation({ onNext }: { onNext?: (s: number) => void }) {
  const user = useAppSelector((state) => state.auth.value);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  return (
    <div className="flex h-dvh flex-col">
      <div className="grid h-full overflow-auto md:grid-cols-2">
        <div className="grid auto-rows-max gap-4 p-6 md:px-10 md:py-20">
          <Typography weight="bold" className="text-left text-4xl md:text-6xl">
            Just 4 easy steps to get started with {user?.company}
          </Typography>
        </div>
        <div className="flex flex-col gap-6 p-6 md:gap-12 md:px-10 md:py-20">
          <div className="grid gap-4 md:gap-6">
            <Typography weight="bold" className="text-2xl md:text-3xl">
              Step 1: Your Role
            </Typography>
            <Typography size="xl">
              Are you an individual talent or a creative powerhouse team?
              Selecting your role helps us tailor your {user?.company}{" "}
              experience to match you with the best opportunities.
            </Typography>
          </div>
          <div className="grid gap-4 md:gap-6">
            <Typography weight="bold" className="text-2xl md:text-3xl">
              Step 2: Name & Avatar
            </Typography>
            <Typography size="xl">
              Give us your name and upload a profile picture to personalize your
              {user?.company} network presence.
            </Typography>
          </div>
          <div className="grid gap-4 md:gap-6">
            <Typography weight="bold" className="text-2xl md:text-3xl">
              Step 3 - AI-Powered Portfolio Sync
            </Typography>
            <Typography size="xl">
              Link your Behance or personal site, and let our AI do the rest.
              Instantly import and polish your portfolio with zero fuss.
            </Typography>
          </div>
          <div className="grid gap-4 md:gap-6">
            <Typography weight="bold" className="text-2xl md:text-3xl">
              Step 4 - Project Approval
            </Typography>
            <Typography size="xl">
              We&apos;ll guide you in adding your first project. Simply review
              to ensure your portfolio represents your best.
            </Typography>
          </div>
        </div>
      </div>

      <BottomNavigation activeStep={0}>
        <div className="flex items-center justify-between p-6">
          <div />

          <nav className="flex gap-4">
            <Link to={defineRoleRoute.to} className="puntt-link">
              <ButtonNew size="sm">Get Started</ButtonNew>
            </Link>
          </nav>
        </div>
      </BottomNavigation>
    </div>
  );
}
