import { type FolderTree } from "@mg/schemas/src/commons";
import { ArrowLineLeft, CaretRight, CaretDown } from "@phosphor-icons/react";
import * as Accordion from "@radix-ui/react-accordion";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  Button,
  Grid,
  IconButton,
  Link,
  ScrollArea,
  Text,
} from "@radix-ui/themes";
import {
  // eslint-disable-next-line import/named
  Link as NavLink,
  useLoaderData,
  useRouter,
  useRouterState,
  useSearch,
} from "@tanstack/react-router";
import cx from "classnames";
import { usePostHog } from "posthog-js/react";
import { useMemo, useState } from "react";

import { CreateFolderDialog } from "./dialogs/CreateFolderDialog";
import { useSidebar } from "./sidebar/useSidebar";

import { useUI } from "../contexts/ui";
import { authLayoutRoute } from "../routes/auth-layout/route";
import { ticketsRoute } from "../routes/tickets/route";
import { errorAnalyticsPayload } from "../utils/analytics";
import { batch } from "../utils/batch";
import { isNil } from "../utils/fp";
import { useAppSelector } from "../utils/hooks";
import { errorMessage } from "../utils/http";
import {
  useEnterprises,
  useSwitchEnterprise,
} from "../utils/queries/enterprises";
import {
  useEditProjectMutation,
  useUpdateFolderMutation,
} from "../utils/queries/projects";

type AppDrawerProps = {
  open: boolean;
  onClose(): void;
};

export function AppDrawer(props: AppDrawerProps) {
  const { open, onClose } = props;
  const posthog = usePostHog();
  const { data: enterprises } = useEnterprises();
  const switchEnterpriseMutation = useSwitchEnterprise();
  const enterpriseId = useAppSelector(
    (state) => state.auth.value?.enterpriseId,
  );
  const userEmail = useAppSelector((state) => state.auth.value?.email);

  const currentEnterprise = enterprises?.find((e) => e._id === enterpriseId);

  return (
    <aside
      className={cx(
        "fixed left-0 z-[4] h-screen w-80 bg-base-white shadow-lg transition-transform",
        {
          "-translate-x-80": !open,
        },
      )}
    >
      <header className="flex items-center justify-between border-b border-b-puntt-neutral-gray-6 p-4">
        <IconButton size="1" variant="soft" color="gray" onClick={onClose}>
          <ArrowLineLeft />
        </IconButton>

        {!enterprises?.length ? null : enterprises?.length === 1 ? (
          <Text size="2" weight="medium" className="ml-2">
            {currentEnterprise?.name}
          </Text>
        ) : (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <Button
                variant="soft"
                color="gray"
                className="ml-2"
                loading={switchEnterpriseMutation.isPending}
              >
                <Text
                  size="2"
                  weight="medium"
                  className="max-w-[180px] truncate"
                >
                  {currentEnterprise?.name || "Select Enterprise"}
                </Text>
                <CaretDown className="ml-1" />
              </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="min-w-[220px] rounded-md bg-base-white p-1 shadow-lg"
                sideOffset={5}
              >
                {enterprises
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((enterprise) => (
                    <DropdownMenu.Item
                      key={enterprise._id}
                      data-id={enterprise._id}
                      className={cx(
                        "flex items-center rounded-sm px-2 py-1.5 outline-none",
                        "hover:bg-puntt-neutral-gray-4 focus:bg-puntt-neutral-gray-4",
                        {
                          "bg-puntt-neutral-gray-2":
                            enterprise._id === enterpriseId,
                          "cursor-pointer": enterprise._id !== enterpriseId,
                        },
                      )}
                      onClick={() => {
                        if (enterprise._id !== enterpriseId && enterprise._id) {
                          const start = performance.now();

                          switchEnterpriseMutation.mutate(enterprise._id, {
                            onSuccess: () => {
                              posthog.capture("switch_enterprise", {
                                from: enterpriseId,
                                to: enterprise._id,
                                duration_ms: performance.now() - start,
                                user: userEmail,
                              });
                              location.reload();
                            },
                          });
                        }
                      }}
                    >
                      <Text size="2">{enterprise.name}</Text>
                    </DropdownMenu.Item>
                  ))}
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        )}
      </header>

      <FolderTreeView />
    </aside>
  );
}

export function FolderTreeView() {
  const { folderId } = useSearch({ strict: false });
  const { folderTree } = useLoaderData({ from: authLayoutRoute.id });
  const { matches } = useRouterState();
  const isOnTicketsPage = matches.find(
    (m) => m.pathname.includes("/tickets") || m.pathname.includes("/ticket"),
  );

  const { open } = useSidebar();

  const user = useAppSelector((state) => state.auth.value);

  const sortedFolderTree = useMemo(() => {
    return folderTree.sort((a, b) => a.name.localeCompare(b.name));
  }, [folderTree]);

  const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);

  if (!open || isNil(user?.email) || !isOnTicketsPage) return null;

  function renderTree() {
    if (!sortedFolderTree.length) {
      return (
        <Grid gap="4">
          <Button
            size="2"
            variant="outline"
            color="gray"
            mx="auto"
            className="max-w-max"
            onClick={() => setCreateFolderDialogOpen(true)}
          >
            Add Your First Project
          </Button>
        </Grid>
      );
    }

    return (
      <>
        <ScrollArea
          type="auto"
          scrollbars="both"
          className="h-[calc(100vh_-_262px)] grow p-2"
        >
          {sortedFolderTree.map((folder) => (
            <Folder key={folder._id} {...folder} />
          ))}
        </ScrollArea>
      </>
    );
  }

  return (
    <>
      <CreateFolderDialog
        open={createFolderDialogOpen}
        onOpenChange={setCreateFolderDialogOpen}
        folderId={folderId}
      />
      {renderTree()}
    </>
  );
}

function Folder(props: FolderTree) {
  const { _id, name, folders = [] } = props;
  const [isOver, setIsOver] = useState(false);
  const posthog = usePostHog();
  const { notify } = useUI();
  const updateFolderMutation = useUpdateFolderMutation();
  const editProjectMutation = useEditProjectMutation();
  const router = useRouter();
  const onMoveToFolder = async (
    draggedIds: { _id: string; isFolder: boolean }[],
    targetFolderId: string,
  ) => {
    const startTime = performance.now();

    try {
      await batch(
        draggedIds,
        1,
        async ([dragTargetId]) => {
          if (dragTargetId.isFolder) {
            await updateFolderMutation.mutateAsync({
              _id: dragTargetId._id,
              parentFolder: targetFolderId,
            });
          } else {
            await editProjectMutation.mutateAsync({
              _id: dragTargetId._id,
              folder: targetFolderId,
            });
          }
        },
        5,
      );
      router.invalidate();
      posthog.capture("tickets_dragged_to_sidebar", {
        folder_id: targetFolderId,
        dropped_ids: draggedIds,
        duration_ms: performance.now() - startTime,
        num_dropped: draggedIds.length,
      });
    } catch (error) {
      const message = await errorMessage(error);
      notify({
        title: "Error dragging to folder",
        message,
      });
      posthog.capture("tickets_dragged_to_sidebar_error", {
        folder_id: targetFolderId,
        num_dropped: draggedIds.length,
        dropped_ids: draggedIds,
        duration_ms: performance.now() - startTime,
        ...errorAnalyticsPayload(error),
      });
    }

    router.invalidate();
  };
  return (
    <Accordion.Root
      type="single"
      collapsible
      onDragOver={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        setIsOver(true);
      }}
      onDragLeave={() => {
        setIsOver(false);
      }}
      onDrop={(e) => {
        e.preventDefault();
        setIsOver(false);

        try {
          const parsed = JSON.parse(e.dataTransfer.getData("application/json"));

          if (parsed != null) {
            // Only proceed with the move if we're not dropping onto the same folder
            const isDropOnSelf = (
              parsed as {
                _id: string;
                isFolder: boolean;
              }[]
            ).some((item: { _id: string }) => item._id === props._id);
            if (!isDropOnSelf) {
              //@ts-expect-error TS doesn't know that parsed is an array
              onMoveToFolder(parsed, props._id);
            }
          }
        } catch (err) {
          console.error("Invalid drag data", err);
        }
      }}
    >
      <Accordion.Item value={_id as string}>
        <Accordion.Header
          className={cx(
            "flex items-center gap-4 rounded-md px-2 py-1.5 hover:bg-puntt-neutral-gray-4",
            {
              "rounded-lg bg-puntt-accent-10 text-base-white outline outline-1 outline-offset-2 outline-puntt-accent-8":
                isOver,
            },
          )}
        >
          <Accordion.Trigger
            asChild
            className={cx("group", { "opacity-0": !folders.length })}
          >
            <IconButton size="1" variant="ghost">
              <CaretRight
                className="transition-transform group-data-[state=open]:rotate-90"
                color="rgb(var(--puntt-neutral-gray-11))"
              />
            </IconButton>
          </Accordion.Trigger>
          <Link
            asChild
            className={cx("puntt-link max-w-[235px] truncate", {
              "!text-base-white": isOver,
            })}
            size="2"
            weight="medium"
          >
            <NavLink
              to={ticketsRoute.to}
              search={(prev) => ({ ...prev, folderId: _id })}
            >
              {name}
            </NavLink>
          </Link>
        </Accordion.Header>

        <Accordion.Content className="ml-4">
          {folders.map((folder) => (
            <Folder key={folder._id} {...folder} />
          ))}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
}
