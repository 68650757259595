import { createRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

import PunttWorkflowView from "./view";

import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
  canAccessPunttDashboard,
  requiresAuth,
} from "../../utils/auth";
import { createDefaultNetworkRouteSearch } from "../../utils/constants";
import { authLayoutRoute } from "../auth-layout/route";
import { listsRoute } from "../lists/route";
import { myNetworkRoute } from "../network/route";
import { ticketsRoute } from "../tickets/route";
import { aboutMeEditRoute } from "../userProfile/routes/AboutMe/editRoute";

export const workflowRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  validateSearch(search: Record<string, unknown>) {
    const parser = z.object({
      personas: z.array(z.string()),
      status: z.literal("done").optional(),
      folderId: z.string().optional(),
    });

    return parser.parse(search);
  },
  path: "workflow",
  beforeLoad({ search }) {
    requiresAuth();

    if (search.redirect) {
      throw redirect({ to: search.redirect });
    }

    if (!canAccessPunttDashboard()) {
      if (canAccessPuntt()) {
        throw redirect({ to: ticketsRoute.to });
      }

      if (canAccessCreativeConnect()) {
        throw redirect({
          to: myNetworkRoute.to,
          search: createDefaultNetworkRouteSearch(),
        });
      }

      if (canAccessLists()) {
        throw redirect({ to: listsRoute.to });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({ to: aboutMeEditRoute.to });
      }
    }
  },
  component: PunttWorkflowView,
});
