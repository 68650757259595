import { createRoute } from "@tanstack/react-router";

import ChatView from "./view";

import { authLayoutRoute } from "../../../auth-layout/route";

export const chatRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "chat",
  component: ChatView,
});
