import { Warning } from "@phosphor-icons/react";
import { Text, Tooltip } from "@radix-ui/themes";

import { isNil } from "../../../../../utils/fp";
import { useAppSelector } from "../../../../../utils/hooks";

const listFormatter = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

export default function MissingFontsIndicator() {
  const versions = useAppSelector((state) => state.ticket.revisions);
  const reversedVersionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );
  if (isNil(reversedVersionIndex)) return;
  const selectedVersion = versions[reversedVersionIndex];
  if (isNil(selectedVersion)) return;

  const { reviewFiles } = selectedVersion;

  if (isNil(reviewFiles)) return;

  const missingFonts = reviewFiles.reduce((fonts, file) => {
    if (file.missingFonts?.length) {
      fonts.push(...file.missingFonts);
    }

    return fonts;
  }, [] as string[]);

  if (!missingFonts.length) return;

  const sortedFonts = missingFonts.toSorted((a, b) => a.localeCompare(b));

  return (
    <Tooltip
      content={
        <Text>
          Warning: Some fonts used in this document are missing (
          {listFormatter.format(sortedFonts)}). The document may not appear
          exactly as intended. Contact Meaningful Gigs to resolve this.
        </Text>
      }
      data-testid="missing-fonts-tooltip"
    >
      <Warning
        color="var(--orange-10)"
        data-testid="missing-fonts-warning-icon"
      />
    </Tooltip>
  );
}
