// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonNew, Card, Icon, Input, Select, Typography } from "@mg/dali/src";
import { type GetPeopleResponse } from "@mg/schemas/src/christo/myNetwork";
import { useMutation, useQuery } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute, useNavigate } from "@tanstack/react-router";
import cx from "classnames";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import {
  getPendingConnectionsList,
  sendVerifiedConnection,
} from "../../../services/network";
import { completeOnboarding } from "../../../services/userProfile";
import { requiresAuth } from "../../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { queryClient } from "../../../utils/queryClient";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { derive, login } from "../../../utils/slices/auth";
import { myNetworkRoute } from "../../network/route";
import { rootRoute } from "../../root/route";

export const workedWithReviewRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "onboarding/worked-with-review",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.value);

    const navigate = useNavigate();
    const pendingConnections = useQuery({
      queryKey: ["pending-connections"],
      queryFn: getPendingConnectionsList,
    });

    const acceptConnectionMutation = useMutation({
      mutationFn: sendVerifiedConnection,
    });

    const onboardingMutation = useMutation({
      mutationKey: ["complete-onboarding-client"],
      mutationFn: completeOnboarding,
    });

    return (
      <div className="w-full bg-carbon-50 text-base-black">
        <div className="mx-auto h-dvh max-w-screen-2xl bg-base-white">
          <article className="mx-auto grid max-w-[1037px] gap-6 px-10 pb-9 pt-20">
            <Typography as="h1" size="6xl" weight="bold">
              Have you worked with them?
            </Typography>

            <Typography as="p" size="xl" weight="normal">
              Please take a few minutes to rate some of your contacts that you
              have worked with before based on their overall expertise,
              competence and experience.
            </Typography>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-3 md:gap-x-6">
              {pendingConnections.data?.map((connection) => (
                <Card
                  key={connection._id}
                  size="sm"
                  type="outlined"
                  uninteractive
                  className="flex flex-col items-center gap-2.5"
                >
                  <AvatarWithInitials
                    avatar={connection.avatar}
                    name={connection.name}
                    size={25}
                  />
                  <div className="mb-6 w-full text-center">
                    <Typography
                      as="p"
                      size="2xl"
                      weight="medium"
                      className="truncate"
                    >
                      {connection.name}
                    </Typography>
                    <Typography
                      as="p"
                      size="sm"
                      className="truncate text-carbon-600"
                    >
                      {connection.title?.length ? connection.title : "N/A"}
                    </Typography>
                  </div>
                  <button
                    className={cx(
                      "flex w-full items-center justify-center gap-2 rounded-md border-2 border-base-black px-3 py-2",
                      {
                        "border-malachite-800 bg-malachite-800 text-base-white":
                          connection.workedWith,
                      },
                    )}
                    onClick={() => {
                      acceptConnectionMutation.mutate(connection._id);
                      queryClient.setQueryData(
                        ["pending-connections"],
                        (oldData: GetPeopleResponse) => {
                          const newData = [...oldData];

                          const index = newData.findIndex(
                            (c) => c._id === connection._id,
                          );

                          if (index !== -1) {
                            newData[index] = {
                              ...newData[index],
                              workedWith: !newData[index].workedWith,
                            };
                          }

                          return newData;
                        },
                      );
                    }}
                  >
                    <Icon.Star
                      weight={connection.workedWith ? "fill" : "regular"}
                      size={16}
                      className={cx({
                        "text-base-black": !connection.workedWith,
                        "text-ochre-400": connection.workedWith,
                      })}
                    />
                    <Typography as="p" weight="medium" className="truncate">
                      I worked with {connection.name}
                    </Typography>
                  </button>
                </Card>
              ))}
            </div>
            <div className="flex justify-end">
              <ButtonNew
                size="sm"
                className="mt-6"
                onClick={() => {
                  onboardingMutation.mutateAsync().then((data) => {
                    localStorage.setItem("token", data.token);
                    sendTokenToServiceWorker();
                    dispatch(login({ token: data.token }));
                    navigate({ to: myNetworkRoute.to });
                  });
                }}
                isLoading={onboardingMutation.isPending}
              >
                Finish and go to {user?.company}&apos;s network
              </ButtonNew>
            </div>
          </article>
        </div>
      </div>
    );
  },
});
