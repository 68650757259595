import { ChatTeardropText, DotsThree } from "@phosphor-icons/react";
import { DropdownMenu, Flex, Heading, IconButton } from "@radix-ui/themes";
import { type Editor } from "@tldraw/tldraw";
import cx from "classnames";
import { useMemo } from "react";

import { Comments } from "./Comments";

import { Sidebar } from "../../../../../components/sidebar/Sidebar";
import { SidebarContent } from "../../../../../components/sidebar/SidebarContent";
import { SidebarHeader } from "../../../../../components/sidebar/SidebarHeader";
import { SidebarProvider } from "../../../../../components/sidebar/SidebarProvider";
import { SidebarRail } from "../../../../../components/sidebar/SidebarRail";
import { SidebarTrigger } from "../../../../../components/sidebar/SidebarTrigger";
import { useSidebar } from "../../../../../components/sidebar/useSidebar";
import { useAnalytics } from "../../../../../utils/analytics";
import { canShowDismissedComments } from "../../../../../utils/auth";
import { isNil } from "../../../../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import {
  setDrawerOpen,
  toggleShowDismissedComments,
  toggleShowResolvedComments,
} from "../../../../../utils/slices/ui";

type CommentDrawerProps = {
  editor?: Editor | null;
  videoRef: React.RefObject<{
    handleCommentClicked: (_seekTime: number) => void;
    duration: number;
  }>;
};

export function CommentDrawer(props: CommentDrawerProps) {
  const analytics = useAnalytics("CommentsSidebar");

  const dispatch = useAppDispatch();
  const ticket = useAppSelector((state) => state.ticket.value);

  const reversedVersionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );
  const versions = useAppSelector((state) => state.ticket.revisions);
  const sidebarOpen = useAppSelector((state) => state.ui.drawerOpen.comments);

  const currentVersion = useMemo(() => {
    return versions[reversedVersionIndex!];
  }, [reversedVersionIndex, versions]);

  if (isNil(ticket)) {
    return null;
  }

  if (currentVersion?.errorDetails?.reason) {
    return null;
  }

  return (
    <SidebarProvider
      name="comments"
      open={sidebarOpen}
      onOpenChange={(open) => {
        analytics.capture(`comments_sidebar_${open ? "opened" : "closed"}`);
        dispatch(setDrawerOpen({ comments: open }));
      }}
      className="col-start-2 row-span-2 row-start-1"
    >
      <CollapsibleCommentDrawer {...props} />
    </SidebarProvider>
  );
}

function CollapsibleCommentDrawer(passthroughProps: CommentDrawerProps) {
  const { open } = useSidebar();

  const dispatch = useAppDispatch();

  const showResolvedComments = useAppSelector(
    (state) => state.ui.showResolvedComments,
  );
  const showDismissedComments = useAppSelector(
    (state) => state.ui.showDismissedComments,
  );

  return (
    <Sidebar side="right" data-testid="comments-sidebar">
      <SidebarHeader data-testid="comments-sidebar-header">
        <Flex gap="4" align="center" justify={open ? "between" : "center"}>
          <ChatTeardropText size={18} className="shrink-0" />

          <Flex
            flexGrow="1"
            gap="4"
            align="center"
            justify="between"
            className={cx({ hidden: !open })}
          >
            <Heading
              size="3"
              weight="medium"
              data-testid="comments-sidebar-header-label"
            >
              Comments
            </Heading>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <IconButton size="1" variant="ghost" color="gray">
                  <DotsThree />
                </IconButton>
              </DropdownMenu.Trigger>

              <DropdownMenu.Content>
                <DropdownMenu.Item
                  onClick={() => dispatch(toggleShowResolvedComments())}
                >
                  {showResolvedComments ? "Hide" : "Show"} resolved comments
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onClick={() => dispatch(toggleShowDismissedComments())}
                  disabled={!canShowDismissedComments()}
                  className={cx({ hidden: !canShowDismissedComments() })}
                >
                  {showDismissedComments ? "Hide" : "Show"} dismissed comments
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Flex>
        </Flex>
      </SidebarHeader>

      <SidebarContent>
        <Comments {...passthroughProps} />
      </SidebarContent>

      <SidebarRail />
      <SidebarTrigger />
    </Sidebar>
  );
}
