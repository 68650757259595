import { Button, DropdownMenu, Text } from "@radix-ui/themes";
import { useState } from "react";

import { CreateEnterpriseDialog } from "./CreateEnterpriseDialog";

import { useAnalytics } from "../utils/analytics";
import { canAddEnterprise } from "../utils/auth";
import { useAppSelector } from "../utils/hooks";
import {
  useEnterprises,
  useSwitchEnterprise,
} from "../utils/queries/enterprises";

export default function EnterpriseSwitcher() {
  const analytics = useAnalytics("EnterpriseSwitcher");

  const enterprisesQuery = useEnterprises();
  const switchEnterpriseMutation = useSwitchEnterprise();

  const userEnterpriseId = useAppSelector(
    (state) => state.auth.value!.enterpriseId,
  );
  const userEmail = useAppSelector((state) => state.auth.value!.email);

  const [createEnterpriseDialogOpen, setCreateEnterpriseDialogOpen] =
    useState(false);

  if (enterprisesQuery.isPending || enterprisesQuery.isError) {
    return null;
  }

  const { data } = enterprisesQuery;
  const currentEnterprise = data.find((e) => e._id === userEnterpriseId)!;
  const sortedEnterprises = data.sort((a, b) => a.name.localeCompare(b.name));

  if (data.length === 1 && !canAddEnterprise()) {
    return (
      <Text size="2" weight="medium" className="truncate">
        {currentEnterprise?.name}
      </Text>
    );
  }

  function handleSwitchEnterprise(enterpriseId: string) {
    if (enterpriseId !== userEnterpriseId) {
      const start = performance.now();

      switchEnterpriseMutation.mutate(enterpriseId, {
        onSuccess: () => {
          analytics.capture("switch_enterprise", {
            from: userEnterpriseId,
            to: enterpriseId,
            duration_ms: performance.now() - start,
            user: userEmail,
          });
          location.reload();
        },
      });
    }
  }

  return (
    <>
      <CreateEnterpriseDialog
        open={createEnterpriseDialogOpen}
        onOpenChange={setCreateEnterpriseDialogOpen}
      />

      <DropdownMenu.Root>
        <DropdownMenu.Trigger
          className="flex-1"
          disabled={switchEnterpriseMutation.isPending}
          data-testid="enterprise-switcher-trigger"
        >
          <Button
            size="1"
            variant="outline"
            color="gray"
            className="min-w-0 justify-between"
            loading={switchEnterpriseMutation.isPending}
          >
            <span className="truncate">{currentEnterprise.name}</span>

            <DropdownMenu.TriggerIcon />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          align="end"
          data-testid="enterprise-switcher-dropdown-menu"
        >
          <DropdownMenu.Group data-testid="selectable-enterprise-group">
            {sortedEnterprises.map((e) => (
              <DropdownMenu.Item
                key={e._id}
                onClick={() => handleSwitchEnterprise(e._id!)}
                data-testid={`enterprise-menu-item-${e._id}`}
                color={e._id === userEnterpriseId ? "blue" : undefined}
                className="max-w-60"
              >
                <span className="truncate">{e.name}</span>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Group>

          {canAddEnterprise() && (
            <>
              <DropdownMenu.Separator />

              <DropdownMenu.Group data-testid="enterprise-switcher-actions-group">
                <DropdownMenu.Item
                  onClick={() => setCreateEnterpriseDialogOpen(true)}
                  data-testid="create-enterprise-menu-item"
                >
                  Add Enterprise
                </DropdownMenu.Item>
              </DropdownMenu.Group>
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
}
