import { Disclosure } from "@headlessui/react";
import { CreateFolderResponse } from "@mg/schemas/src/christo/catalyst";
import {
  ArrowUp,
  CaretDown,
  CaretLeft,
  CaretRight,
  CheckCircle,
  CloudArrowUp,
} from "@phosphor-icons/react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Progress,
  ScrollArea,
  Separator,
  Skeleton,
  Spinner,
  Text,
  TextField,
} from "@radix-ui/themes";
import cx from "classnames";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { createRoot } from "react-dom/client";

import { workflowRoute } from "./route";

import { Markdown } from "../../components/Markdown";
import { getProject, requestAIComment } from "../../services/projects";
import { uploadImageAsset, uploadToS3 } from "../../services/upload";
import { isNil } from "../../utils/fp";
import { renderPersonaAvatar } from "../../utils/helpers/ai-persona-avatars";
import { createCombinatorString } from "../../utils/helpers/arrays";
import { getFileExtension, renderFileIcon } from "../../utils/helpers/files";
import {
  useCreateFolderMutation,
  useProjectMutation,
  useRevisionMutation,
  useTicketVersionStatusMutation,
} from "../../utils/queries/projects";
import { queryClient } from "../../utils/queryClient";
import { generateUUID } from "../../utils/uuid";
import { ticketRoute } from "../tickets/routes/ticket/route";

const paneClasses =
  "rounded-lg bg-base-white p-4 sm:p-6 max-h-[calc(100vh_-_90px)]";

function printInitialPersonaMessage(
  personas: string[],
): Pick<ChatMessageProps, "message" | "actions"> {
  if (!personas.length) {
    return {
      message:
        "In order to review your assets, we need to better understand how to review your assets.",
      actions: [{ label: "Back to dashboard", type: "back" }],
    };
  }

  return {
    message: `To get started with this ${createCombinatorString(personas)} Review, upload any assets you'd like reviewed`,
  };
}
function insertCommentMarker(comment: {
  x: number;
  y: number;
  body: string;
  type: string;
  required: boolean;
}) {
  const documentContainer = document.querySelector(".document-container");
  if (!documentContainer) return;

  const markerContainer = document.createElement("div");
  markerContainer.style.position = "absolute";
  markerContainer.style.top = `${(comment.y * 100) / 15.265}%`;
  markerContainer.style.left = `${comment.x * 100}%`;

  documentContainer.appendChild(markerContainer);

  const root = createRoot(markerContainer);
  const avatarContainer = (
    <div
      className={cx("comment-pin rounded-[20px_20px_20px_0]", {
        "bg-base-white": !comment?.required,
        "bg-puntt-red-9": comment?.required,
      })}
      style={{
        boxShadow: `0 0 0 2px ${
          comment?.required
            ? "rgb(var(--puntt-red-9))"
            : "rgb(var(--base-white))"
        }, 0 1px 3px 2px rgba(0, 0, 0, 0.12), 0 1px 2px 2px rgba(0, 0, 0, 0.24)`,
      }}
    >
      {renderPersonaAvatar(comment.type, true)}
    </div>
  );
  root.render(avatarContainer);

  setTimeout(() => {
    markerContainer.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, 100);
}

type ReviewDocument = {
  file: File;
  messageId: string;
  ticketId: string;
  revisionId: string;
  pageKeys: []; // An array of keys where the review file images were uploaded.
  status: {
    isPending: boolean;
    isAIReady: boolean;
  };
  candidateComments: {
    persona: string;
    x: number;
    y: number;
  }[];
  finalComments: {
    persona: string;
    x: number;
    y: number;
  }[];
  done: boolean;
};

export default function PunttWorkflowView() {
  const navigate = workflowRoute.useNavigate();
  const { personas, folderId } = workflowRoute.useSearch();

  const ticketMutation = useProjectMutation();
  const revisionMutation = useRevisionMutation();
  const createFolderMutation = useCreateFolderMutation();

  const rightPanelRef = useRef<HTMLDivElement>(null);

  const [_isDemo, _setIsDemo] = useState(false);
  // This is because the right pane scroll contrainer has weird scroll behavior
  // when it only contains images.
  const [documentHeight, setDocumentHeight] = useState("100%");
  const [assetsCount, setAssetsCount] = useState(0);
  const [reviewDocuments, setReviewDocuments] = useState<ReviewDocument[]>([]);
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);
  const [chatMessages, setChatMessages] = useState<ChatMessageProps[]>([
    {
      id: generateUUID(),
      author: "theirs",
      type: "message-simple",
      ...printInitialPersonaMessage(personas),
    },
  ]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect; // height minus padding
        const titleHeight = 26;
        const totalGapHeight = 32 * 2;
        const carouselHeight = 82;
        const finalHeight =
          height - titleHeight - totalGapHeight - carouselHeight;

        setDocumentHeight(`${finalHeight}px`);
      }
    });

    if (rightPanelRef.current) {
      resizeObserver.observe(rightPanelRef.current);
    }

    return () => {
      if (rightPanelRef.current) {
        resizeObserver.unobserve(rightPanelRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (reviewDocuments.length === 0) return;

    if (reviewDocuments.map((d) => d.done).every((b) => b === true)) {
      console.table(reviewDocuments);

      setChatMessages((prev) => [
        ...prev,
        {
          id: generateUUID(),
          author: "theirs",
          type: "message-confetti",
          message:
            "All assets have been reviewed successfully! You can now view the detailed feedback for each asset.",
          actions: [{ type: "view", label: "View assets" }],
          onActionClick: () =>
            navigate({
              to: ticketRoute.to,
              params: {
                ticketId: reviewDocuments[0]?.ticketId,
              },
            }),
        },
      ]);

      handleDone();
    }
  }, [reviewDocuments]);

  function handleDone() {
    navigate({
      search(prev) {
        return { ...prev, status: "done" };
      },
    });
  }

  function handleChatMessageActionClick(
    action: ChatAction["type"],
    ticketId?: string,
  ) {
    console.debug("ticketId", ticketId);

    switch (action) {
      case "back":
        history.back();
        break;
      case "view":
        if (isNil(ticketId)) {
          throw new Error(
            "Attempted to route to a ticket, but no ID was provided",
          );
        }

        navigate({
          to: ticketRoute.to,
          params: {
            ticketId,
          },
        });
        break;
      default:
        console.warn("Unimplemented action type");
        break;
    }
  }

  function updateMessageById(
    messageId: string,
    message: Partial<ChatMessageProps>,
  ) {
    setChatMessages((prev) => {
      const messageIndex = prev.findIndex(
        (message) => message.id === messageId,
      );

      if (messageIndex !== -1) {
        const updatedMessages = [...prev];
        updatedMessages[messageIndex] = {
          ...updatedMessages[messageIndex],
          ...message,
        };
        return updatedMessages;
      }
      return prev;
    });
  }

  async function handleInitiateUpload(files?: FileList | null) {
    if (isNil(files) || files.length === 0) {
      return setChatMessages((prev) => [
        ...prev,
        {
          type: "message-simple",
          message:
            "I didn't quite catch that. Can you try uploading your assets again?",
          author: "theirs",
          id: generateUUID(),
        },
      ]);
    }

    setAssetsCount(files.length);

    let folder: CreateFolderResponse | undefined = undefined;

    if (isNil(folderId)) {
      folder = await createFolderMutation.mutateAsync({
        folder: {
          name: `${createCombinatorString(personas)} Review ${new Date().toISOString().slice(0, 10)}`,
        },
      });

      navigate({
        search(prev) {
          return { ...prev, folderId: folder!.folder._id };
        },
        replace: true,
      });
    }

    if (
      isNil(folderId) &&
      isNil((folder as CreateFolderResponse)?.folder._id)
    ) {
      throw new Error("Unable to add tickets to project as no ID exists");
    }

    const messageIds = Array.from(files).map(() => generateUUID());

    setChatMessages((prev) => [
      ...prev,
      {
        id: generateUUID(),
        author: "mine",
        message: "Please upload and review the following assets.",
        files: Array.from(files).map((f) => f.name),
        type: "message-simple",
      },
      ...(messageIds.map((id, i) => ({
        id,
        author: "theirs",
        type: "upload",
        message: "",
        uploadStatus: "uploading",
        files: [files[i].name],
      })) as ChatMessageProps[]),
    ]);

    return Promise.all(
      Array.from(files).map((file, i) =>
        handleFile(
          file,
          (folder?.folder._id ?? folderId) as string,
          messageIds[i],
        ),
      ),
    );
  }

  async function handleFile(file: File, folderId: string, messageId: string) {
    // 1: actually upload the file
    const payload = (await uploadImageAsset(file)) as FormData;

    const url = payload.get("url") as string;
    const key = payload.get("key") as string;
    payload.delete("url");

    const source = `https://static.puntt.ai/${key}`;

    await uploadToS3({ url, payload });

    // 1.a: Update the associated message's status
    updateMessageById(messageId, {
      message: "Creating ticket",
      type: "status",
    });

    // 2: create a ticket for the file.
    const ticket = await ticketMutation.mutateAsync({
      title: file.name,
      folder: folderId,
    });

    // 3: create a revision
    const revision = await revisionMutation.mutateAsync({
      ticketId: ticket._id,
      payload: {
        name: "Version 1",
        documentImportType: file.name
          .slice(file.name.lastIndexOf(".") + 1)
          .toUpperCase(),
        reviewFiles: [
          {
            source,
            type: file.type.includes("image")
              ? "image"
              : file.type.includes("video")
                ? "video"
                : "file",
          },
        ],
      },
    });
    //
    // 3.a: Update the associated message's status
    updateMessageById(messageId, {
      message: "Generating thumbnails",
      type: "status",
    });

    setReviewDocuments((prev) => [
      ...prev,
      {
        file,
        messageId,
        ticketId: ticket._id,
        revisionId: revision.boards[0]._id, // only one will be created at this time.
        pageKeys: [],
        status: {
          isPending: true,
          isAIReady: false,
        },
        candidateComments: [],
        finalComments: [],
        done: false,
      },
    ]);
  }

  const filesAdded = assetsCount > 0;

  return (
    <Grid gap="4" height="100vh" className="auto-rows-[auto_1fr]">
      {reviewDocuments.map(({ messageId, revisionId, ticketId, status }) => (
        <DocumentStatusUpdater
          key={ticketId}
          ticketId={ticketId}
          statuses={status}
          revisionId={revisionId}
          onFail={() => console.error("Max checks exceeded")}
          onReady={() => {
            updateMessageById(messageId, {
              message: "Analyzing document.",
            });

            queryClient
              .fetchQuery({
                queryKey: ["tickets", ticketId],
                queryFn: () => getProject(ticketId),
              })
              .then((ticket) => {
                setReviewDocuments((prev) => {
                  const updatedDocuments = [...prev];
                  const documentIndex = updatedDocuments.findIndex(
                    (doc) => doc.ticketId === ticketId,
                  );

                  if (documentIndex !== -1) {
                    updatedDocuments[documentIndex].status.isPending = false;
                    // @ts-expect-error TS2322: FIXME AT YOUR EARLIEST
                    // CONVENIENCE
                    updatedDocuments[documentIndex].pageKeys =
                      ticket.revisionBoards[0].thumbnails;
                  }

                  return updatedDocuments;
                });
              });
          }}
          onAIReady={() => {
            updateMessageById(messageId, {
              message: "Generating comments...",
            });

            setReviewDocuments((prev) => {
              const updatedDocuments = [...prev];
              const documentIndex = updatedDocuments.findIndex(
                (doc) => doc.ticketId === ticketId,
              );

              if (documentIndex !== -1) {
                updatedDocuments[documentIndex].status.isAIReady = true;
              }

              return updatedDocuments;
            });

            requestAIComment({
              personas,
              ticket_id: ticketId,
              board_id: revisionId,
            }).then(({ allComments, totalComments }) => {
              setReviewDocuments((prev) => {
                const updatedDocuments = [...prev];
                const documentIndex = updatedDocuments.findIndex(
                  (doc) => doc.ticketId === ticketId,
                );

                if (documentIndex !== -1) {
                  updatedDocuments[documentIndex].candidateComments =
                    allComments.map((c) => ({
                      persona: c.aiPersona!.avatar,
                      x: 0,
                      y: 0,
                    }));
                }

                return updatedDocuments;
              });

              // For each comment in allComments, update the current message's
              // `message` field at an interval of 1 second
              // For each comment in allComments, update the current message's
              // `message` field at an interval of 1 second
              allComments.forEach((comment, index) => {
                setTimeout(() => {
                  insertCommentMarker({
                    type: comment.aiPersona!.avatar,
                    required: true,
                    body: "",
                    x: Math.random(),
                    // A number between 0 and 100, evenly spaced for the number of
                    // comments
                    y: (index / allComments.length) * 15,
                  });

                  updateMessageById(messageId, {
                    message: `*Comment ${index + 1}*: ${comment.description}`,
                  });

                  if (index === allComments.length - 1) {
                    updateMessageById(messageId, {
                      // TODO: make a fake summary based on total comments, how
                      // many were required, suggested, etc. and be sure to
                      // include the personas used again just for clarity.
                      message: `Your ${createCombinatorString(personas)} Review is complete. There are ${totalComments} comments, ${allComments.filter((c) => c.isRequired).length} require your attention.`,
                      type: "status",
                    });

                    setReviewDocuments((prev) => {
                      const updatedDocuments = [...prev];
                      const documentIndex = updatedDocuments.findIndex(
                        (doc) => doc.ticketId === ticketId,
                      );

                      if (documentIndex !== -1) {
                        updatedDocuments[documentIndex].done = true;
                      }

                      return updatedDocuments;
                    });
                  }
                }, index * 1000);
              });
            });
          }}
        />
      ))}
      <header className="p-4">
        <Heading size="5">{createCombinatorString(personas)} Review</Heading>
      </header>

      <Grid columns={{ sm: "2" }} px="4" pb="4" gap="4">
        {/* Left side content */}
        <Box className={paneClasses}>
          <Grid gap="5" height="100%" className="grid-rows-[1fr_auto]">
            <ScrollArea scrollbars="vertical" type="always">
              <Flex
                direction="column"
                justify="end"
                gap="4"
                height="100%"
                pr="3"
              >
                {chatMessages.map((message) => (
                  <ChatMessage
                    key={message.id}
                    {...message}
                    // @ts-expect-error TS2322: it just doesn't like the generic
                    // string
                    onActionClick={
                      message.onActionClick ?? handleChatMessageActionClick
                    }
                  />
                ))}
              </Flex>
            </ScrollArea>

            <Box
              position="relative"
              p="5"
              data-files-uploaded={filesAdded}
              className="rounded-2xl border border-dashed border-puntt-accent-9 transition-colors hover:bg-puntt-accent-2 data-[files-uploaded=true]:hidden"
            >
              <Grid gap="4" className="justify-items-center">
                <CloudArrowUp size={36} color="rgb(var(--puntt-blue-9))" />
                <Text
                  size="5"
                  weight="medium"
                  className="text-center"
                  color="blue"
                >
                  Drop Files or Folders to Upload
                </Text>
                <Button color="blue" variant="outline" size="2">
                  Select Files
                </Button>
              </Grid>
              <input
                type="file"
                multiple
                className="absolute inset-0 cursor-pointer appearance-none opacity-0"
                onChange={async ({ target }) => {
                  const files = target.files;

                  return handleInitiateUpload(files);
                }}
              />
            </Box>

            <TextField.Root size="3" disabled>
              <TextField.Slot side="right">
                <IconButton size="2" disabled>
                  <ArrowUp />
                </IconButton>
              </TextField.Slot>
            </TextField.Root>
          </Grid>
        </Box>

        {/* Right side content */}
        <Box className={paneClasses} ref={rightPanelRef}>
          <Grid gap="6" height="100%" className="auto-rows-[auto_1fr]">
            <Skeleton loading={!filesAdded}>
              <Text size="4" weight="medium" className="max-w-max">
                Reviewing {assetsCount} Asset(s)
              </Text>
            </Skeleton>

            <Grid gap="4" height="100%" className="auto-rows-[1fr_auto]">
              <Skeleton
                loading={
                  !filesAdded ||
                  reviewDocuments[currentAssetIndex]?.pageKeys.length === 0
                }
              >
                <Box height={documentHeight}>
                  {filesAdded ? (
                    <ScrollArea type="always" scrollbars="vertical">
                      <Flex
                        direction="column"
                        gap="1"
                        className="document-container relative"
                      >
                        {reviewDocuments[currentAssetIndex]?.pageKeys.map(
                          (key, i) => (
                            <img key={key} src={key} alt={`Page ${i + 1}`} />
                          ),
                        )}
                      </Flex>
                    </ScrollArea>
                  ) : null}
                </Box>
              </Skeleton>

              <Grid gap="2" className="max-w-max">
                <Flex gap="2" align="center">
                  <Skeleton loading={!filesAdded}>
                    <IconButton
                      size="1"
                      variant="outline"
                      disabled={assetsCount === 1}
                      onClick={() =>
                        setCurrentAssetIndex(Math.max(currentAssetIndex - 1, 0))
                      }
                    >
                      <CaretLeft />
                    </IconButton>
                  </Skeleton>
                  <Skeleton loading={!filesAdded}>
                    <IconButton
                      size="1"
                      variant="outline"
                      disabled={assetsCount === 1}
                      onClick={() =>
                        setCurrentAssetIndex(
                          Math.min(currentAssetIndex + 1, assetsCount - 1),
                        )
                      }
                    >
                      <CaretRight />
                    </IconButton>
                  </Skeleton>
                  <Skeleton loading={!filesAdded}>
                    <Text size="1">
                      Asset {currentAssetIndex + 1} of {assetsCount}
                    </Text>
                  </Skeleton>
                </Flex>

                <Flex gap="2" className="hidden sm:flex">
                  {(reviewDocuments.length
                    ? reviewDocuments
                    : Array.from({ length: 3 })
                  ).map((_doc, i) => (
                    <Grid gap="1" key={`.${i}`}>
                      <Skeleton
                        loading={
                          reviewDocuments.length === 0 ||
                          reviewDocuments[i]?.pageKeys.length === 0
                        }
                      >
                        {reviewDocuments.length === 0 ||
                        reviewDocuments[i]?.pageKeys.length === 0 ? (
                          <Box className="aspect-video h-10" />
                        ) : (
                          <img
                            // @ts-expect-error TS2493: FIXME AT YOUR EARLIEST
                            // CONVENIENCE
                            src={reviewDocuments[i]?.pageKeys[0]}
                            alt="Thumbnail"
                            className="aspect-video h-10"
                          />
                        )}
                      </Skeleton>

                      <Skeleton loading={!reviewDocuments.length}>
                        <Progress
                          value={reviewDocuments[i]?.done ? 100 : undefined}
                          duration="120s"
                        />
                      </Skeleton>
                    </Grid>
                  ))}
                </Flex>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

type ChatAction = {
  label: string;
  type: "view" | "reminder" | "negative" | "email" | "back";
};

type ChatMessageProps = {
  /** A unique identifier placeholder */
  id: string;
  /** Used to style the comment */
  author: "theirs" | "mine";
  /** Used to style the comment */
  type: "message-simple" | "message-confetti" | "status" | "upload";
  /** The comment message contents */
  message: string;
  /** Optional actions that the user can take on a "theirs" comment */
  actions?: ChatAction[];
  /** Pre-determined callbacks depending on the chat action type */
  onActionClick?: (action: ChatAction["label"]) => void;
  /** Any file names that may have been added for a "mine" comment */
  files?: string[];
  /** Callback that fires when a status message completes its last step */
  onStatusComplete?: () => void;
  /** Different than what onStatusComplete uses, but sets the upload status */
  uploadStatus?: "uploading" | "done";
};

function ChatMessage(props: ChatMessageProps) {
  const {
    id,
    author,
    type,
    message,
    actions = [],
    onActionClick,
    files = [],
    onStatusComplete,
    uploadStatus,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const classes = useMemo(
    () =>
      cx(
        // base styles
        "rounded-xl max-w-[75%]",
        // theirs styles
        "",
        // mine styles
        "data-[author=mine]:self-end data-[author=mine]:border data-[author=mine]:border-puntt-accent-4",
        // theirs + message simple
        "[&[data-author=theirs][data-message-type=message-simple]]:bg-puntt-accent-4",
        // theirs + message confetti
        "[&[data-author=theirs][data-message-type=message-confetti]]:bg-puntt-accent-2",
        // theirs + status
        "[&[data-author=theirs][data-message-type=status]]:bg-puntt-neutral-gray-3",
        // theirs + uploading
        "[&[data-author=theirs][data-message-type=upload]]:bg-puntt-neutral-gray-3",
      ),
    [],
  );

  function renderCommentContents() {
    if (type === "status") {
      return <StatusComment onComplete={onStatusComplete!} message={message} />;
    }

    if (type === "upload") {
      return <UploadComment status={uploadStatus} />;
    }

    return (
      <Grid gap="2">
        <Text size="3">{message}</Text>

        <Grid
          gap="2"
          data-hidden={!files.length}
          className="data-[hidden=true]:hidden"
        >
          {!isNil(files) &&
            files.map((filename) => (
              <Flex key={filename} gap="2" align="center">
                {renderFileIcon(getFileExtension(filename))}
                <Text>{filename}</Text>
              </Flex>
            ))}
        </Grid>

        <Flex
          gap="2"
          data-hidden={!actions.length}
          className="data-[hidden=true]:hidden"
        >
          {actions?.map((action, i) => (
            <Button
              key={`.${i}.${action.type}`}
              color={
                ["negative", "back"].includes(action.type) ? "gray" : "blue"
              }
              variant="outline"
              onClick={() => onActionClick?.(action.type)}
            >
              {action.label}
            </Button>
          ))}
        </Flex>
      </Grid>
    );
  }

  return (
    <Box
      px="4"
      py="2"
      // TODO: this is temporarily unused, we may not need it here
      data-id={id}
      data-author={author}
      data-message-type={type}
      className={classes}
      ref={ref}
    >
      {type === "message-confetti" && (
        <ConfettiExplosion
          width={ref.current?.getBoundingClientRect().width}
          height={ref.current?.getBoundingClientRect().height}
          duration={5000}
        />
      )}
      {renderCommentContents()}
    </Box>
  );
}

type UploadCommentProps = {
  status?: "uploading" | "done";
};

function UploadComment(props: UploadCommentProps) {
  const { status } = props;

  if (status === "uploading") {
    return (
      <Disclosure defaultOpen as={Grid} gap="2">
        <Box>
          <Flex align="center" justify="between">
            <Disclosure.Button>
              <Text>
                Working
                <CaretDown className="inline align-text-top" />
              </Text>
            </Disclosure.Button>
          </Flex>
          <Separator orientation="horizontal" className="w-full" />
        </Box>

        <Disclosure.Panel as={Grid} gap="2">
          <Flex gap="2" align="center">
            <Spinner />
            <Text>Uploading</Text>
          </Flex>
        </Disclosure.Panel>
      </Disclosure>
    );
  }

  if (status === "done") {
    return (
      <Disclosure defaultOpen as={Grid} gap="2">
        <Box>
          <Flex align="center" justify="between">
            <Disclosure.Button>
              <Text>
                Upload Completed
                <CaretDown className="inline align-text-top" />
              </Text>
            </Disclosure.Button>
          </Flex>
          <Separator orientation="horizontal" className="w-full" />
        </Box>

        <Disclosure.Panel as={Grid} gap="2">
          <Flex gap="2" align="center">
            <CheckCircle />
            <Text color="green">Uploading complete.</Text>
          </Flex>
        </Disclosure.Panel>
      </Disclosure>
    );
  }

  return "Invalid status";
}

function StatusComment(props: { onComplete: () => void; message: string }) {
  const { message } = props;

  const { status } = workflowRoute.useSearch();

  return (
    <Disclosure defaultOpen as={Grid} gap="2">
      <Box>
        <Flex align="center" justify="between">
          <Disclosure.Button>
            <Text>
              {status === "done" ? "Review Completed" : "Working"}{" "}
              <CaretDown className="inline align-text-top" />
            </Text>
          </Disclosure.Button>
        </Flex>
        <Separator orientation="horizontal" className="w-full" />
      </Box>

      <Disclosure.Panel as={Grid} gap="2">
        <Markdown>{message}</Markdown>

        <Flex
          gap="2"
          align="center"
          className={cx({ hidden: status === "done" })}
        >
          <Spinner /> Processing
        </Flex>
      </Disclosure.Panel>
    </Disclosure>
  );
}

type DocumentStatusUpdaterProps = {
  ticketId: string;
  revisionId: string;
  statuses: {
    isPending: boolean;
    isAIReady: boolean;
  };
  onReady(): void;
  onAIReady(): void;
  onFail(): void;
};

function DocumentStatusUpdater(props: DocumentStatusUpdaterProps) {
  const { ticketId, revisionId, onReady, onAIReady, onFail, statuses } = props;

  const ticketVersionStatusMutation = useTicketVersionStatusMutation(ticketId);

  const MAX_CHECKS = 40;
  const [currentCheckCount, incrementCheckCount] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    // When uploading a version that results in doc mode, we will have to wait
    // for some amount of processing to be done on the API. Because of this, we
    // will poll the status at some interval (3 seconds currently).
    if (!statuses.isPending && statuses.isAIReady) {
      return;
    }

    if (currentCheckCount >= MAX_CHECKS) {
      return onFail();
    }

    const interval = setInterval(() => {
      console.warn("Getting version status", ticketId, revisionId);
      console.table(statuses);

      incrementCheckCount();

      ticketVersionStatusMutation.mutate(revisionId, {
        onSuccess(data) {
          if (isNil(data)) return;

          // We can load the asset image URLs
          if (!data.isPending) {
            onReady();
          }

          // We can run an AI review
          if (data.isAIReady) {
            onAIReady();
          }
        },
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [revisionId, statuses.isAIReady, statuses.isPending]);

  return null;
}
