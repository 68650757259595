import {
  File,
  FileArchive,
  FileCsv,
  FileDoc,
  FileJpg,
  FilePdf,
  FilePpt,
  FileSvg,
  FileTxt,
  FileVideo,
  FileZip,
  Gif,
} from "@phosphor-icons/react";

/**
 * Given a file name, returns the extension not including the final period.
 */
export function getFileExtension(filename: string) {
  return filename.split(".").pop() ?? "";
}

/**
 * Given a file extension, returns a related icon
 */
export function renderFileIcon(extension: string) {
  const normalizedExtension = extension.toLocaleLowerCase();

  switch (normalizedExtension) {
    // spreadsheets
    case "csv":
    case "xls":
    case "xlsx":
      return <FileCsv />;
    // slideshows
    case "ppt":
    case "pptx":
      return <FilePpt />;
    // documents + pdfs
    case "doc":
    case "docx":
      return <FileDoc />;
    case "pdf":
      return <FilePdf />;
    // archives
    case "gz":
      return <FileArchive />;
    case "zip":
      return <FileZip />;
    // images
    case "gif":
      return <Gif />;
    case "svg":
      return <FileSvg />;
    case "jpg":
    case "jpeg":
      return <FileJpg />;
    // videos
    case "mp4":
    case "mov":
    case "avi":
    case "wmv":
    case "mkv":
    case "flv":
    case "3gp":
    case "3g2":
      return <FileVideo />;
    // misc
    case "txt":
      return <FileTxt />;
    default:
      return <File />;
  }
}
