import { CaretRight, DotsThree } from "@phosphor-icons/react";
import { DropdownMenu, Flex, IconButton, Link } from "@radix-ui/themes";
// eslint-disable-next-line import/named
import { Link as NavLink } from "@tanstack/react-router";
import cx from "classnames";
import { Fragment, type ReactNode, useState } from "react";

import { TicketActionsDropdown } from "./TicketActionsDropdown";

import { isPunttGuest } from "../../../utils/auth";
import { useAppSelector } from "../../../utils/hooks";
import { ticketsRoute } from "../route";

type BreadcrumbsProps = {
  onDeleteFolder(): void;
  onRenameFolder(): void;
  onShareFolder(): void;
  onDownloadFolder(): void;
  onManageVersions?(): void;
  ticketTitle?: ReactNode;
  onMoveToFolder?: (
    draggedIds: { _id: string; isFolder: boolean }[],
    targetFolderId: string,
  ) => void;
};

export function Breadcrumbs(props: BreadcrumbsProps) {
  const {
    onRenameFolder,
    onShareFolder,
    onDeleteFolder,
    onDownloadFolder,
    onManageVersions,
    ticketTitle,
    onMoveToFolder,
  } = props;
  const breadcrumbs = useAppSelector(
    (state) => state.punttProjects.breadcrumbs,
  );

  if (!breadcrumbs?.length) {
    if (!ticketTitle) {
      return null;
    }
    return (
      <>
        {ticketTitle}
        {renderDropdownActions()}
      </>
    );
  }

  function renderDropdownActions() {
    if (isPunttGuest()) {
      return null;
    }

    return (
      <TicketActionsDropdown
        onShare={onShareFolder}
        onDeleteTicket={onDeleteFolder}
        onRename={onRenameFolder}
        onDownload={onDownloadFolder}
        onManageVersions={onManageVersions}
      />
    );
  }

  // if we have several breadcrumbs, we just want to show the top-level folder,
  // the folder you're currently at, and a "..." menu in between as a popover.
  if (breadcrumbs.length > 2) {
    const init = breadcrumbs.slice(0, -1);
    const last = breadcrumbs[breadcrumbs.length - 1];

    return (
      <>
        <CaretRight className="shrink-0" />

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton variant="ghost" color="gray">
              <DotsThree />
            </IconButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content>
            {init.map((el) => (
              <DropdownMenu.Item key={el._id}>
                <NavLink
                  to={ticketsRoute.to}
                  search={(prev) => ({ ...prev, folderId: el._id })}
                  style={{
                    lineHeight: "var(--base-menu-item-height)",
                    width: "100%",
                  }}
                >
                  {el.name}
                </NavLink>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>

        <CaretRight className="shrink-0" />

        <Link asChild className="puntt-link">
          <NavLink
            to={ticketsRoute.to}
            search={(prev) => ({ ...prev, folderId: last._id })}
          >
            {last.name}
          </NavLink>
        </Link>
        {ticketTitle}
        {renderDropdownActions()}
      </>
    );
  }

  return (
    <Flex gap="2" align="center" className="min-w-0">
      {breadcrumbs.map((b) => (
        <Fragment key={b._id}>
          <CaretRight className="shrink-0" />
          <BreadcrumbLink folder={b} onMoveToFolder={onMoveToFolder} />
        </Fragment>
      ))}
      {ticketTitle}
      {renderDropdownActions()}
    </Flex>
  );
}

function BreadcrumbLink({
  folder,
  onMoveToFolder,
}: {
  folder: { _id: string; name: string };
  onMoveToFolder?: (
    draggedIds: { _id: string; isFolder: boolean }[],
    targetFolderId: string,
  ) => void;
}) {
  const [isOver, setIsOver] = useState(false);

  return (
    <Link
      asChild
      color="gray"
      className={cx({
        "rounded-lg bg-puntt-accent-10 text-base-white outline outline-1 outline-offset-2 outline-puntt-accent-8":
          isOver,
      })}
      onDragOver={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        setIsOver(true);
      }}
      onDragLeave={() => {
        setIsOver(false);
      }}
      onDrop={(e) => {
        e.preventDefault();
        setIsOver(false);

        try {
          const parsed = JSON.parse(e.dataTransfer.getData("application/json"));

          if (parsed != null) {
            // Only proceed with the move if we're not dropping onto the same folder
            const isDropOnSelf = (
              parsed as {
                _id: string;
                isFolder: boolean;
              }[]
            ).some((item: { _id: string }) => item._id === folder._id);
            if (!isDropOnSelf && typeof onMoveToFolder === "function") {
              //@ts-expect-error TS doesn't know that parsed is an array
              onMoveToFolder(parsed, folder._id);
            }
          }
        } catch (err) {
          console.error("Invalid drag data", err);
        }
      }}
    >
      <NavLink
        to={ticketsRoute.to}
        search={(prev) => ({ ...prev, folderId: folder._id })}
      >
        {folder.name}
      </NavLink>
    </Link>
  );
}
