import { ButtonNew, Chip, Icon, Select, Typography } from "@mg/dali/src";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useReducer, useState } from "react";

import { myNetworkRoute } from "../routes/network/route";
import { useTags } from "../utils/queries/profile";

type LabelledMappedChipsProps = {
  title: string;
  values?: string[];
  hideWhenEmpty?: boolean;
  editable?: boolean;
  onSave?: (a: (string | number)[]) => void;
  showButton?: boolean;
  subheader?: string;
  searchChips?: boolean;
  minShowValue?: number;
  matchedTerms?: { value: string; context: string; term: string }[];
};

export const LabeledMappedChips = ({
  title,
  values = [],
  hideWhenEmpty = false,
  editable = false,
  showButton = false,
  onSave,
  subheader,
  searchChips,
  minShowValue = 4,
  matchedTerms,
}: LabelledMappedChipsProps) => {
  const tags = useTags();
  const navigate = useNavigate();
  const [showAll, setShowAll] = useReducer((x) => !x, false);
  const [isEditing, setIsEditing] = useState(false);
  const [selections, setSelections] = useState<
    { label: string; value: string | number }[]
  >(values.map((v) => ({ label: v, value: v })));
  const [sortedValues, setSortedValues] = useState(values);
  useEffect(() => {
    if (matchedTerms?.some((term) => values.includes(term.value))) {
      const tempSorted = values.slice().sort((a, b) => {
        const aInMatchedTerms = matchedTerms.some((term) => term.value === a);
        const bInMatchedTerms = matchedTerms.some((term) => term.value === b);

        if (aInMatchedTerms && !bInMatchedTerms) {
          return -1;
        } else if (!aInMatchedTerms && bInMatchedTerms) {
          return 1;
        }
        return 0;
      });
      setSortedValues(tempSorted);
      if (
        !showAll &&
        matchedTerms.filter((term) => values.includes(term.value)).length >
          minShowValue
      ) {
        setShowAll();
      }
    }
  }, [values, matchedTerms]);
  if (hideWhenEmpty && values.length === 0) {
    return null;
  }
  const shownValues = showAll
    ? sortedValues
    : sortedValues.slice(0, minShowValue);

  return (
    <article
      className="grid flex-1 auto-rows-max gap-2"
      data-testid={`${title}-chips`}
    >
      <div className="flex items-center justify-between gap-4">
        <Typography
          as="h3"
          size="xl"
          weight="medium"
          className="text-carbon-900"
        >
          {title}
        </Typography>

        <ButtonNew
          size="xs"
          variant="ghost"
          className={cx({
            hidden:
              !editable || (!isEditing && showButton && values.length === 0),
          })}
          onClick={() => {
            if (isEditing && onSave != null) {
              setIsEditing(false);
            } else {
              setIsEditing(true);
            }
          }}
          data-testid={`edit-${title}-cta`}
        >
          <Icon.FloppyDisk />
          {isEditing ? "Done" : `Edit ${title}`}
        </ButtonNew>
      </div>

      <ButtonNew
        size="sm"
        variant="outlined"
        className={cx("w-max", {
          hidden: isEditing || !showButton || values.length > 0,
        })}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        Add Tags
      </ButtonNew>
      {subheader && (
        <Typography size="sm" className="text-carbon-500">
          {subheader}
        </Typography>
      )}
      <div className={cx("flex flex-wrap gap-2", { hidden: isEditing })}>
        {shownValues.map((s, i) =>
          searchChips ? (
            <Tooltip.Provider key={i + s}>
              <Tooltip.Root delayDuration={150}>
                <Tooltip.Trigger asChild>
                  <button
                    onClick={() =>
                      navigate({
                        to: myNetworkRoute.to,
                        search() {
                          return { queries: [s] };
                        },
                      })
                    }
                  >
                    <Chip
                      key={`.${i}.${s}`}
                      variant="primary-outlined"
                      label={s}
                      className={cx({
                        "bg-malachite-100": matchedTerms?.find(
                          (term) => term.value === s,
                        ),
                      })}
                    />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    sideOffset={5}
                    className="z-50 w-fit max-w-full rounded bg-carbon-700 p-1.5 text-base-white"
                  >
                    <Typography size="sm">
                      {matchedTerms?.find(
                        (term) => term.value === s && s !== term.term,
                      )
                        ? `Synonym of ${
                            matchedTerms?.find(
                              (term) => term.value === s && s !== term.term,
                            )?.term
                          }`
                        : "Click to find others with this!"}
                    </Typography>
                    <Tooltip.Arrow className="fill-carbon-700" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          ) : (
            <Chip
              key={`.${i}.${s}`}
              variant="primary-outlined"
              className={cx({
                "bg-malachite-100": matchedTerms?.find(
                  (term) => term.value === s,
                ),
              })}
              label={s}
              data-testid={s}
            />
          ),
        )}
        {values.length > minShowValue && (
          <Chip
            variant="carbon-light"
            label={
              showAll ? "Show less" : `${values.length - minShowValue}+ More`
            }
            trailingIcon={
              <button onClick={setShowAll}>
                {!showAll ? <Icon.PlusCircle /> : <Icon.MinusCircle />}
              </button>
            }
          />
        )}
      </div>

      <Select<true>
        className={cx({ hidden: !isEditing })}
        multiple
        value={selections}
        onChange={(val) => {
          if (onSave != null) {
            onSave(val.map((s) => s.value));
          }
          setSelections(val);
        }}
        allowCustom
        // @ts-expect-error TS2322: not sure why this is saying a string array
        // is not okay, but this works as-is, so I'm not going to try and change
        // something that potentially breaks this.
        options={tags.data ?? []}
        disabled={tags.isLoading}
      />
    </article>
  );
};
