import { type GetFolderTicketsPaginatedResponse } from "@mg/schemas/src/christo/catalyst";
import { Robot, Folder } from "@phosphor-icons/react";
import {
  Avatar,
  Badge,
  Flex,
  Grid,
  Heading,
  HoverCard,
  IconButton,
  Skeleton,
  Spinner,
  Table,
  Text,
} from "@radix-ui/themes";
import cx from "classnames";
import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";
import { validate } from "uuid";

import { TicketActionsDropdown } from "./TicketActionsDropdown";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAnalytics } from "../../../utils/analytics";
import { isPunttGuest } from "../../../utils/auth";
import { isNil } from "../../../utils/fp";
import { useAppSelector } from "../../../utils/hooks";
import { DragDropProps } from "../view";

const MAX_PARTICIPANTS_VISIBLE = 4;

type ResponseTicket = GetFolderTicketsPaginatedResponse["tickets"][number];
type ResponseFolder = NonNullable<
  GetFolderTicketsPaginatedResponse["folders"]
>[number];
type TicketOrFolder = ResponseTicket | ResponseFolder;

function isFolder(t: TicketOrFolder): t is ResponseFolder {
  return "isFolder" in t && Boolean(t.isFolder);
}

type TicketTableProps = {
  onDeleteTicket: (ticketId: string) => void;
  onDeleteFolder: (folderId: string) => void;
  onTicketClick: (ticketId: string) => void;
  onFolderClick: (folderId: string) => void;
  onRenameFolder: (folderId: string) => void;
  onShareTicket: (ticketId: string) => void;
  onShareFolder: (folderId: string) => void;
  onDownloadFolder: (folderId: string) => void;
  onDownloadTicket: (ticketId: string) => void;
} & DragDropProps;

export function TicketTable(props: TicketTableProps) {
  const tickets = useAppSelector((state) => state.punttProjects.tickets);
  const folders = useAppSelector((state) => state.punttProjects.folders);
  const posthog = useAnalytics();
  const markedFolders = useMemo(
    () =>
      folders.map((f) => ({
        ...f,
        isFolder: true,
      })),
    [folders],
  );
  const mergedFoldersAndTickets: TicketOrFolder[] = useMemo(
    () =>
      [...markedFolders, ...tickets].toSorted((a, b) => {
        const dateA = new Date(a.updatedAt as string);
        const dateB = new Date(b.updatedAt as string);

        // Sort in descending order (most recent first)
        return dateB.getTime() - dateA.getTime();
      }),
    [markedFolders, tickets],
  );

  const {
    onDeleteTicket,
    onDeleteFolder,
    onRenameFolder,
    onShareTicket,
    onShareFolder,
    onDownloadFolder,
    onDownloadTicket,
  } = props;

  return (
    <Table.Root
      variant="surface"
      className="mx-10"
      onDragEnter={(e) => {
        if (props.isDraggingCards) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      onDragOver={(e) => {
        if (props.isDraggingCards) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      onDrop={(e) => {
        if (props.isDraggingCards) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Last Updated</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell align="right">
            Participants
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {mergedFoldersAndTickets.map((t, i) => {
          // t is uploading
          if (isNil(t._id) || validate(t._id)) {
            if (isFolder(t)) {
              return (
                <Table.Row key={`.${i}.${t.name}`}>
                  <Table.Cell width="100%">
                    <Grid gap="3" columns="auto auto auto auto" align="center">
                      <Folder />
                      <Heading size="2" className="truncate">
                        {t.name}
                      </Heading>
                    </Grid>
                  </Table.Cell>
                  <Table.Cell className="min-w-48 whitespace-nowrap">
                    <Flex gap="3" align="center">
                      <Spinner />
                      <Text>Uploading...</Text>
                    </Flex>
                  </Table.Cell>

                  <Table.Cell className="min-w-48 whitespace-nowrap">
                    <Flex gap="2" justify="end">
                      <Skeleton>
                        <Avatar fallback="" size="1" />
                      </Skeleton>
                    </Flex>
                  </Table.Cell>
                </Table.Row>
              );
            }

            return (
              <Table.Row key={`.${i}.${t.title}`}>
                <Table.Cell width="100%">
                  <Heading size="2" className="truncate">
                    {t.title}
                  </Heading>
                </Table.Cell>
                <Table.Cell className="min-w-48 whitespace-nowrap">
                  <Flex gap="3" align="center">
                    <Spinner />
                    <Text>Uploading...</Text>
                  </Flex>
                </Table.Cell>

                <Table.Cell className="min-w-48 whitespace-nowrap">
                  <Flex gap="2" justify="end">
                    <Skeleton>
                      <Avatar fallback="" size="1" />
                    </Skeleton>
                  </Flex>
                </Table.Cell>
              </Table.Row>
            );
          }

          // t is uploaded
          return (
            <Table.Row
              key={t._id}
              draggable={true}
              onClick={(e) => props.onSelect(t._id, e)}
              onDoubleClick={() => {
                posthog.capture("double_clicked_tickets_card", {
                  id: t._id,
                  is_folder: isFolder(t),
                  name: isFolder(t) ? t.name : t.title,
                });
                if (isFolder(t)) {
                  props.onFolderClick(t._id);
                } else {
                  props.onTicketClick(t._id);
                }
              }}
              onDragStart={(e) => props.onDragStart(t._id, e)}
              onDragEnter={(e) => props.onDragEnter(t._id, e)}
              onDragLeave={(e) => props.onDragLeave(t._id, e)}
              onDragOver={props.onDragOver}
              onDrop={(e) => props.onDrop(t._id, e)}
              className={cx(
                "group cursor-pointer transition-colors hover:bg-puntt-blue-3 active:bg-puntt-blue-4",
                {
                  "bg-puntt-blue-3":
                    props.dropTargetId === t._id ||
                    props.selectedIds.includes(t._id),
                  "opacity-50":
                    props.isDraggingCards && props.selectedIds.includes(t._id),
                },
              )}
            >
              <Table.Cell width="100%" className="align-middle">
                <Grid gap="3" columns="auto auto auto auto" align="center">
                  {isFolder(t) && <Folder />}
                  <Heading size="2" className="truncate">
                    {isFolder(t) ? t.name : t.title}
                  </Heading>

                  <Text
                    size="2"
                    className={cx({
                      hidden: !isFolder(t) && !t.documentImportType,
                    })}
                  >
                    {!isFolder(t) && t.documentImportType}
                  </Text>

                  <Badge
                    size="1"
                    color="gray"
                    variant="solid"
                    className={cx({
                      hidden:
                        ("totalRevisions" in t ? t.totalRevisions : 1) < 2,
                    })}
                  >
                    V{"totalRevisions" in t ? t.totalRevisions : 1}
                  </Badge>

                  <TicketActionsDropdown
                    className={cx(
                      "opacity-0 group-hover:opacity-100 data-[state=open]:opacity-100",
                      {
                        hidden: isPunttGuest(),
                      },
                    )}
                    onRename={() => onRenameFolder(t._id)}
                    onShare={() =>
                      isFolder(t) ? onShareFolder(t._id) : onShareTicket(t._id)
                    }
                    onDeleteTicket={() =>
                      isFolder(t)
                        ? onDeleteFolder(t._id)
                        : onDeleteTicket(t._id)
                    }
                    onDownload={() =>
                      isFolder(t)
                        ? onDownloadFolder(t._id)
                        : onDownloadTicket(t._id)
                    }
                  />
                </Grid>
              </Table.Cell>
              <Table.Cell className="min-w-48 whitespace-nowrap align-middle">
                {t.updatedAt !== "" && !isNil(t.updatedAt)
                  ? formatDistanceToNow(new Date(t.updatedAt as string), {
                      addSuffix: true,
                    })
                  : "--"}
              </Table.Cell>
              <Table.Cell className="min-w-48 whitespace-nowrap">
                <Flex gap="1" justify="end">
                  {(
                    t.participants as Exclude<
                      (typeof t.participants)[number],
                      string
                    >[]
                  )
                    ?.slice(0, MAX_PARTICIPANTS_VISIBLE)
                    .map(({ avatar, name, _id }, i) => {
                      const showsAvatarOverflow =
                        t.participants.length > MAX_PARTICIPANTS_VISIBLE;
                      const avatarOverflowCount =
                        t.participants.length - (MAX_PARTICIPANTS_VISIBLE - 1);
                      const isLastVisibleAvatar =
                        i === MAX_PARTICIPANTS_VISIBLE - 1;

                      if (showsAvatarOverflow && isLastVisibleAvatar) {
                        return (
                          <HoverCard.Root key={`h.${t._id}.${_id}`}>
                            <HoverCard.Trigger>
                              <IconButton size="1" variant="ghost">
                                <Avatar
                                  color="red"
                                  size="1"
                                  variant="soft"
                                  fallback={`+${avatarOverflowCount}`}
                                />
                              </IconButton>
                            </HoverCard.Trigger>

                            <HoverCard.Content
                              className="dark"
                              side="top"
                              align="center"
                            >
                              <Grid gap="2">
                                {(
                                  t.participants as Exclude<
                                    (typeof t.participants)[number],
                                    string
                                  >[]
                                )
                                  .slice(MAX_PARTICIPANTS_VISIBLE - 1)
                                  .map(({ _id, avatar, name }) => (
                                    <Flex
                                      gap="3"
                                      align="center"
                                      key={`${t._id}.${_id}`}
                                    >
                                      {name === "AI Reviewer" ? (
                                        <Avatar
                                          color="amber"
                                          size="1"
                                          variant="solid"
                                          fallback={<Robot />}
                                        />
                                      ) : (
                                        <AvatarWithInitials
                                          size={6}
                                          square={true}
                                          avatar={avatar}
                                          name={name}
                                        />
                                      )}

                                      <Text size="1">{name}</Text>
                                    </Flex>
                                  ))}
                              </Grid>
                            </HoverCard.Content>
                          </HoverCard.Root>
                        );
                      }

                      if (name === "AI Reviewer") {
                        return (
                          <HoverCard.Root key={`h.${t._id}.${_id}`}>
                            <HoverCard.Trigger>
                              <div>
                                <Avatar
                                  data-testid="ai-avatar"
                                  color="amber"
                                  size="1"
                                  variant="solid"
                                  fallback={<Robot />}
                                />
                              </div>
                            </HoverCard.Trigger>
                            <HoverCard.Content
                              className="dark"
                              side="top"
                              align="center"
                            >
                              <Grid gap="2">
                                <Flex
                                  gap="3"
                                  align="center"
                                  key={`a.${t._id}.${_id}`}
                                >
                                  <Avatar
                                    color="amber"
                                    size="1"
                                    variant="solid"
                                    fallback={<Robot />}
                                  />
                                  <Text size="1">AI Reviewer</Text>
                                </Flex>
                              </Grid>
                            </HoverCard.Content>
                          </HoverCard.Root>
                        );
                      }

                      return (
                        <HoverCard.Root key={`h.${t._id}.${_id}`}>
                          <HoverCard.Trigger>
                            <AvatarWithInitials
                              key={`a.${t._id}.${_id}`}
                              size={6}
                              square={true}
                              avatar={avatar}
                              name={name}
                            />
                          </HoverCard.Trigger>
                          <HoverCard.Content
                            className="dark"
                            side="top"
                            align="center"
                          >
                            <Grid gap="2">
                              <Flex
                                gap="3"
                                align="center"
                                key={`a.${t._id}.${_id}`}
                              >
                                <AvatarWithInitials
                                  size={6}
                                  square={true}
                                  avatar={avatar}
                                  name={name}
                                />
                                <Text size="1">{name}</Text>
                              </Flex>
                            </Grid>
                          </HoverCard.Content>
                        </HoverCard.Root>
                      );
                    })}
                </Flex>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
}
