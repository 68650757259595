import { ArrowUp, Copy, Palette, Scales } from "@phosphor-icons/react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  ScrollArea,
  Text,
  TextArea,
  Tooltip,
} from "@radix-ui/themes";
import cx from "classnames";
import { KeyboardEvent, useEffect, useRef, useState } from "react";

import { Markdown } from "../../../../components/Markdown";
import { isNil } from "../../../../utils/fp";
import { useAskQAQuestionMutation } from "../../../../utils/queries/workflows";
import { generateUUID } from "../../../../utils/uuid";

type Message = {
  _id: string;
  content: string;
  sender: "mine" | "theirs";
  status?: "working";
};

export default function ChatView() {
  const askQAQuestionMutation = useAskQAQuestionMutation();

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [pendingMessage, setPendingMessage] = useState("");

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: scrollAreaRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages.length]);

  function handleSubmit() {
    if (!pendingMessage.trim().length) return;

    setPendingMessage("");
    setMessages((prev) => [
      ...prev,
      { _id: generateUUID(), content: pendingMessage, sender: "mine" },
      {
        _id: generateUUID(),
        status: "working",
        content: "Working...",
        sender: "theirs",
      },
    ]);

    askQAQuestionMutation.mutate(
      { query: pendingMessage },
      {
        onSuccess: (data) => {
          setMessages((prev) => [
            // Remove all pending messages
            ...prev.filter((m) => isNil(m.status)),
            { _id: generateUUID(), content: data.answer, sender: "theirs" },
          ]);
        },
      },
    );
  }

  function askInitialQuestion(query: string) {
    setMessages((prev) => [
      ...prev,
      { _id: generateUUID(), content: query, sender: "mine" },
      {
        _id: generateUUID(),
        status: "working",
        content: "Working...",
        sender: "theirs",
      },
    ]);

    askQAQuestionMutation.mutate(
      { query },
      {
        onSuccess: (data) => {
          setMessages((prev) => [
            ...prev.filter((m) => isNil(m.status)),
            { _id: generateUUID(), content: data.answer, sender: "theirs" },
          ]);
        },
      },
    );
  }

  function handleInputChange(evt: KeyboardEvent<HTMLTextAreaElement>) {
    if (!evt.shiftKey && evt.key === "Enter") {
      evt.preventDefault();
      handleSubmit();
    }
  }

  if (!messages.length) {
    return (
      <Grid
        pt="9"
        gap="5"
        maxWidth="588px"
        width="100%"
        mx="auto"
        height="max-content"
      >
        <Heading size="8" weight="regular" align="center">
          How can I help?
        </Heading>

        <Box position="relative">
          <TextArea
            placeholder="Ask anything"
            size="3"
            className="[&>textarea]:pr-14"
            value={pendingMessage}
            onKeyDown={handleInputChange}
            onChange={({ target }) => setPendingMessage(target.value)}
          />

          <IconButton
            size="2"
            color="blue"
            className="absolute bottom-3 right-3"
            onClick={handleSubmit}
            disabled={!pendingMessage.trim().length}
          >
            <ArrowUp />
          </IconButton>
        </Box>

        <Flex gap="2">
          <Button size="1" color="gray">
            <Scales />
            Legal
          </Button>

          <Button size="1" color="gray" variant="outline">
            <Palette />
            Creative
          </Button>
        </Flex>

        <Grid columns={{ sm: "1", md: "3" }} gap="4">
          <Button
            size="2"
            color="gray"
            variant="surface"
            className="h-full items-start p-3 text-left"
            onClick={() =>
              askInitialQuestion(
                "Which new regulations impact our products (e.g. alcohol, ingredients, marketing claims)?",
              )
            }
          >
            <Text size="3" weight="regular">
              Which new regulations impact our products (e.g. alcohol,
              ingredients, marketing claims)?
            </Text>
          </Button>

          <Button
            size="2"
            color="gray"
            variant="surface"
            className="h-full items-start p-3 text-left"
            onClick={() =>
              askInitialQuestion(
                "What are the highest-risk regulatory updates in our priority geographies?",
              )
            }
          >
            <Text size="3" weight="regular">
              What are the highest-risk regulatory updates in our priority
              geographies?
            </Text>
          </Button>

          <Button
            size="2"
            color="gray"
            variant="surface"
            className="h-full items-start p-3 text-left"
            onClick={() =>
              askInitialQuestion(
                "Can you generate a report summarizing key regulatory changes by practice area?",
              )
            }
          >
            <Text size="3" weight="regular">
              Can you generate a report summarizing key regulatory changes by
              practice area?
            </Text>
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Flex direction="column" width="588px" mx="auto" height="100vh" pb="4">
      <ScrollArea scrollbars="vertical" type="always" ref={scrollAreaRef}>
        {/* The gradient */}
        <div className="pointer-events-none absolute inset-0 [background-image:linear-gradient(to_bottom,var(--sand-3)_0%,transparent_3%,transparent_97%,var(--sand-3)_100%)]" />
        <Flex
          direction="column"
          gap="3"
          py="4"
          pr="4"
          justify="end"
          height="100%"
        >
          {messages.map((message) => (
            <Flex
              key={message._id}
              align={message.sender === "mine" ? "end" : "start"}
              direction="column"
              gap="2"
            >
              <Box
                p="4"
                data-sender={message.sender}
                className="w-3/4 rounded-xl data-[sender=mine]:border data-[sender=mine]:border-puntt-accent-8 data-[sender=mine]:bg-transparent data-[sender=theirs]:bg-puntt-accent-3"
              >
                <Markdown>{message.content}</Markdown>
              </Box>

              <Tooltip content="Copy to clipboard">
                <IconButton
                  variant="ghost"
                  color="gray"
                  size="1"
                  className={cx("", { hidden: message.sender === "mine" })}
                  onClick={() =>
                    window.navigator.clipboard.writeText(message.content)
                  }
                >
                  <Copy />
                </IconButton>
              </Tooltip>
            </Flex>
          ))}
        </Flex>
      </ScrollArea>

      <Box position="relative">
        <TextArea
          placeholder="Type a message..."
          size="3"
          className="[&>textarea]:pr-14"
          value={pendingMessage}
          onKeyDown={handleInputChange}
          onChange={({ target }) => setPendingMessage(target.value)}
          disabled={askQAQuestionMutation.isPending}
        />

        <IconButton
          size="2"
          color="blue"
          className="absolute bottom-3 right-3"
          onClick={handleSubmit}
          disabled={
            askQAQuestionMutation.isPending || !pendingMessage.trim().length
          }
          loading={askQAQuestionMutation.isPending}
        >
          <ArrowUp />
        </IconButton>
      </Box>
    </Flex>
  );
}
