import {
  Button,
  Dialog,
  Flex,
  Grid,
  Text,
  TextField,
  VisuallyHidden,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";

type RenameTicketDialogProps = {
  /**
   * Whether the dialog is open or not; defaults to `false`
   */
  open: boolean;
  /**
   * Handles how the Dialog communicates its internal state with the parent
   * component
   */
  onOpenChange(open: boolean): void;
  /**
   * Handles what happens when the user clicks the "submit" action
   */
  onSubmit(title: string): any;
  /**
   * Whether or not the parent handler to rename the ticket is in-flight
   */
  isPending: boolean;
  /**
   * The ticket's current title
   */
  value: string;
};

export function RenameTicketDialog(props: RenameTicketDialogProps) {
  const { isPending, open, value, onOpenChange, onSubmit } = props;

  const [title, setTitle] = useState(value);

  useEffect(() => {
    // Resets the title input when the user closes the dialog, but has some
    // changes.
    if (!open && title !== value) {
      setTitle(value);
    }
  }, [open, title, value]);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content width="450px">
        <Dialog.Title data-testid="rename-ticket-dialog-title">
          Rename ticket?
        </Dialog.Title>
        <VisuallyHidden>
          <Dialog.Description data-testid="rename-ticket-dialog-description">
            Change the ticket&apos;s title
          </Dialog.Description>
        </VisuallyHidden>

        <Grid gap="4">
          <Grid gap="2">
            <Text size="2" weight="medium" data-testid="new-ticket-title-label">
              Title
            </Text>

            <TextField.Root
              value={title}
              onChange={({ target }) => setTitle(target.value)}
              disabled={isPending}
              data-testid="new-ticket-title-input"
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isPending) {
                  e.preventDefault();
                  onSubmit(title);
                }
              }}
            />
          </Grid>

          <Flex gap="2" align="center" justify="end">
            <Dialog.Close
              disabled={isPending}
              data-testid="rename-ticket-dialog-cancel-cta"
            >
              <Button color="gray" variant="soft">
                Cancel
              </Button>
            </Dialog.Close>

            <Button
              disabled={isPending || !title.trim().length}
              loading={isPending}
              onClick={() => onSubmit(title)}
              data-testid="rename-ticket-dialog-submit-cta"
            >
              <Text size="2">Rename</Text>
            </Button>
          </Flex>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
}
