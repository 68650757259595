import { isMongoId } from "validator";
import { z } from "zod";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";
import { parseResponse } from "../utils/parseResponse";

const reviewTicketParser = z.object({
  _id: z.string().refine((val) => isMongoId(val)),
  name: z.string(),
  thumbnail: z.string(),
});

const reviewResultParser = z.object({
  tickets: z.array(reviewTicketParser),
  personas: z.array(z.enum(["copy", "brand", "legal"])),
  createdAt: z.string().datetime(),
  summary: z.string(),
});

const reviewParser = z.object({
  _id: z.string().refine((val) => isMongoId(val)),
  name: z.string(),
  results: z.array(reviewResultParser),
});

export type Review = z.infer<typeof reviewParser>;

const getWorkflowReviewsResponseParser = z.array(reviewParser);

export async function getRecentReviews() {
  const json = await smartFetch(`${API_URL}/private/catalyst/reviews/recents`, {
    method: "GET",
  });

  const parsedData = parseResponse(getWorkflowReviewsResponseParser, json);
  return parsedData;
}
