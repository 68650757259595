import { Slot } from "@radix-ui/themes";
import cx from "classnames";
import { type ComponentProps, forwardRef, type ReactNode } from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "./SidebarTooltip";
import { useSidebar } from "./useSidebar";

export const SidebarMenuButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button"> & {
    asChild?: boolean;
    isActive?: boolean;
    tooltip?: ReactNode;
    tooltipClassName?: string;
    tooltipSide?: "right" | "left";
  }
>(
  (
    {
      asChild = false,
      isActive = false,
      tooltip,
      className,
      tooltipClassName,
      tooltipSide = "right",
      ...props
    },
    ref,
  ) => {
    // Only use a slot if you know what you're doing and accept the styling
    // consequences.
    const Comp = asChild ? Slot : "button";
    const { isMobile, state } = useSidebar();

    const button = (
      <Comp
        ref={ref}
        data-state={state}
        data-sidebar="menu-button"
        data-active={isActive}
        className={cx(
          {
            "peer/menu-item flex w-full items-center gap-2 overflow-hidden rounded-md bg-transparent px-2 py-[7px] outline-none transition-[width,height,padding]":
              !asChild,
          },
          className,
        )}
        {...props}
      />
    );

    if (!tooltip) {
      return button;
    }

    return (
      <Tooltip>
        <TooltipTrigger asChild>{button}</TooltipTrigger>
        <TooltipContent
          side={tooltipSide}
          align="center"
          hidden={state !== "collapsed" || isMobile}
          className={tooltipClassName}
        >
          {tooltip}
        </TooltipContent>
      </Tooltip>
    );
  },
);

SidebarMenuButton.displayName = "SidebarMenuButton";
