import { ShareTicketBody } from "@mg/schemas/src/christo/catalyst";
import {
  CaretDown,
  CaretRight,
  Share,
  UploadSimple,
  UserCircle,
} from "@phosphor-icons/react";
import { Button, Flex, Grid, Heading, Link } from "@radix-ui/themes";
import {
  // eslint-disable-next-line import/named
  Link as NavLink,
} from "@tanstack/react-router";
import cx from "classnames";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";

import CreateVersionDialog, { Asset } from "./dialogs/CreateRevisionDialog";
import { RenameTicketDialog } from "./dialogs/RenameTicket";
import MissingFontsIndicator from "./MissingFontsIndicator";
import VersionSelect from "./VersionSelect";

import { AuthTooltip } from "../../../../../components/AuthTooltip";
import { Expandable } from "../../../../../components/Expandable";
import { useUI } from "../../../../../contexts/ui";
import { useAnalytics } from "../../../../../utils/analytics";
import { isNil } from "../../../../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { downloadAsset } from "../../../../../utils/http";
import {
  useDeleteRevisionMutation,
  useDeleteTicketMutation,
  useDownloadTicketLink,
  useEditProjectMutation,
  useRevisionMutation,
  useShareTicket,
  useShareTicketLink,
} from "../../../../../utils/queries/projects";
import { setTicket } from "../../../../../utils/slices/ticket";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { DeleteTicketDialog } from "../../../components/DeleteTicketDialog";
import {
  ShareDialog,
  type ShareDialogProps,
} from "../../../components/dialogs/ShareDialog";
import { ticketsRoute } from "../../../route";
import { VersionManagerDialog } from "../../components/dialogs/VersionManagerDialog";
import { ticketRoute } from "../route";

export default function TicketHeader() {
  // Router methods
  const { ticketId } = ticketRoute.useParams();
  const { version: versionIndex } = ticketRoute.useSearch();
  const navigate = ticketRoute.useNavigate();
  const { notify } = useUI();
  const posthog = usePostHog();

  // Slices
  const dispatch = useAppDispatch();
  const totalVersions =
    useAppSelector((state) => state.ticket.value?.totalRevisions) ?? 0;
  const ticket = useAppSelector((state) => state.ticket.value);
  const ticketTitle =
    ticketId == "editabledoc" // demo purposes only
      ? "Data Processing Agreement"
      : ticketId == "editabledoc2"
        ? "Coca-Cola Joke List"
        : (ticket?.title ?? "Untitled ticket");
  const versions = useAppSelector((state) => state.ticket.revisions);
  const user = useAppSelector((state) => state.auth.value);

  // Queries and mutations
  const renameTicketMutation = useEditProjectMutation(ticketId);
  const deleteRevisionMutation = useDeleteRevisionMutation(ticketId);
  const createVersionMutation = useRevisionMutation();
  const ticketShareLinkMutation = useShareTicketLink();
  const shareTicketMutation = useShareTicket();
  const deleteTicketMutation = useDeleteTicketMutation();
  const downloadTicketMutation = useDownloadTicketLink();

  // Analytics
  const analytics = useAnalytics(`root_ticket_${ticketId}`);

  // Local state
  const [versionManagerDialogOpen, setVersionManagerDialogOpen] =
    useState(false);
  const [createVersionDialogOpen, setCreateVersionDialogOpen] = useState(false);
  const [renameTicketDialogOpen, setRenameTicketDialogOpen] = useState(false);
  const [shareItem, setShareItem] = useState<ShareDialogProps["item"]>();
  const [shareLink, setShareLink] = useState<string>();
  const [deletingTicketId, setDeletingTicketId] = useState<
    string | undefined
  >();

  // Effect chain would go here

  /////
  // No hooks beyond this point
  /////

  function handleRenameTicket(ticketTitle: string) {
    const startTime = performance.now();

    return renameTicketMutation.mutateAsync(
      { title: ticketTitle, _id: ticketId },
      {
        onSuccess(data) {
          const endTime = performance.now();

          analytics.capture("ticket_renamed", {
            ticketTitle: ticketTitle,
            mutationDuration: endTime - startTime,
            durationSincePageLoad:
              endTime - (analytics.startTimeRef?.current ?? 0),
          });

          setRenameTicketDialogOpen(false);

          if (isNil(data)) return;

          dispatch(setTicket(data));
        },
      },
    );
  }

  function handleCreateVersion(assets: Asset[]) {
    const firstAsset = assets[0];
    const { source } = firstAsset;

    createVersionMutation.mutate(
      {
        ticketId,
        payload: {
          reviewFiles: assets,
          documentImportType: source.slice(source.lastIndexOf(".") + 1),
        },
      },
      {
        onSuccess(data) {
          setCreateVersionDialogOpen(false);
          // FIXME: this is not guaranteed to be the board the user just uploaded if
          // two people uploaded versions at the same time.
          const newBoard = data.boards[data.boards.length - 1];

          analytics.capture("created_revision", { boardId: newBoard._id });

          // Cleverly call the route's `loader` function, which will
          // automatically select the newest version index and append it to the
          // URL bar.
          navigate({});
        },
      },
    );
  }

  function handleShareTicket(
    ticketId: string,
    emails: string[],
    shareVersion: ShareTicketBody["version"],
  ) {
    const start = performance.now();

    shareTicketMutation.mutate(
      { ticketId, emails, version: shareVersion },
      {
        onSuccess() {
          analytics.capture("generate_share_ticket_link", {
            ticketId,
            recipientCount: emails.length,
            shareVersion,
            duration: performance.now() - start,
          });
          setShareLink(undefined);
          setShareItem(undefined);
          notify({
            title: "Ticket shared",
            message: `Email has been sent to ${emails.length} recipient(s)`,
          });
        },
      },
    );
  }

  function handleShareRequest() {
    setShareLink(undefined);
    setShareItem({ type: "ticket", id: ticketId });
    ticketShareLinkMutation.mutate(
      { ticketId, version: "all" },
      {
        onSuccess(data) {
          if (isNil(data)) {
            throw new Error("An unknown error occurred");
          }

          setShareLink(data.url);
        },
      },
    );
  }

  function handleDownloadTicket(ticketId: string) {
    const startTime = performance.now();
    downloadTicketMutation.mutate(ticketId, {
      onSuccess(data) {
        posthog.capture("download_ticket", {
          ticket_id: ticketId,
          ticket_name: ticketTitle,
          data_length: data?.url.length,
          request_duration: performance.now() - startTime,
        });
        if (data) {
          downloadAsset(data.url);
        }
      },
    });
  }

  const versionManagerList =
    versions.map((board, i) => ({
      _id: board._id,
      title: board.name ?? `Version ${i + 1}`,
      thumbnail: board.thumbnails[0] ?? board.screenshotUrl,
    })) ?? [];

  return (
    <>
      {/* Put dialogs here, please */}
      <VersionManagerDialog
        open={versionManagerDialogOpen}
        onOpenChange={setVersionManagerDialogOpen}
        onDeleteVersion={(revisionId, index) =>
          deleteRevisionMutation.mutate(revisionId, {
            onSuccess(data) {
              analytics.capture("delete_version", {
                ticketId,
                versionId: revisionId,
              });
              if (isNil(data)) return;

              const { boards } = data;

              if (!boards.length) {
                setVersionManagerDialogOpen(false);
                navigate({
                  search({ version: _version, ...rest }) {
                    return rest;
                  },
                });
              }

              navigate({
                search(prev) {
                  return {
                    ...prev,
                    version:
                      !isNil(prev.version) && prev.version >= index
                        ? prev.version - 1
                        : prev.version,
                  };
                },
              });
            },
          })
        }
        revisions={versionManagerList}
        isPending={deleteRevisionMutation.isPending}
        deletingId={deleteRevisionMutation.variables}
      />
      <CreateVersionDialog
        isPending={createVersionMutation.isPending}
        open={createVersionDialogOpen}
        onOpenChange={setCreateVersionDialogOpen}
        onSubmitAssets={handleCreateVersion}
      />
      <RenameTicketDialog
        open={renameTicketDialogOpen}
        onOpenChange={setRenameTicketDialogOpen}
        isPending={renameTicketMutation.isPending}
        onSubmit={handleRenameTicket}
        value={ticketTitle}
      />
      <ShareDialog
        isPending={shareTicketMutation.isPending}
        item={shareItem}
        latestVersionNumber={totalVersions}
        onCancel={() => setShareItem(undefined)}
        onShare={(emails, shareVersion) => {
          if (isNil(shareLink)) return;

          handleShareTicket(ticketId, emails, shareVersion);
        }}
        onVersionChange={(version) =>
          ticketShareLinkMutation.mutate(
            { ticketId: shareItem!.id, version },
            {
              onSuccess(data) {
                if (isNil(data)) {
                  throw new Error("An unknown error occurred");
                }

                let { url } = data;

                if (version === "all") {
                  url = `${url}&version=${versionIndex}`;
                }

                setShareLink(url);
              },
            },
          )
        }
        shareLink={shareLink}
        shareLinkPending={ticketShareLinkMutation.isPending}
      />
      <DeleteTicketDialog
        isPending={deleteRevisionMutation.isPending}
        ticketId={deletingTicketId}
        onCancel={() => setDeletingTicketId(undefined)}
        onDelete={() => {
          deleteTicketMutation.mutate(
            { ticketId },
            {
              onSuccess() {
                navigate({
                  to: ticketsRoute.to,
                  search: (prev) => {
                    const searchParams = { ...prev };
                    if (ticket?.folder) {
                      // @ts-expect-error TS2339: Open ended searchParams in case we change them
                      searchParams.folderId = ticket.folder;
                    }
                    return searchParams;
                  },
                });
              },
            },
          );
        }}
      />
      {/* Main DOM goes here */}
      <Grid
        gap="4"
        className="col-span-1 row-span-1 bg-base-white px-7 py-4 shadow"
        data-testid="ticket-header-container"
      >
        <Flex
          gap="3"
          align="center"
          justify="between"
          data-testid="ticket-header_ticket-manager-row"
        >
          <Flex gap="3" align="center" className="min-w-0">
            <Heading as="h1" size="5" weight="bold">
              <Link asChild data-auth-trigger="projects-link">
                <NavLink
                  to={ticketsRoute.to}
                  search={({ folderId: _folderId, ...prev }) => prev}
                  className="puntt-link"
                >
                  Projects
                </NavLink>
              </Link>
            </Heading>
            <Breadcrumbs
              ticketTitle={
                <>
                  <CaretRight className="shrink-0" />
                  <Expandable
                    maxLines={3}
                    size="1"
                    buttonText={<CaretDown className="shrink-0" />}
                  >
                    <Heading size="4" className="min-w-0">
                      {ticketTitle}
                    </Heading>
                  </Expandable>
                </>
              }
              onDeleteFolder={() => {
                setDeletingTicketId(ticketId);
              }}
              onManageVersions={() => setVersionManagerDialogOpen(true)}
              onRenameFolder={() => setRenameTicketDialogOpen(true)}
              onShareFolder={handleShareRequest}
              onDownloadFolder={() => handleDownloadTicket(ticketId)}
            />
          </Flex>

          <Button
            variant="outline"
            color="blue"
            size="1"
            className={cx({ hidden: !isNil(user?.email) })}
            data-auth-trigger="login-signup-button"
          >
            <UserCircle /> Log In or Sign Up
          </Button>
        </Flex>

        <Flex
          gap="2"
          align="center"
          data-testid="ticket-header_version-manager-row"
        >
          <VersionSelect />

          <MissingFontsIndicator />

          <AuthTooltip trigger="upload-version-cta" classNames="cursor-pointer">
            <Button
              variant="outline"
              size="1"
              onClick={() => setCreateVersionDialogOpen(true)}
              data-testid="ticket-header_upload-version-cta"
            >
              <UploadSimple />
              Upload New Version
            </Button>
          </AuthTooltip>

          <AuthTooltip trigger="share-ticket-cta" classNames="cursor-pointer">
            <Button
              size="1"
              disabled={!versions.length}
              onClick={handleShareRequest}
            >
              <Share /> Share
            </Button>
          </AuthTooltip>
        </Flex>
      </Grid>
    </>
  );
}
