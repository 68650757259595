import { Popover } from "@headlessui/react";
import { Icon, Typography } from "@mg/dali/src";
import { type MyNetworkResponse } from "@mg/schemas/src/christo/myNetwork";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import { type ReactNode } from "react";

import { AvatarWithInitials } from "./AvatarWithInitials";
import { Carousel } from "./Carousel";
import { TooltipAvatar } from "./TooltipAvatar";

import { sendRecommendedInvite } from "../services/network";
import { setWorkedWith } from "../services/workedWith";
import { useAppSelector } from "../utils/hooks";
import { assetForUser } from "../utils/imageHandler";

export function NetworkSummaryCard(
  props: MyNetworkResponse["all"]["results"][number] & {
    presentation: "list" | "grid";
    actions?: ReactNode;
  },
) {
  const {
    actions,
    avatar,
    name,
    title,
    assets = [],
    workedWith = [],
    _id,
    hasWorkedWith = false,
    hasWorkedWithEnterprise = false,
    reason,
    type,
    score,
    matchedHighlights,
    onboarding,
  } = props;
  const user = useAppSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const workedWithMutation = useMutation({
    mutationKey: ["worked-with", _id],
    mutationFn: setWorkedWith,
  });
  const inviteRecommendedMutation = useMutation({
    mutationKey: ["invite-recommended", _id],
    mutationFn: sendRecommendedInvite,
  });

  const displayName = type === "agency" ? name : name.split(" ")[0];
  const isWorkedWithChecked = workedWithMutation.data
    ? workedWithMutation.data?.hasWorkedWith
    : hasWorkedWith;
  const count = workedWithMutation.data?.count ?? workedWith.length;

  const renderedWorkedWith = isWorkedWithChecked
    ? workedWith
    : workedWith.filter((person) => person._id !== user?.userID);

  function renderWorkedWith() {
    if (isWorkedWithChecked && onboarding !== "recommended") {
      return (
        <>
          <Typography
            size="sm"
            weight="medium"
            data-testid="worked-with-header"
          >
            Worked with you{" "}
            {count - 1 === 0 ? null : `and ${count - 1} colleagues`}
          </Typography>

          <section
            className="flex w-full flex-wrap gap-2"
            data-testid="worked-with-avatars"
          >
            {workedWith.map((person) => (
              <TooltipAvatar
                onClick={(e) => {
                  e?.stopPropagation();
                  if (person._id === user?.userID) {
                    workedWithMutation.mutate(_id);
                  }
                }}
                key={person._id}
                name={
                  person._id === user?.userID
                    ? 'Remove "worked with" relationship'
                    : person.name
                }
                _id={person._id}
                avatar={person.avatar}
              />
            ))}
          </section>
        </>
      );
    }

    if (hasWorkedWithEnterprise) {
      return (
        <>
          <Typography
            size="sm"
            weight="medium"
            data-testid="worked-with-header"
          >
            Worked with {count} colleague{count !== 1 ? "s" : null}
          </Typography>

          <div className="flex w-full gap-4 overflow-hidden">
            <button
              onClick={(e) => {
                e.stopPropagation();
                workedWithMutation.mutate(_id);
              }}
              data-testid="worked-with-cta"
              className={cx(
                "flex w-48 min-w-[167px] items-center justify-center gap-2 truncate rounded-full bg-carbon-50 px-3 py-1.5 text-base-black shadow-md transition-colors hover:bg-carbon-100 active:bg-carbon-200",
                {
                  "opacity-50": workedWithMutation.isPending,
                  "bg-ochre-400 hover:bg-ochre-500": isWorkedWithChecked,
                },
              )}
            >
              <Icon.Handshake size={28} className="shrink-0" />
              <Typography
                weight="medium"
                size="sm"
                className="min-w-0 truncate"
                data-testid="worked-with-header"
              >
                I worked with {displayName}
              </Typography>
            </button>

            <section className="flex gap-2" data-testid="worked-with-avatars">
              {renderedWorkedWith.slice(0, 1).map((person) => (
                <TooltipAvatar
                  key={person._id}
                  _id={person._id}
                  name={person.name}
                  avatar={person.avatar}
                />
              ))}
              {renderedWorkedWith[1] != null ? (
                <TooltipAvatar
                  {...renderedWorkedWith[1]}
                  overlayCount={
                    renderedWorkedWith.length === 2
                      ? 0
                      : renderedWorkedWith.length - 1
                  }
                  content={
                    renderedWorkedWith
                      .slice(1)
                      .map((c) => c.name as string) as string[]
                  }
                />
              ) : null}
            </section>
          </div>
        </>
      );
    }

    if (onboarding === "recommended") {
      return (
        <Popover as="div">
          {({ open }) => (
            <div
              className={cx(
                "relative grid bg-carbon-50 text-base-black shadow-md",
                { "rounded-full": !open, "rounded-t-2xl": open },
              )}
            >
              <div className="transition-color flex items-center justify-between truncate">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    inviteRecommendedMutation.mutate(_id);
                  }}
                  className={cx(
                    "flex w-full flex-1 items-center gap-2 truncate px-6 py-1.5 text-base-black transition-colors hover:enabled:bg-carbon-100 active:enabled:bg-carbon-200 disabled:opacity-50",
                    {
                      "bg-malachite-300":
                        inviteRecommendedMutation.isSuccess ||
                        workedWithMutation.isSuccess,
                      "bg-carbon-50": !inviteRecommendedMutation.isSuccess,
                      "rounded-l-full": !open,
                      "rounded-tl-2xl": open,
                    },
                  )}
                  disabled={
                    inviteRecommendedMutation.isPending ||
                    inviteRecommendedMutation.isSuccess ||
                    workedWithMutation.isPending ||
                    workedWithMutation.isSuccess ||
                    (isWorkedWithChecked && onboarding !== "recommended")
                  }
                  data-testid="worked-with-cta"
                >
                  {inviteRecommendedMutation.isPending ||
                  workedWithMutation.isPending ? (
                    <Icon.CircleNotch size={28} className="animate-spin" />
                  ) : inviteRecommendedMutation.isSuccess ||
                    workedWithMutation.isSuccess ? (
                    <Icon.CheckCircle size={28} />
                  ) : (
                    <Icon.UserPlus size={28} />
                  )}
                  <Typography
                    size="sm"
                    weight="medium"
                    className="min-w-0 truncate"
                    data-testid="worked-with-header"
                  >
                    {inviteRecommendedMutation.isSuccess ||
                    workedWithMutation.isSuccess
                      ? "Added to my network"
                      : `Add ${displayName} to my network`}
                  </Typography>
                </button>
                <Popover.Button className="h-full">
                  <div
                    className={cx(
                      "grid h-full items-center border-l border-carbon-200 pl-2 pr-4 hover:bg-carbon-100 active:bg-carbon-200",
                      {
                        "bg-malachite-300":
                          inviteRecommendedMutation.isSuccess ||
                          (workedWithMutation.isSuccess && !open),
                        "rounded-tr-2xl bg-carbon-200": open,
                        "rounded-r-full": !open,
                      },
                    )}
                  >
                    <svg
                      className="size-4"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </Popover.Button>
              </div>

              <Popover.Panel className="absolute top-full w-full">
                <Popover.Button
                  onClick={(e) => {
                    e.stopPropagation();
                    workedWithMutation.mutate(_id);
                  }}
                  className={cx(
                    "flex w-full flex-1 items-center gap-2 truncate rounded-b-2xl border-t border-carbon-200 bg-carbon-50 py-1.5 pl-6 text-base-black transition-colors hover:bg-carbon-100 active:bg-carbon-200",
                    {
                      "opacity-50": workedWithMutation.isPending,
                      "bg-malachite-300": isWorkedWithChecked,
                    },
                  )}
                  data-testid="worked-with-cta"
                >
                  <Icon.Handshake size={28} className="shrink-0" />
                  <Typography
                    weight="medium"
                    size="sm"
                    data-testid="worked-with-header"
                    className="min-w-0 truncate"
                  >
                    I worked with {displayName}
                  </Typography>
                </Popover.Button>
              </Popover.Panel>
            </div>
          )}
        </Popover>
      );
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          workedWithMutation.mutate(_id);
        }}
        className={cx(
          "flex items-center justify-center gap-2 truncate rounded-full bg-carbon-50 px-3 py-1.5 text-base-black shadow-md transition-colors hover:bg-carbon-100 active:bg-carbon-200",
          {
            "opacity-50": workedWithMutation.isPending,
            "bg-malachite-300": isWorkedWithChecked,
          },
        )}
        data-testid="worked-with-cta"
      >
        <Icon.Handshake size={28} className="shrink-0" />
        <Typography
          size="sm"
          weight="medium"
          data-testid="worked-with-header"
          className="min-w-0 truncate"
        >
          I worked with {displayName}
        </Typography>
      </button>
    );
  }

  return (
    <button
      className={cx(
        "relative grid min-w-[275px] rounded-2xl bg-base-white text-left shadow-md",
      )}
      onContextMenu={() => {
        console.log(
          `Matched results for ${name} - Score ${score}:`,
          matchedHighlights,
        );
      }}
      onClick={() => {
        if (reason != null) {
          return window.open(props.linkedIn, "_blank");
        }

        navigate({
          // @ts-expect-error TS2322: TanStack Router types can't handle changing search params without specifying a route, but this component can be used on multiple routes
          search(prev) {
            return {
              ...prev,
              port: _id,
            };
          },
        });
      }}
      data-testid="network-person-card"
    >
      <Carousel
        className={cx("rounded-t-2xl [&>figure]:h-[184px] [&>figure]:w-full", {
          hidden: reason != null,
        })}
        data-testid="card-hero"
        images={assets.map((asset) => assetForUser(asset, 480)) as string[]}
        wrapAround
      />

      <figure className="flex items-center gap-4 border-b border-carbon-300 p-4">
        <AvatarWithInitials
          avatar={assetForUser(avatar)}
          name={name}
          size={16}
          hasWorkedWith={hasWorkedWith}
        />

        <span className="grid truncate">
          <Typography
            size="lg"
            weight="bold"
            className="truncate"
            data-testid="card-name"
          >
            {name}
          </Typography>

          <Typography
            size="sm"
            weight="normal"
            className="truncate text-carbon-600"
            data-testid="card-role"
          >
            {title}
          </Typography>
        </span>
      </figure>

      <figure className="grid gap-4 p-4">
        <figcaption className="grid gap-2" data-testid="card-worked-with">
          <Typography
            size="sm"
            weight="medium"
            className={cx("mb-2 overflow-hidden truncate", {
              hidden: reason == null,
            })}
          >
            {reason}
          </Typography>
          {renderWorkedWith()}
        </figcaption>
      </figure>

      <div
        data-testid="card-actions"
        className={cx(
          "absolute right-0 top-0 flex items-center gap-2 rounded-bl-2xl rounded-tr-2xl bg-carbon-300 px-4 py-2 mix-blend-luminosity backdrop-blur",
          {
            hidden: actions == null,
          },
        )}
      >
        {actions}
      </div>
    </button>
  );
}
