import {
  Menu as HMenu,
  type MenuProps as HMenuProps,
  Transition,
} from "@headlessui/react";
import { cx } from "class-variance-authority";
import React, { Fragment, type ReactNode } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Icon, Typography } from "../foundation";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

/**
 * TODO: this is a one-off component. In order to improve portability and the
 * overall dev experience, we should add more flexibility to it.
 *
 * It's also possible that Headless's `Popover` component is a likely candidate
 * to use for this component under the hood.
 */

export type Item = {
  label: string;
  leadingIcon?: ReactNode;
  onClick(): void;
  disabled?: boolean;
  className?: string;
};

export type MenuProps = {
  label?: string;
  labelIcon?: ReactNode;
  /**
   * The items to be shown in the list
   */
  items: Item[];
} & HMenuProps<"div">;

export function Menu(props: MenuProps) {
  const { label, labelIcon, className: hash, items, ...rest } = props;
  const classes = cx(twMerge("dali-relative"), hash);

  return (
    <HMenu as="div" className={classes} {...rest}>
      <HMenu.Button className="dali-bg-base-white dali-flex dali-items-center dali-gap-4 dali-px-4 dali-py-1.5 dali-rounded-lg">
        <Typography
          size="base"
          weight="normal"
          className={cx("dali-text-base-black", {
            hidden: label == null || label.length === 0,
          })}
        >
          {label ?? "Menu"}
        </Typography>
        {labelIcon ?? <Icon.List />}
      </HMenu.Button>

      <Transition
        as={Fragment}
        leave="dali-transition dali-ease-in dali-duration-100"
        leaveFrom="dali-opacity-100"
        leaveTo="dali-opacity-0"
      >
        <HMenu.Items className="dali-bg-base-white dali-overflow-hidden dali-grid dali-absolute dali-right-0 dali-mt-[-2px] dali-origin-top-left dali-rounded-lg dali-border-2 dali-border-carbon-300">
          {items.map((v, i) => (
            // TODO: implement item disabling
            <HMenu.Item
              key={`.${i}.${v.label}`}
              disabled={v.disabled}
              className={v.className}
              as="div"
            >
              {({ active }) => (
                <button
                  onClick={v.onClick}
                  className={cx(
                    "dali-px-4 dali-py-2 dali-text-left dali-flex dali-items-center dali-gap-4",
                    {
                      "dali-bg-carbon-50": active,
                      "dali-opacity-50": v.disabled,
                    },
                  )}
                >
                  {v.leadingIcon}
                  <Typography
                    as="span"
                    size="base"
                    weight="normal"
                    className="dali-text-base-black dali-flex-1 dali-whitespace-nowrap"
                  >
                    {v.label}
                  </Typography>
                </button>
              )}
            </HMenu.Item>
          ))}
        </HMenu.Items>
      </Transition>
    </HMenu>
  );
}
