import { useMutation } from "@tanstack/react-query";

import { askQAQuestion } from "../../services/workflows";

export function useAskQAQuestionMutation() {
  return useMutation({
    mutationKey: ["ask-qa"],
    mutationFn: askQAQuestion,
  });
}
