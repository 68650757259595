import cx from "classnames";
import { type ComponentProps, forwardRef } from "react";

export const SidebarMenu = forwardRef<HTMLUListElement, ComponentProps<"ul">>(
  ({ className, ...props }, ref) => (
    <ul
      ref={ref}
      data-sidebar="menu"
      className={cx("flex w-full min-w-0 flex-col gap-2", className)}
      {...props}
    />
  ),
);
SidebarMenu.displayName = "SidebarMenu";
