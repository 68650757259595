import { Flex } from "@radix-ui/themes";
import cx from "classnames";
import { ComponentProps, forwardRef } from "react";

import { useSidebar } from "./useSidebar";

type ExtendedComponentProps = ComponentProps<"div"> & {
  /**
   * The side of the sidebar. Defaults to "left".
   */
  side?: "left" | "right";
  /**
   * The variant of the sidebar. Defaults to "sidebar".
   * Note: if you use the `inset` variant, remember to wrap your main content in
   * a `SidebarInset` component.
   */
  variant?: "sidebar" | "floating" | "inset";
  /**
   * Collapsible state of the sidebar.
   *
   *  * `offcanvas` - A collapsible sidebar that slides in from the left or
   *  right.
   *  * `icon` - A sidebar that collapses to icons (default)
   *  * `none` - A non-collapsible sidebar.
   */
  collapsible?: "offcanvas" | "icon" | "none";
};

//const SIDEBAR_WIDTH_MOBILE = "18.75rem";

export const Sidebar = forwardRef<HTMLDivElement, ExtendedComponentProps>(
  (props, ref) => {
    const {
      side = "left",
      variant = "sidebar",
      collapsible = "icon",
      className,
      children,
      ...rest
    } = props;
    const { state /*, isMobile, openMobile, setOpenMobile */ } = useSidebar();

    if (collapsible === "none") {
      return (
        <Flex
          height="100%"
          width="var(--sidebar-width)"
          direction="column"
          className={cx("bg-white", className)}
          ref={ref}
          {...rest}
        >
          {children}
        </Flex>
      );
    }

    //if (isMobile) {
    // TODO: implement me :) should probably use something like a dialog
    // primitive
    //  return null;
    //}

    return (
      <div
        ref={ref}
        className="group peer"
        // TODO: uncomment when mobile is implemented
        //className="group peer hidden md:block"
        data-state={state}
        data-collapsible={state === "collapsed" ? collapsible : ""}
        data-variant={variant}
        data-side={side}
      >
        {/* This is what handles the sidebar gap on desktop */}
        <div
          className={cx(
            "relative h-svh w-[--sidebar-width] bg-base-white transition-[width] duration-200 ease-out",
            "group-data-[collapsible=offcanvas]:w-0",
            "group-data-[side=right]:rotate-180",
            variant === "floating" || variant === "inset"
              ? "group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]"
              : "group-data-[collapsible=icon]:w-[--sidebar-width-icon]",
          )}
        />

        <div
          className={cx(
            "fixed inset-y-0 z-10 flex h-svh w-[--sidebar-width] transition-[left,right,width] duration-200 ease-out",
            side === "left"
              ? "left-0 group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]"
              : "right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]",
            // Adjust the padding for floating and inset variants.
            variant === "floating" || variant === "inset"
              ? "p-2 group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]"
              : "group-data-[collapsible=icon]:w-[--sidebar-width-icon] group-data-[side=left]:border-r group-data-[side=right]:border-l",
            className,
          )}
          {...props}
        >
          <Flex
            height="100%"
            width="100%"
            direction="column"
            data-sidebar="sidebar"
            className="bg-sidebar group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:border-puntt-neutral-gray-4 group-data-[variant=floating]:shadow"
          >
            {children}
          </Flex>
        </div>
      </div>
    );
  },
);

Sidebar.displayName = "Sidebar";
