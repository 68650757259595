import { ButtonNew, Icon, Typography } from "@mg/dali/src";
// eslint-disable-next-line import/named
import { Link } from "@tanstack/react-router";
import cx from "classnames";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAppSelector } from "../../../utils/hooks";
import { newMessageRoute } from "../../new-message/route";
import { threadRoute } from "../../thread/route";
import { useMessagesContext } from "../route";

export function formatDateString(inputDate: string | Date): string {
  const currentDate = new Date();
  const inputDateObj = new Date(inputDate);

  if (
    inputDateObj.getDate() === currentDate.getDate() &&
    inputDateObj.getMonth() === currentDate.getMonth() &&
    inputDateObj.getFullYear() === currentDate.getFullYear()
  ) {
    // Return the time in local time
    let hours = inputDateObj.getHours();
    const minutes = inputDateObj.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
  }

  // Check if the input date is yesterday
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  if (
    inputDateObj.getDate() === yesterday.getDate() &&
    inputDateObj.getMonth() === yesterday.getMonth() &&
    inputDateObj.getFullYear() === yesterday.getFullYear()
  ) {
    return "Yesterday";
  }

  // Return the date for any other day
  const formattedDate = `${inputDateObj.getDate()}/${
    inputDateObj.getMonth() + 1
  }/${inputDateObj.getFullYear()}`;
  return formattedDate;
}

export function MessagesSidebar() {
  const messages = useAppSelector((state) => state.messages.messages);
  const { setMobileDrawerVisible } = useMessagesContext();

  return (
    <aside
      className={cx(
        "grid grid-rows-[max-content,1fr] overflow-hidden border-r border-r-carbon-300 pb-24 md:px-5 md:py-4 md:pb-0",
        {
          "min-w-3/4 w-3/4 md:w-80 md:min-w-80":
            window.location.pathname !== "/messages",
          "w-full": window.location.pathname === "/messages",
        },
      )}
    >
      <div className="flex items-center justify-between border-b border-carbon-200 pb-5">
        <Typography size="2xl" weight="medium">
          Messages
        </Typography>
        <Link to={newMessageRoute.to} className="puntt-link">
          <ButtonNew variant="ghost" size="sm">
            <Icon.PencilSimpleLine size={24} />
          </ButtonNew>
        </Link>
      </div>
      <div
        className={cx("mt-4 grid gap-4 overflow-y-auto", {
          "auto-rows-max": messages != null && messages.length > 0,
        })}
      >
        {messages == null || messages.length === 0 ? (
          <div className="grid flex-1 place-content-center place-items-center">
            <Icon.EnvelopeSimpleOpen className="mx-auto" size={72} />
            <Typography
              size="2xl"
              className="place-self-center text-carbon-600"
            >
              No message found!
            </Typography>
            <Link
              to={newMessageRoute.to}
              className="puntt-link flex items-center gap-2"
            >
              <Icon.PencilSimpleLine />
              <Typography weight="medium">Send a new message</Typography>
            </Link>
          </div>
        ) : (
          <>
            {messages.map((m) => {
              const isMulti = m.recipients.length > 1;
              const recipient = m.recipients[0];
              return (
                <button
                  className="w-full overflow-hidden"
                  key={m._id}
                  onClick={() => setMobileDrawerVisible(false)}
                >
                  <Link
                    className={cx(
                      "puntt-link flex items-center gap-2 truncate rounded-lg bg-[#F9F9F9] px-3 py-2",
                      {
                        "border-carbon-200 bg-carbon-50": m.unreadMessages > 0,
                      },
                    )}
                    key={recipient._id}
                    to={threadRoute.to}
                    params={{ threadId: m._id }}
                    activeProps={{
                      className: "bg-vermillion-50",
                    }}
                  >
                    <AvatarWithInitials
                      avatar={recipient.avatar}
                      name={recipient.name}
                      size={11}
                    />
                    {isMulti && (
                      <div className="-ml-6 flex size-11 items-center justify-center rounded-full bg-carbon-200">
                        <Typography
                          weight={m.unreadMessages > 0 ? "bold" : "normal"}
                        >
                          +{m.recipients.length - 1}
                        </Typography>
                      </div>
                    )}
                    <div className="flex-1 truncate">
                      <div className="flex justify-between gap-1">
                        <Typography
                          as="span"
                          className="w-auto truncate"
                          weight={m.unreadMessages > 0 ? "bold" : "normal"}
                        >
                          {isMulti
                            ? recipient.name?.split(" ")[0]
                            : recipient.name}
                          {isMulti
                            ? ", " +
                              m.recipients[1].name?.split(" ")[0] +
                              " and others"
                            : ""}
                        </Typography>
                        {!!m.lastMessage.updatedAt && (
                          <Typography
                            as="span"
                            size="xs"
                            weight={m.unreadMessages > 0 ? "bold" : "normal"}
                          >
                            {formatDateString(m.lastMessage.updatedAt)}
                          </Typography>
                        )}
                      </div>

                      <Typography
                        className="text-ellipses w-auto truncate text-left text-carbon-500"
                        weight={m.unreadMessages > 0 ? "bold" : "normal"}
                      >
                        {m.lastMessage.content}
                      </Typography>
                    </div>
                  </Link>
                </button>
              );
            })}
          </>
        )}
      </div>
    </aside>
  );
}
