import {
  // eslint-disable-next-line import/named
  createRoute,
} from "@tanstack/react-router";

import { setDrawerOpen } from "../../../utils/slices/ui";
import { store } from "../../../utils/store";
import { authLayoutRoute } from "../../auth-layout/route";
import { TutorialRevision } from "../components/TutorialRevision";

export const tutorialTicketRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "tutorial-ticket",
  component: TutorialRevision,
  onEnter() {
    store.dispatch(setDrawerOpen({ app: false }));
  },
});
