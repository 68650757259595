import { visit } from "unist-util-visit";

interface Options {
  customClassName: string;
}

/**
 * Reformats ^# to <sup>#</sup> and _# to <sub>#</sub>
 */
function plugin(
  options: Options = { customClassName: "markdown-positional-script" },
) {
  return (tree: /* hast.Root */ any) => {
    const matcher = /(\^|_)(\d+)\b/g;

    visit(tree, "text", function (node, index, parent) {
      if (!parent || index === null) return;

      // Skip if this node was created by us
      if (node.data?.processedByPositionalScript) return;

      // Skip if this is a URL
      if (parent.tagName === "a" && parent.properties?.href === node.value)
        return;

      const text = "value" in node && node.type === "text" ? node.value : "";

      let match;
      let lastIndex = 0;
      const newChildren: any[] = [];

      // Split text node and insert super/subscripts
      while ((match = matcher.exec(text)) !== null) {
        const [fullMatch, prefix, number] = match;

        // Add text before script code
        if (match.index > lastIndex) {
          newChildren.push({
            type: "text",
            value: text.slice(lastIndex, match.index),
            data: { processedByPositionalScript: true },
          });
        }

        // Add sup or sub element based on prefix
        const isSuperscript = prefix === "^";
        newChildren.push({
          type: "element",
          tagName: isSuperscript ? "sup" : "sub",
          properties: {
            className: [options.customClassName],
          },
          children: [
            {
              type: "text",
              value: number,
              data: { processedByPositionalScript: true },
            },
          ],
        });

        lastIndex = match.index + fullMatch.length;
      }

      // Add remaining text
      if (lastIndex < text.length) {
        newChildren.push({
          type: "text",
          value: text.slice(lastIndex),
          data: { processedByPositionalScript: true },
        });
      }

      // Replace original node with new children if we found any matches
      if (newChildren.length > 0) {
        parent.children.splice(index, 1, ...newChildren);
      }
    });
  };
}

export default plugin;
