import { ArrowRight, DotsThreeOutlineVertical } from "@phosphor-icons/react";
import { amber, teal } from "@radix-ui/colors";
import {
  Box,
  Button,
  Card,
  DropdownMenu,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  Text,
} from "@radix-ui/themes";
import { Link as NavLink } from "@tanstack/react-router";
import { formatDistanceToNow } from "date-fns";

import { indexRoute } from "./route";

import MessagingFramework from "../../images/enverus.png";
import Gradient from "../../images/image.png";
import RisingVoices4 from "../../images/indeed.png";
import { Review } from "../../services/reviews";
import { renderPersonaAvatar } from "../../utils/helpers/ai-persona-avatars";
import { createCombinatorString } from "../../utils/helpers/arrays";
import { sortAlphabetically } from "../../utils/helpers/sorters";
import { useAppSelector } from "../../utils/hooks";
import { workflowRoute } from "../workflow/route";
import { chatRoute } from "../workflow/routes/chat/route";

export default function PunttDashboardView() {
  const { aiPersonas, recents } = indexRoute.useLoaderData();

  const availablePersonas = aiPersonas.filter((p) => p.enabled);

  const user = useAppSelector((state) => state.auth.value);

  const firstName = (user!.name?.split(" ") ?? "")[0];
  const hasFirstName = firstName !== "";

  const selectedPersonas = sortAlphabetically(
    availablePersonas.map((p) => p.name),
  );

  function renderPersonaDescription(personas: string[]): string {
    if (personas.length === 0) return "";
    if (personas.length === availablePersonas.length) {
      return "Provide a comprehensive assessment of all potential problems and improvements.";
    }

    const concatenated = personas.join("|");

    switch (concatenated) {
      case "Brand":
        return "Catch off-brand colors, layouts, icons, and other visual discrepancies";
      case "Copy":
        return "Get feedback on spelling, grammar, clarity, and brand voice.";
      case "Legal":
        return "Review claims, trademarks, disclosures, and other compliance issues.";
      case "Brand|Copy":
        return "Find mistakes and suggested enhancements in both visuals and text.";
      case "Brand|Legal":
        return "Identify non-compliance with either brand or legal guidelines.";
      case "Copy|Legal":
        return "Analyze copy editing as well as potential legal risks.";
      default:
        return "";
    }
  }

  return (
    <Grid py="6" px="4" gap="9" rows="repeat(3, max-content)" overflow="auto">
      <Heading size="8" weight="regular" data-testid="dashboard-heading">
        Welcome{hasFirstName ? `, ${firstName}` : null}!
      </Heading>

      <Grid gap="4" height="max-content" data-testid="review-workflows">
        <Flex data-testid="review-workflows-header" gap="6" align="center">
          <Text size="4" weight="medium">
            Start a Workflow
          </Text>
        </Flex>

        <Grid
          columns={{ sm: "1", md: "2", lg: "3" }}
          gap="4"
          className="auto-rows-[1fr]"
        >
          <Box
            p="1px"
            className="group relative rounded-[13px]"
            style={{
              backgroundImage: generateLinearGradient(["Q&A"]),
            }}
          >
            {/* Div for the gradient hover shadow */}
            <div
              className="-z-1 absolute -inset-0.5 opacity-0 blur-md transition-opacity group-hover:opacity-35"
              style={{
                backgroundImage: generateLinearGradient(["Q&A"]),
              }}
            />
            <Card
              size="2"
              className="grid size-full gap-2 bg-base-white after:shadow-none"
            >
              <Flex align="center" gap="4" justify="between">
                <Text size="3" weight="bold">
                  Ask Puntt
                </Text>
              </Flex>

              <Text>
                Get insights from brand, marketing and compliance data.
              </Text>
            </Card>

            <NavLink to={chatRoute.to} className="absolute inset-0">
              <span className="sr-only">Ask Puntt</span>
            </NavLink>
          </Box>
          {createPersonaPermutations(selectedPersonas).map((personas) => (
            <Box
              key={personas.join("|")}
              p="1px"
              className="group relative rounded-[13px]"
              style={{
                backgroundImage: generateLinearGradient(
                  personas as GradientValue[],
                ),
              }}
            >
              {/* Div for the gradient hover shadow */}
              <div
                className="-z-1 absolute -inset-0.5 opacity-0 blur-md transition-opacity group-hover:opacity-35"
                style={{
                  backgroundImage: generateLinearGradient(
                    personas as GradientValue[],
                  ),
                }}
              />
              <Card
                size="2"
                className="grid size-full gap-2 bg-base-white after:shadow-none"
              >
                <Flex align="center" gap="4" justify="between">
                  <Text size="3" weight="bold">
                    {createCombinatorString(personas)} Review
                  </Text>

                  <Flex gap="3">
                    {personas.map((persona) => renderPersonaAvatar(persona))}
                  </Flex>
                </Flex>

                <Text>{renderPersonaDescription(personas)}</Text>
              </Card>

              <NavLink
                to={workflowRoute.to}
                search={{ personas }}
                className="absolute inset-0"
              >
                <span className="sr-only">
                  {createCombinatorString(personas)} Review
                </span>
              </NavLink>
            </Box>
          ))}
        </Grid>
      </Grid>

      <Grid gap="3">
        <Flex align="center" justify="between">
          <Text size="4" weight="medium">
            Recent Workflows
          </Text>

          <Button size="1" variant="ghost" color="gray">
            All Reviews <ArrowRight />
          </Button>
        </Flex>

        <Grid
          columns={{ sm: "1", md: "2", lg: "3" }}
          gap="4"
          className="auto-rows-[1fr]"
        >
          {recents.map((r) => (
            <RecentReviewCard key={r._id} {...r} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

function RecentReviewCard(props: Review) {
  const { name, results } = props;

  const _thumbnailUrl = results[0].tickets[0].thumbnail;
  const createdAt = results[0].createdAt;
  const uniquePersonasUsed = Array.from(
    new Set(results.flatMap((r) => r.personas)),
  );

  const demoImages = [MessagingFramework, Gradient, RisingVoices4];
  const randomImage = demoImages[Math.floor(Math.random() * demoImages.length)];

  return (
    <Card>
      <Flex gap="4">
        <Box className="aspect-video h-[52px]">
          <img src={randomImage} alt={`${name} thumbnail`} />
        </Box>

        <Grid gap="1" className="flex-1" align="start">
          <Flex gap="1" justify="between" align="center">
            <Link size="3" truncate className="puntt-link">
              {name}
            </Link>

            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <IconButton size="1" variant="ghost" color="gray">
                  <DotsThreeOutlineVertical />
                </IconButton>
              </DropdownMenu.Trigger>
            </DropdownMenu.Root>
          </Flex>

          <Flex gap="1" justify="between" align="center">
            <Flex gap="1">
              {uniquePersonasUsed.map((persona) =>
                renderPersonaAvatar(persona),
              )}
            </Flex>

            <Text size="1" color="gray">
              {formatDistanceToNow(createdAt)} ago
            </Text>
          </Flex>
        </Grid>
      </Flex>
    </Card>
  );
}

// This uses a backtracking algo to generate all possible combinations of
// personas. This ensures that there are no duplicates in any order as each
// combination is generated only once and the elements within each combination
// maintain their relative order from the input personas array.
function createPersonaPermutations(personas: string[]): string[][] {
  const result: string[][] = [];

  function backtrack(start: number, current: string[]) {
    result.push([...current]);

    for (let i = start; i < personas.length; i++) {
      current.push(personas[i]);
      backtrack(i + 1, current);
      current.pop();
    }
  }

  backtrack(0, []);
  return result
    .slice(1) // Remove the empty combination
    .map((p) => sortAlphabetically(p))
    .sort((a, b) => {
      // First sort by array length
      if (a.length !== b.length) {
        return a.length - b.length;
      }
      // Then sort alphabetically by joining arrays into strings
      return a.join(" & ").localeCompare(b.join(" & "));
    });
}

type GradientValue = "Brand" | "Legal" | "Copy" | "Q&A";

function generateLinearGradient(values: GradientValue[]): string {
  const colorMap: Record<GradientValue, string> = {
    Brand: amber.amber7,
    Legal: teal.teal7,
    Copy: "rgb(var(--puntt-blue-7))",
    "Q&A": "rgb(var(--puntt-blue-10))",
  };

  if (values.length === 1) {
    return `linear-gradient(to bottom, ${colorMap[values[0]]}, ${colorMap[values[0]]})`;
  }

  const uniqueValues = Array.from(new Set(values));
  const stopPercentage = 100 / (uniqueValues.length - 1);

  const gradientStops = uniqueValues.map((value, index) => {
    const percentage = index * stopPercentage;
    return `${colorMap[value]} ${percentage}%`;
  });

  return `linear-gradient(to bottom right, ${gradientStops.join(", ")})`;
}
