import cx from "classnames";
import { type ComponentProps, forwardRef } from "react";

import { useSidebar } from "./useSidebar";

export const SidebarHeader = forwardRef<HTMLDivElement, ComponentProps<"div">>(
  ({ className, ...props }, ref) => {
    const { open } = useSidebar();

    return (
      <div
        ref={ref}
        data-sidebar="header"
        className={cx(
          "flex flex-col gap-2 border-b border-b-puntt-neutral-gray-6 transition-all",
          { "p-4": open, "p-3": !open },
          className,
        )}
        {...props}
      />
    );
  },
);
SidebarHeader.displayName = "SidebarHeader";
