type Callback = (...args: any[]) => void;

export class EventEmitter {
  events_: Record<string, Callback[]> = {};

  /**
   * Appends a callback to a named event
   *
   * @param {string} event a string event identifier
   * @param {Function} cb a function appended to the events
   */
  on(event: string, cb: Callback) {
    this.events_ = this.events_ || {};
    this.events_[event] = this.events_[event] || [];
    this.events_[event].push(cb);
  }

  /**
   * Removes a callback from a named event
   *
   * @param {string} event a string event identifier
   * @param {Function} cb a function appended to the events
   */
  off(event: string, cb: Callback) {
    this.events_ = this.events_ || {};

    if (!(event in this.events_)) return;

    this.events_[event].splice(this.events_[event].indexOf(cb), 1);
  }

  /**
   * Manually triggers callbacks at a given event name
   *
   * @param {string} event a string event identifier
   */
  trigger(event: string) {
    this.events_ = this.events_ || {};

    if (!(event in this.events_)) return;

    for (let i = 0; i < this.events_[event].length; i += 1) {
      this.events_[event][i].apply(
        this,
        // eslint-disable-next-line prefer-rest-params
        Array.prototype.slice.call(arguments, 1),
      );
    }
  }
}

export class ArrayEmitter<T> extends EventEmitter {
  array: T[] = [];

  /**
   * Appends one or more items to the array queue
   *
   * @param  {...any} items items to add to the array queue
   * @returns {Array} the current array queue with the new items appended
   */
  push(...items: T[]) {
    const result = this.array.push(...items);
    this.trigger("change");

    return result;
  }

  /**
   * Empties the current array queue
   */
  empty() {
    this.array.length = 0;
    this.trigger("change");
  }

  /**
   * Removes an item from the current array queue
   *
   * @param {any} item item to be removed from the array queue
   * @returns {boolean} true if the item found and removed, otherwise false
   */
  remove(item: T) {
    const index = this.array.indexOf(item);

    if (index > -1) {
      this.array.splice(index, 1);
      this.trigger("change");

      return true;
    }

    return false;
  }
}
