import { datadogRum } from "@datadog/browser-rum";
import {
  type LoginResponse,
  type ShowcaseToken,
} from "@mg/schemas/src/prince/auth";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

import { posthogClient } from "../../config/posthog";
import { logout as logoutService } from "../../services/auth";
import { queryClient } from "../queryClient";

type AuthState = {
  /*
  The relevant fields are typically:
  - If authenticated: email, enterpriseId, exp, name, role, userID
  - If unauthenticated: enterpriseId, exp, referredBy, ticketId/folderId
  */
  value: ShowcaseToken | null;
};

const initialState: AuthState = {
  value: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /**
     * Given a token, decodes the token and sets the user object.
     * Note: This should be invoked immediately after hitting an endpoint that
     * returns a token. This action does not check for token expiry before
     * setting the user. To check token expiry, use `derive` instead.
     */
    login(state, action: PayloadAction<LoginResponse>) {
      const decoded = jwtDecode<ShowcaseToken>(action.payload.token, {});

      // will error at this point if invalid token.

      localStorage.setItem("token", action.payload.token);
      sendTokenToServiceWorker();

      datadogRum.setUser({
        id: decoded.userID,
        ...decoded,
      });
      posthogClient.reset(); // The root route will update the PostHog identity when the auth value changes
      state.value = decoded;
    },
    /**
     * Removes the token from localStorage and sets the state to `null`.
     */
    logout(state, action: PayloadAction<string | undefined>) {
      localStorage.removeItem("token");
      localStorage.removeItem("onboarding_flow");
      sendTokenToServiceWorker();
      state.value = null;

      // Clear session cookie by calling API endpoint
      logoutService();

      // reset query client
      queryClient.invalidateQueries();
      queryClient.clear();

      // reset analytics
      posthogClient.reset();

      // reset Datadog user
      datadogRum.setUser({});

      if (action.payload != null) {
        localStorage.setItem("redirect", action.payload);
      }
    },
    derive(state) {
      // TODO: use this temporarily until parsed search is added back to router.
      const search = new URLSearchParams(window.location.search);
      const urlToken = search.get("t");
      const refreshedToken = search.get("refreshedToken");
      const localToken = localStorage.getItem("token");
      const listRedirect = search.get("r");
      const tokenIsExpired = (token: ShowcaseToken) =>
        Date.now() / 1000 > (token.exp as number);

      let defaultUser: (ShowcaseToken & { listPath?: string }) | null = null;
      if (urlToken && !tokenIsExpired(jwtDecode<ShowcaseToken>(urlToken))) {
        localStorage.setItem("urlToken", urlToken);
        search.delete("t");
      }

      function applyToken(decodedToken: ShowcaseToken, token: string) {
        defaultUser = decodedToken;
        localStorage.setItem("token", token);

        datadogRum.setUser({
          id: defaultUser.userID,
          ...defaultUser,
        });
        if (token !== localToken) {
          posthogClient.reset(); // The root route will update the PostHog identity when the auth value changes
        }
      }

      let decodedToken: ShowcaseToken;
      // If there is a valid local token, always use that
      if (refreshedToken) {
        decodedToken = jwtDecode<ShowcaseToken>(refreshedToken);
        applyToken(decodedToken, refreshedToken);
      } else if (
        localToken &&
        (decodedToken = jwtDecode<ShowcaseToken>(localToken)) &&
        !tokenIsExpired(decodedToken) &&
        (decodedToken.email ||
          !urlToken ||
          tokenIsExpired(jwtDecode<ShowcaseToken>(urlToken)))
      ) {
        applyToken(decodedToken, localToken);
      }
      // Else if there is a valid URL token, use that
      else if (
        urlToken &&
        !tokenIsExpired((decodedToken = jwtDecode<ShowcaseToken>(urlToken)))
      ) {
        if (!decodedToken.email) {
          if (decodedToken.ticketId) {
            sessionStorage.setItem(`${decodedToken.ticketId}|token`, urlToken);
          } else if (decodedToken.folderId) {
            sessionStorage.setItem(`${decodedToken.folderId}|token`, urlToken);
          }
        }

        applyToken(decodedToken, urlToken);
      }
      // If neither is valid, remove the token
      else {
        localStorage.removeItem("token");
      }

      if (listRedirect) {
        localStorage.setItem("listPath", listRedirect);
      }

      sendTokenToServiceWorker();
      state.value = defaultUser;
    },
  },
});

export const { derive, login, logout } = authSlice.actions;
