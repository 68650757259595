import { useAppSelector } from "./hooks";

import { posthogClient } from "../config/posthog";

export const useSelections = () => {
  //   const [selections, setSelections] = useState<string[]>([]);
  //   const networkSelections = useAppSelector(
  //     (state) => state.network?.selections,
  //   );
  //   const listsSelections = useAppSelector((state) => state.lists?.selections);
  //   const profileValue = useAppSelector((state) => state.profile?.value);

  //   useEffect(() => {
  //     console.log("networkSelections", networkSelections);
  //     if (window.location.pathname.includes("network")) {
  //       setSelections(networkSelections ?? []);
  //       console.log('set')
  //     } else if (window.location.pathname.includes("lists")) {
  //       setSelections(listsSelections ?? []);
  //     } else if (window.location.pathname.includes("profiles")) {
  //       profileValue?._id ? [profileValue?._id] : [];
  //     }
  //     setSelections([]);
  //   }, [networkSelections, listsSelections, profileValue?._id]);

  //   return selections;

  return useAppSelector((state) => {
    if (window.location.pathname.includes("network")) {
      return state.network?.selections;
    } else if (window.location.pathname.includes("lists")) {
      return state.lists?.selections;
    }
    return state.profile?.value?._id;
  });
};

const defaultRuleMap = {
  brand: "Branding",
  text: "Content/Copy",
  preference: "Preference",
  technical: "Technical",
  legal: "Legal",
};

export const getAiRuleMap = (): { [key: string]: string } => {
  const aiRulesMapFlag =
    posthogClient.getFeatureFlagPayload("ai-rules-available");

  if (aiRulesMapFlag) {
    return aiRulesMapFlag as { [key: string]: string };
  }

  return defaultRuleMap;
};
