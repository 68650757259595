import {
  StateNode,
  type TLEventHandlers,
  Vec,
  type TLPointerEvent,
  type TLPointerEventInfo,
  type TLShape,
} from "@tldraw/tldraw";
import "tldraw/tldraw.css";

import { CommentShapeTool } from "./CommentTool/CommentTool";

import { createAndSelectCommentShape } from "../../../utils/tldraw/comments";
const HIT_TEST_MARGIN = 8;
// [1]
export class ComboTool extends StateNode {
  static override id = "combo";
  static override initial = "idle";
  static override children = () => [
    Idle,
    Dragging,
    CommentShapeTool,
    MovingComment,
  ];
}

// [2]
class Idle extends StateNode {
  static override id = "idle";
  shouldOpenCommentTool = false;
  info = {} as TLPointerEventInfo & { target: "handle" };

  override onEnter = (info: TLPointerEventInfo & { target: "handle" }) => {
    this.info = info;
    this.editor.setCursor({ type: "grab", rotation: 0 });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override onPointerDown: TLEventHandlers["onPointerDown"] = (info) => {
    const { editor } = this;

    const selectedShapes = editor.getSelectedShapes();
    const hitShape = editor.getShapeAtPoint(editor.inputs.currentPagePoint);
    if (
      hitShape &&
      (hitShape.type === "comment" || hitShape.type === "comment-avatar")
    ) {
      this.editor.setSelectedShapes([hitShape.id]);
    } else {
      if (
        selectedShapes.filter(
          (shape) =>
            shape.type === "comment" || shape.type === "comment-avatar",
        ).length > 0
      ) {
        editor.selectNone();
        return;
      }
      this.shouldOpenCommentTool = true;
    }
  };
  override onPointerMove: TLEventHandlers["onPointerMove"] = (info) => {
    const { editor } = this;
    const hitShape = editor.getShapeAtPoint(editor.inputs.currentPagePoint, {
      hitInside: false,
      hitLabels: false,
      margin: HIT_TEST_MARGIN / editor.getZoomLevel(),
      renderingOnly: true,
    });
    if (editor.inputs.isDragging) {
      if (
        hitShape &&
        (hitShape.type === "comment" || hitShape.type === "comment-avatar")
      ) {
        this.parent.transition("moving", hitShape);
      } else {
        this.shouldOpenCommentTool = false;
        editor.selectNone();
        this.parent.transition("dragging", info);
      }
    } else {
      // this follows similar logic to tldraw documentation here:
      // https://github.com/tldraw/tldraw/blob/7c759019ef95c36dd2e7526b8d27d47cdce0e1b6/packages/tldraw/src/lib/tools/selection-logic/updateHoveredId.ts#L3

      if (
        !hitShape ||
        (hitShape.type != "comment" && hitShape.type != "comment-avatar")
      ) {
        return editor.setCursor({ type: "grab", rotation: 0 });
      }

      editor.setCursor({ type: "pointer", rotation: 0 });
      // this.parent.transition("dragging_handle", { ...info, shape: hitShape });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override onPointerUp?: TLPointerEvent = (info) => {
    const { editor } = this;
    const { currentPagePoint, originScreenPoint } = editor.inputs;

    let x = currentPagePoint.x;
    let y = currentPagePoint.y;
    const viewport = editor.getViewportScreenBounds();
    const zoom = editor.getZoomLevel();

    const originalCoords: { x?: number; y?: number } | undefined = {
      x: currentPagePoint.x,
      y: currentPagePoint.y,
    };
    // 600 because the comment tool is 300px wide and we double it to take the sidebar into account
    if (originScreenPoint.x + 600 > viewport.width) {
      // we have to account for the zoom level which effects the size of the comment box
      x = x - 300 / zoom;
    }

    // we only use origin screen point because of the y offset of the canvas
    // it essentially gives us a lee way of 248px from the bottom of the screen
    // which allows for the height of the comment box plus some extra space
    if (originScreenPoint.y > viewport.height - viewport.y) {
      // we have to account for the zoom level which effects the size of the comment box
      y = y - 193 / zoom;
    }

    if (this.shouldOpenCommentTool) {
      createAndSelectCommentShape({
        editor,
        x: x,
        y: y,
        originalCoords,
        zoom,
      });
      this.shouldOpenCommentTool = false;
    }
    this.parent.transition("idle", { shape: null });
  };
}

// this if from tldraw v2.0.1, inside of their hand tool
// https://github.com/tldraw/tldraw/blob/7c759019ef95c36dd2e7526b8d27d47cdce0e1b6/packages/tldraw/src/lib/tools/HandTool/childStates/Dragging.ts
class Dragging extends StateNode {
  static override id = "dragging";
  initialCamera = new Vec();

  override onEnter = () => {
    this.initialCamera = Vec.From(this.editor.getCamera());
    this.update();
  };

  override onPointerMove: TLEventHandlers["onPointerMove"] = () => {
    this.update();
  };

  override onPointerUp: TLEventHandlers["onPointerUp"] = () => {
    this.complete();
  };

  override onCancel: TLEventHandlers["onCancel"] = () => {
    this.complete();
  };

  override onComplete = () => {
    this.complete();
  };

  private update() {
    const { initialCamera, editor } = this;
    const { currentScreenPoint, originScreenPoint } = this.editor.inputs;

    const delta = Vec.Sub(currentScreenPoint, originScreenPoint).div(
      editor.getZoomLevel(),
    );
    if (delta.len2() === 0) return;
    editor.setCamera(initialCamera.clone().add(delta));
  }

  private complete() {
    const { editor } = this;
    const { pointerVelocity } = editor.inputs;

    const velocityAtPointerUp = Math.min(pointerVelocity.len(), 2);

    if (velocityAtPointerUp > 0.1) {
      this.editor.slideCamera({
        speed: velocityAtPointerUp,
        direction: pointerVelocity,
      });
    }

    this.parent.transition("idle");
  }
}

class MovingComment extends StateNode {
  static override id = "moving";
  shape: TLShape | null = null;
  offset = { x: 0, y: 0 };
  override onEnter = (shape: TLShape) => {
    this.shape = shape;
    const { currentPagePoint } = this.editor.inputs;
    this.offset = {
      x: currentPagePoint.x - shape.x,
      y: currentPagePoint.y - shape.y,
    };

    this.update(shape);
  };

  override onPointerMove: TLEventHandlers["onPointerMove"] = () => {
    this.update(this.shape!);
  };

  override onPointerUp: TLEventHandlers["onPointerUp"] = () => {
    this.complete();
  };

  override onCancel: TLEventHandlers["onCancel"] = () => {
    this.complete();
  };

  override onComplete = () => {
    this.complete();
  };

  private update(shape: TLShape) {
    const { currentPagePoint } = this.editor.inputs;

    const newShape = {
      ...shape,
      x: currentPagePoint.x - this.offset.x,
      y: currentPagePoint.y - this.offset.y,
    };
    this.shape = newShape;
    this.editor.updateShape(newShape);
  }

  private complete() {
    this.parent.transition("idle");
  }
}
