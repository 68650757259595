import { TicketsAuth } from "@mg/schemas/src/auth";
import { GetTicketResponse } from "@mg/schemas/src/christo/catalyst";
import {
  type CreativeBriefConfig,
  type DeliveryConfig,
  type DesignReviewConfig,
  EnterpriseProfileType,
  type Ticket,
  TicketWorkflowStep,
  UserPermission,
  type WorkflowUserTypes,
  type User,
} from "@mg/schemas/src/commons";
import { redirect } from "@tanstack/react-router";

import { store } from "./store";

import { posthogClient } from "../config/posthog";
import { loginRoute } from "../routes/login/route";

export function requiresAuth() {
  const { value: user } = store.getState().auth;

  if (user == null) {
    throw redirect({
      to: loginRoute.to,
    });
  }
}

export function isPunttGuest() {
  const { value: user } = store.getState().auth;

  return (
    user != null && user.role == null && !!(user.ticketId || user.folderId)
  );
}

export function isUserCreative() {
  const { value } = store.getState().auth;

  return (
    !value?.permissions?.includes(UserPermission.CONNECT_TEAM) &&
    !value?.permissions?.includes(UserPermission.ENTERPRISE_ADMIN) &&
    value?.type !== "client"
  );
}

export function canAssignCreatives(ticket: Ticket) {
  const { value } = store.getState().auth;

  // always true for role mg and admin
  if (
    value?.role === EnterpriseProfileType.MEANINGFUL_GIGS ||
    value?.role === EnterpriseProfileType.ADMIN
  ) {
    return true;
  }

  const sendToDesign = (
    ticket.workflow?.steps.find(
      (step) => step.type === TicketWorkflowStep.BRIEF,
    )?.config as CreativeBriefConfig
  )?.sendToDesign;

  if (!sendToDesign) {
    return true;
  }

  if (
    sendToDesign.canAssignCreatives.userIds.includes(value?.userID as string)
  ) {
    return true;
  }

  if (
    sendToDesign.canAssignCreatives.roles.includes(
      value?.role as unknown as WorkflowUserTypes,
    )
  ) {
    return true;
  }

  return false;
}

export function canAssignReviewers(ticket: Ticket) {
  const { value } = store.getState().auth;

  // always true for role mg and admin
  if (
    value?.role === EnterpriseProfileType.MEANINGFUL_GIGS ||
    value?.role === EnterpriseProfileType.ADMIN
  ) {
    return true;
  }
  const reviewConfig = (
    ticket.workflow?.steps.find(
      (step) => step.type === TicketWorkflowStep.DESIGN_REVIEW,
    )?.config as DesignReviewConfig
  )?.requiresApproval?.canAssignReviewers;

  if (!reviewConfig) {
    return true;
  }

  if (
    reviewConfig.userIds.includes(value?.userID as string) ||
    reviewConfig.roles.includes(value?.role as unknown as WorkflowUserTypes)
  ) {
    return true;
  }

  return false;
}

export function canSetTicketStatus(ticket: GetTicketResponse) {
  const { value } = store.getState().auth;

  // always true for role mg and admin
  if (
    value?.role === EnterpriseProfileType.MEANINGFUL_GIGS ||
    value?.role === EnterpriseProfileType.ADMIN
  ) {
    return true;
  }

  const config = ticket.workflow?.steps.find(
    (step) => step.type === TicketWorkflowStep.DELIVERY,
  )?.config as DeliveryConfig;

  if (!config) {
    return true;
  }

  if (
    config.canSetTicketStatus.userIds.includes(value?.userID as string) ||
    config.canSetTicketStatus.roles.includes(
      value?.role as unknown as WorkflowUserTypes,
    )
  ) {
    return true;
  }

  return false;
}

export function canMarketingAccess(ticket: GetTicketResponse) {
  const { value } = store.getState().auth;

  const config = ticket.workflow?.steps.find(
    (step) => step.type === TicketWorkflowStep.DESIGN_REVIEW,
  )?.config as DesignReviewConfig;

  if (!config) {
    return true;
  }

  if (
    config.canMarketingAccess ||
    value?.role !== EnterpriseProfileType.CATALYST_MARKETING
  ) {
    return true;
  }

  return false;
}

export function canApproveTicket(ticket: Ticket) {
  const { value } = store.getState().auth;

  if (value == null) {
    return false;
  }

  return TicketsAuth.canApproveTicket(
    ticket,
    value?.userID as string,
    value?.role as unknown as WorkflowUserTypes,
  );
}

export function canBeAssignedDesignReviewStep(ticket: Ticket) {
  const { value } = store.getState().auth;

  // current creatives should be able to submit revisions, independent of any config
  if (
    ticket.creatives?.find(
      (creative) => (creative as User)._id === value?.userID,
    )
  ) {
    return true;
  }

  // always true for role mg and admin
  if (
    value?.role === EnterpriseProfileType.MEANINGFUL_GIGS ||
    value?.role === EnterpriseProfileType.ADMIN
  ) {
    return true;
  }

  const config = ticket.workflow?.steps.find(
    (step) => step.type === TicketWorkflowStep.DESIGN_REVIEW,
  )?.config as DesignReviewConfig;

  if (!config) {
    return true;
  }

  return (
    config.requiresApproval?.canBeAssigned?.userIds?.includes(
      value?.userID as string,
    ) ||
    config.requiresApproval?.canBeAssigned?.roles?.includes(
      value?.role as unknown as WorkflowUserTypes,
    )
  );
}

export function canAccessPuntt() {
  return userCanAccess("puntt-access");
}

export function canAccessCreativeConnect() {
  return userCanAccess("creative-connect-access");
}

export function canAccessEditOwnProfile() {
  return userCanAccess("edit-own-profile");
}

export function canAccessLists() {
  return userCanAccess("lists-access");
}

export function canAccessMessages() {
  return userCanAccess("messages");
}

export function canDismissComments() {
  return userCanAccess("dismiss-comments");
}

export function canResolveComments() {
  return userCanAccess("resolve-comments");
}

export function canDeleteComments() {
  return userCanAccess("delete-comments");
}

export function canThumbsDownAiComments() {
  return userCanAccess("ai-thumbs-down");
}

export function canCreateRevisions() {
  return userCanAccess("create-revisions");
}

export function canShowDismissedComments() {
  return userCanAccess("show-dismissed-comments");
}

export function canManageBrandGuidelines() {
  return userCanAccess("manage-brand-guidelines");
}

export function canManageUsers() {
  return userCanAccess("manage-users");
}

export function canDoRestrictedCommentEdit() {
  return userCanAccess("restricted-comment-edit");
}

export function canAccessKnowledgeDatabase() {
  return (
    flagIsValue("knowledge-database", "enabled") ||
    flagIsValue("knowledge-database", "mutate-records") ||
    flagIsValue("knowledge-database", "mutate-models")
  );
}

export function canMutateKnowledgeDatabaseRecords() {
  return (
    flagIsValue("knowledge-database", "mutate-records") ||
    flagIsValue("knowledge-database", "mutate-models")
  );
}

export function canMutateKnowledgeDatabaseModels() {
  return flagIsValue("knowledge-database", "mutate-models");
}

export function canChooseCommentPersonas() {
  return userCanAccess("comment-personas");
}

export function canAccessPunttDashboard() {
  return userCanAccess("puntt-dashboard");
}

export function canAddEnterprise() {
  const { value: user } = store.getState().auth;

  // See services/christo/app/handlers/catalyst/enterprises.ts@createEnterprise
  return user?.role === EnterpriseProfileType.MEANINGFUL_GIGS;
}

function userCanAccess(flag: string) {
  return userExists() && flagIsEnabled(flag);
}

function userExists() {
  const user = store.getState().auth.value;

  return user != null;
}

function flagIsEnabled(flag: string) {
  const access = posthogClient.getFeatureFlag(flag);

  return access === "enabled";
}

function flagIsValue(flag: string, variantKey: string): boolean {
  const flagValue = posthogClient.getFeatureFlag(flag);

  return flagValue === variantKey;
}
