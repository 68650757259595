import { Disclosure } from "@headlessui/react";
import {
  type GetRevisionsResponse,
  type GetTicketResponse,
  type GetTicketCommentsResponse,
} from "@mg/schemas/src/christo/catalyst";
import {
  TicketCommentBoard,
  TicketCommentDisposition,
} from "@mg/schemas/src/commons";
import { type ShowcaseToken } from "@mg/schemas/src/prince/auth";
import { CaretDown, CaretLeft, MagicWand, Robot } from "@phosphor-icons/react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Link,
  Popover,
  Skeleton,
  Text,
} from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useRouter, Link as NavLink } from "@tanstack/react-router";
import {
  createShapeId,
  type Editor,
  type TLShapeId,
  type TLShape,
  type UnknownRecord,
  type TLImageShape,
  type TLGroupShape,
} from "@tldraw/tldraw";
import cx from "classnames";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";

import { Comment } from "./Comment";
import { StickyHeader } from "./StickyHeader";

import { AuthTooltip } from "../../../../../components/AuthTooltip";
import { AvatarWithInitials } from "../../../../../components/AvatarWithInitials";
import { SidebarGroup } from "../../../../../components/sidebar/SidebarGroup";
import { SidebarMenu } from "../../../../../components/sidebar/SidebarMenu";
import { SidebarMenuButton } from "../../../../../components/sidebar/SidebarMenuButton";
import { SidebarMenuItem } from "../../../../../components/sidebar/SidebarMenuItem";
import { useSidebar } from "../../../../../components/sidebar/useSidebar";
import { useUI } from "../../../../../contexts/ui";
import { ReactComponent as BrandRobot } from "../../../../../icons/BrandRobot.svg";
import { ReactComponent as CopyRobot } from "../../../../../icons/CopyRobot.svg";
import { ReactComponent as LegalRobot } from "../../../../../icons/LegalRobot.svg";
import leapfrogLoader from "../../../../../images/leapfrog-gray.gif";
import { getGuidelines } from "../../../../../services/brand-guidelines";
import { requestAIComment } from "../../../../../services/projects";
import {
  errorAnalyticsPayload,
  useAnalytics,
} from "../../../../../utils/analytics";
import { canChooseCommentPersonas } from "../../../../../utils/auth";
import { getNameInitials } from "../../../../../utils/constants";
import { isNil } from "../../../../../utils/fp";
import {
  useAppDispatch,
  useAppSelector,
  waitForState,
} from "../../../../../utils/hooks";
import { errorMessage } from "../../../../../utils/http";
import { assetForUser } from "../../../../../utils/imageHandler";
import { useReviewRevisionMutation } from "../../../../../utils/queries/projects";
import {
  setActiveCommentId,
  setSelectedVersionIndex,
} from "../../../../../utils/slices/ticket";
import { store } from "../../../../../utils/store";
import { useGetVisibleUsers } from "../../../../../utils/tldraw/comments";
import { knowledgeDatabaseRoute } from "../../../../settings/routes/knowledge-database/route";
import { knowledgeSettingRoute } from "../../../../settings/routes/knowledgeSettingRoute";
import { useTicket } from "../../ticket/view";

const collapsibleTriggerClasses =
  "z-[1] top-0 sticky p-4 border-b border-b-puntt-neutral-gray-9 bg-base-white w-full flex items-center justify-between";
const sectionClasses = "max-h-full overflow-y-auto";

type CommentsProps = {
  editor?: Editor | null;
  videoRef: React.RefObject<{
    handleCommentClicked(seekTime: number, commentId?: string): void;
    duration: number;
  }>;
};

function analyticsPayload(
  renderTime: number,
  ticket: GetTicketResponse,
  comments: GetTicketCommentsResponse,
  currentRevision: GetRevisionsResponse[number],
  user: ShowcaseToken | null,
  selectedPersonas: string[],
) {
  const newComments = comments.filter(
    (c) => new Date(c.createdAt).getTime() > renderTime,
  );
  return {
    numComments: comments.length,
    numNewComments: newComments.length,
    numNewAIComments: newComments.filter((c) => c.isAI).length,
    numActiveCommentsOnCurrentRevision: comments.filter(
      (c) =>
        c.boardId === currentRevision?._id &&
        c.disposition === TicketCommentDisposition.DEFAULT,
    ).length,
    numRevisions: ticket.revisionBoards?.length,
    revisionName: currentRevision.name,
    revisionId: currentRevision._id,
    revisionNumber: currentRevision.revision,
    revisionFileTypes: currentRevision.reviewFiles?.map((f) =>
      f.source.slice(-3),
    ),
    minutesSinceRevisionCreated:
      (Date.now() - new Date(currentRevision.createdAt).getTime()) /
      (1000 * 60),
    revisionCreatedByCurrentUser:
      (typeof currentRevision.createdBy === "string"
        ? currentRevision.createdBy
        : currentRevision.createdBy._id) === user?.userID &&
      Boolean(user?.userID),
    isRevisionAIReviewed: currentRevision.isAIReviewed,
    minutesSinceRender: (Date.now() - renderTime) / (1000 * 60),
    numSelectedPersonas: selectedPersonas.length,
    ...selectedPersonas.reduce(
      (acc, persona) => {
        acc[`${persona}PersonaEnabled`] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    ),
  };
}

function getStorageKey(enterpriseId: string) {
  return `ai-personas-${enterpriseId}`;
}

function getStoredPersonas(enterpriseId: string) {
  try {
    return JSON.parse(
      localStorage.getItem(getStorageKey(enterpriseId)) || "{}",
    ) as Record<"brand" | "legal", boolean>;
  } catch {
    return {};
  }
}

/**
 * This can only appear for design reviews. Creative Briefs
 * cannot get AI reviews on them.
 */
export function Comments(props: CommentsProps) {
  const { editor } = props;

  const router = useRouter();
  const query = useQuery({
    queryKey: ["brand-guidelines"],
    queryFn: getGuidelines,
  });

  const { open } = useSidebar();

  const {
    value: ticket,
    revisions,
    comments,
    activeCommentId,
  } = useAppSelector((state) => state.ticket);
  const user = useAppSelector((state) => state.auth.value);
  const { showResolvedComments, showDismissedComments } = useAppSelector(
    (state) => state.ui,
  );
  const dispatch = useAppDispatch();
  const { notify } = useUI();
  const users = useGetVisibleUsers();

  const aiReviewerAccessKey = useFeatureFlagVariantKey("run-ai-review");

  // we navigate to the revision and pan to the comment
  // shape when clicking a comment within the sidebar
  const activeRevisionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );

  const enterpriseId = useAppSelector(
    (state) => state.auth.value?.enterpriseId,
  );
  const [personaSettings, setPersonaSettings] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (enterpriseId) {
      setPersonaSettings(getStoredPersonas(enterpriseId));
    }
  }, [enterpriseId]);

  async function setActiveRevisionIndex(index: number) {
    const newIndex = revisions.length - 1 - index;
    dispatch(setSelectedVersionIndex(newIndex));
    await waitForState(
      store,
      (state) => state.ticket.reversedVersionIndex === newIndex,
    );
  }

  const { hasInitiatedAiReview, setHasInitiatedAiReview } = useTicket();
  const reviewRevisionMutation = useReviewRevisionMutation();
  const currentRevision = revisions[activeRevisionIndex!];

  const [aiReviewRunning, setAiReviewRunning] = useState(false);
  const [aiRunningCurrentStep, setAiRunningCurrentStep] = useState(0);
  const [aiRunningProgressBar, setAiRunningProgressBar] = useState(0);
  const [finalizingAIReview, setFinalizingAIReview] = useState(false);

  const posthog = useAnalytics("Comments");
  const renderTimeRef = useRef(0);
  const suggestedImprovementsRef = useRef<HTMLButtonElement>(null);
  const requiredImprovementsRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    renderTimeRef.current = Date.now();
  }, [ticket?._id]);

  const pendingCoordsRef = useRef<{
    x: number;
    y: number;
  } | null>(null);

  useEffect(() => {
    const pendingCoords = pendingCoordsRef.current;

    if (pendingCoords != null) {
      editor?.centerOnPoint(pendingCoords);
      pendingCoordsRef.current = null; // reset the pendingCoords in ref
      const shape = editor?.getShapeAtPoint(pendingCoords);

      if (shape) {
        editor?.setSelectedShapes([shape.id]);
        editor?.select(shape);
      }
    }
  }, [editor, activeRevisionIndex]);

  // Support sorting Design Review comments by physical location
  const [sortedComments, setSortedComments] = useState(comments.slice());
  useEffect(() => {
    // Get comments we may show in the sidebar
    const revisionCommentsByBoardId: Record<string, GetTicketCommentsResponse> =
      comments.reduce((map, comment) => {
        if (
          comment.boardType === TicketCommentBoard.REVISION &&
          comment.boardId
        ) {
          if (!map[comment.boardId]) map[comment.boardId] = [];
          map[comment.boardId].push(comment);
        }
        return map;
      }, Object.create(null));

    // Get shapes that can be commented upon
    const imageShapesByBoardId: Record<string, TLImageShape[]> =
      Object.create(null);
    let groupShapesById: Record<string, TLGroupShape> = Object.create(null);
    for (const revision of revisions) {
      const shapes: (UnknownRecord & Partial<TLShape>)[] = Object.values(
        revision.shapes?.store ?? {},
      );
      imageShapesByBoardId[revision._id] = shapes.filter(
        (shape): shape is TLImageShape => {
          return shape.typeName === "shape" && shape.type === "image";
        },
      );
      groupShapesById = shapes.reduce((map, shape) => {
        if (shape.typeName === "shape" && shape.type === "group") {
          map[shape.id] = shape as TLGroupShape;
        }
        return map;
      }, groupShapesById);
    }

    // Shape X/Y coords are relative to their parent, so we have to add the parent coords.
    // We should only ever have one level of parents.
    function getShapeCoord(shape: TLShape, coord: "x" | "y"): number {
      const parent =
        shape.parentId && groupShapesById[shape.parentId]
          ? groupShapesById[shape.parentId][coord]
          : 0;
      return parent + shape[coord];
    }

    // Filter to commented shapes
    const commentedShapesByBoardId: Record<string, TLImageShape[]> =
      Object.create(null);
    const commentsToShapes = new Map<
      GetTicketCommentsResponse[number],
      TLImageShape[]
    >();
    for (const [boardId, imageShapes] of Object.entries(imageShapesByBoardId)) {
      const revisionComments = revisionCommentsByBoardId[boardId];
      if (!revisionComments) continue;

      if (!commentedShapesByBoardId[boardId]) {
        commentedShapesByBoardId[boardId] = [];
      }

      for (const comment of revisionComments) {
        if (comment.x === undefined || comment.y === undefined) continue;
        for (const imageShape of imageShapes) {
          // Check if the comment is inside an imageShape.
          // This could be done more efficiently by e.g. using a quadtree or
          // by calculating which shape comments should be attached to when
          // they're saved or moved. However, on a 2021 M1 Pro, the whole
          // sorting operation with 10 comments and 69 shapes took about 1ms,
          // so it's not worth the effort to make it faster.
          if (
            comment.x >= getShapeCoord(imageShape, "x") &&
            comment.x <= getShapeCoord(imageShape, "x") + imageShape.props.w &&
            comment.y >= getShapeCoord(imageShape, "y") &&
            comment.y <= getShapeCoord(imageShape, "y") + imageShape.props.h
          ) {
            commentedShapesByBoardId[boardId].push(imageShape);

            const commentShapes = commentsToShapes.get(comment);
            if (!commentShapes) {
              commentsToShapes.set(comment, [imageShape]);
            } else {
              commentShapes.push(imageShape);
            }
          }
        }
      }
    }

    // Sort commented shapes top to bottom, then left to right
    const shapeToIndex = new Map<TLImageShape, number>();
    for (const commentedShapes of Object.values(commentedShapesByBoardId)) {
      commentedShapes.sort((a, b) => {
        return (
          getShapeCoord(a, "y") - getShapeCoord(b, "y") ||
          getShapeCoord(a, "x") - getShapeCoord(b, "x")
        );
      });
      for (let i = 0; i < commentedShapes.length; i++) {
        shapeToIndex.set(commentedShapes[i], i);
      }
    }

    // Associate each comment with the shape whose index we can use for sorting
    // Array.from() is due to TypeScript error:
    // Map can only be iterated through when using the '--downlevelIteration' flag or with a '--target' of 'es2015' or higher. ts(2802)
    const commentToShape = new Map<
      GetTicketCommentsResponse[number],
      TLImageShape
    >();
    for (const [comment, shapes] of Array.from(commentsToShapes)) {
      const commentShape = shapes.reduce(
        (smallest, shape) => {
          if (!smallest) return shape;
          return smallest.props.w * smallest.props.h <
            shape.props.w * shape.props.h
            ? smallest
            : shape;
        },
        null as TLImageShape | null,
      ) as TLImageShape;
      commentToShape.set(comment, commentShape);
    }

    // Sort comments by timestamp, then by shape index, then by coordinates
    const newSortedComments = comments.toSorted((a, b) => {
      const aShape = commentToShape.get(a) as TLImageShape;
      const bShape = commentToShape.get(b) as TLImageShape;
      const aShapeIndex = shapeToIndex.get(aShape) ?? comments.length;
      const bShapeIndex = shapeToIndex.get(bShape) ?? comments.length;
      const xDiff = a.x !== undefined && b.x !== undefined ? a.x - b.x : 0;
      const yDiff = a.y !== undefined && b.y !== undefined ? a.y - b.y : 0;

      // If the comments are on the same shape, then if the y-coordinates are
      // close, scan left to right, otherwise top to bottom.
      // aShape === bShape when this is used. 3% is somewhat arbitrary.
      const maxComparableHeight = aShape ? aShape.props.h * 0.03 : 0;

      return (
        (a.videoStart || 0) - (b.videoStart || 0) ||
        aShapeIndex - bShapeIndex ||
        (Math.abs(yDiff) <= maxComparableHeight ? xDiff || yDiff : yDiff) ||
        (a.x === undefined && a.y === undefined ? 1 : 0) -
          (b.x === undefined && b.y === undefined ? 1 : 0) ||
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    });

    setSortedComments(newSortedComments);
  }, [revisions, comments, editor]);

  useEffect(() => {
    if (hasInitiatedAiReview && currentRevision?.isAIReady) {
      runAIReview();
    }
  }, [currentRevision?.isAIReady, hasInitiatedAiReview]);

  const randomAiStepDurations = useRef({
    queue: Math.random() * 6000,
    assets: Math.random() * 3000,
    guidelines: Math.random() * 3000,
    comments: Math.random() * 5000,
    review: Math.random() * 2000,
    finalize: Math.random() * 2000,
  });

  const aiRunningSteps = useMemo(
    () => [
      {
        label: "Queued",
        active: hasInitiatedAiReview && !aiReviewRunning,
        duration: 9000 + randomAiStepDurations.current.queue,
        maxProgress: 90,
      },
      {
        label: "Retrieving assets",
        active: aiReviewRunning && !finalizingAIReview,
        duration: 3000 + randomAiStepDurations.current.assets,
      },
      {
        label: "Processing guidelines",
        active: aiReviewRunning && !finalizingAIReview,
        duration: 3000 + randomAiStepDurations.current.guidelines,
      },
      {
        label: "Generating comments",
        active: aiReviewRunning && !finalizingAIReview,
        duration:
          Math.max(
            45_000,
            Math.min(
              20_000,
              10_000 +
                (props.videoRef?.current?.duration
                  ? props.videoRef?.current?.duration / 3
                  : (currentRevision?.reviewFiles?.length ?? 1)) *
                  1000,
            ),
          ) + randomAiStepDurations.current.comments,
      },
      {
        label: "Reviewing comments",
        active: aiReviewRunning && !finalizingAIReview,
        duration: 8000 + randomAiStepDurations.current.review,
        maxProgress: 90,
      },
      {
        label: "Finalizing",
        active: finalizingAIReview,
        duration: 8000 + randomAiStepDurations.current.finalize,
        maxProgress: 90,
      },
    ],
    [
      hasInitiatedAiReview,
      aiReviewRunning,
      finalizingAIReview,
      props.videoRef?.current?.duration,
      currentRevision?.reviewFiles?.length,
    ],
  );

  useEffect(() => {
    if (
      !hasInitiatedAiReview &&
      !aiReviewRunning &&
      aiRunningCurrentStep === 0
    ) {
      return;
    }
    if (!aiRunningSteps[aiRunningCurrentStep]?.active) {
      let interval: number;
      if (aiRunningCurrentStep < aiRunningSteps.length - 1) {
        interval = setTimeout(() => {
          setAiRunningCurrentStep(aiRunningCurrentStep + 1);
        }, 500);
      }
      if (aiRunningCurrentStep === aiRunningSteps.length - 1) {
        setAiRunningProgressBar(0);
      }
      return () => clearInterval(interval);
    }

    const step = aiRunningSteps[aiRunningCurrentStep];
    const startTime = performance.now();

    let interval = setInterval(() => {
      const elapsed = performance.now() - startTime;
      const newProgress = (elapsed / step.duration) * 100;

      if (newProgress <= (step.maxProgress || 100)) {
        setAiRunningProgressBar(newProgress);
      }

      if (
        elapsed >= step.duration &&
        aiRunningCurrentStep < aiRunningSteps.length - 1 &&
        !step.maxProgress
      ) {
        clearInterval(interval);
        interval = setTimeout(() => {
          setAiRunningCurrentStep(aiRunningCurrentStep + 1);
          setAiRunningProgressBar(0);
        }, 500); // Short delay before starting the next step
      }
    }, 100);

    return () => clearInterval(interval);
  }, [
    aiRunningCurrentStep,
    aiRunningSteps,
    hasInitiatedAiReview,
    aiReviewRunning,
  ]);

  function renderAIReviewRunning() {
    if (!hasInitiatedAiReview && !aiReviewRunning) return null;

    return (
      <Flex direction="column" gap="2" p="4">
        {aiRunningSteps.map((step, index) => (
          <Flex
            direction="row"
            justify="between"
            align="center"
            gap="5"
            key={step.label}
            className={cx("transition-opacity duration-100", {
              "opacity-30": index > aiRunningCurrentStep,
              "opacity-100": index <= aiRunningCurrentStep,
            })}
          >
            <Text
              size="2"
              className={cx({
                "opacity-80": index < aiRunningCurrentStep,
              })}
            >
              {step.label}
            </Text>
            <div className="h-2 w-16 overflow-hidden rounded bg-puntt-neutral-gray-6">
              <div
                className="h-full bg-puntt-accent-9 transition-all duration-100"
                style={{
                  width: `${index < aiRunningCurrentStep ? 100 : index === aiRunningCurrentStep ? aiRunningProgressBar : 0}%`,
                }}
              />
            </div>
          </Flex>
        ))}
      </Flex>
    );
  }

  // Open the Required/Suggested drawer when the first comment of that type gets added
  useEffect(() => {
    if (!revisions[activeRevisionIndex!]) return;

    const currentRevisionId = revisions[activeRevisionIndex!]._id;
    const currentRevisionComments = comments.filter(
      (c) => c.boardId === currentRevisionId,
    );
    const hasRequiredChanges = currentRevisionComments.some(
      (comment) => comment.isRequired === true,
    );
    const hasSuggestedImprovements = currentRevisionComments.some(
      (comment) => comment.isRequired === false,
    );

    const isDisclosureOpen = (el: React.RefObject<HTMLButtonElement>) =>
      el.current?.getAttribute("data-headlessui-state") === "open";

    if (hasRequiredChanges && !isDisclosureOpen(requiredImprovementsRef)) {
      requiredImprovementsRef.current?.click();
    }
    if (
      hasSuggestedImprovements &&
      !isDisclosureOpen(suggestedImprovementsRef)
    ) {
      suggestedImprovementsRef.current?.click();
    }
  }, [
    revisions[activeRevisionIndex!],
    comments,
    requiredImprovementsRef,
    suggestedImprovementsRef,
  ]);

  const handleCommentReplySuccess = useCallback(
    (comment: (typeof comments)[number], context: string) => {
      return (data: typeof comment, eventName: string) => {
        updateShapeWithResponse(comment._id, data);
        posthog.capture("changed_comment", {
          isAI: data.isAI,
          comment: data,
          eventName,
          context,
        });
      };
    },
    [],
  );

  const handleCommentReplyError = useCallback(
    (comment: (typeof comments)[number], context: string) => {
      return (
        error: Error | Response,
        data: typeof comment,
        operation: string,
        payload: Record<
          string,
          string | number | boolean | string[] | undefined
        >,
      ) => {
        updateShapeWithResponse(comment._id, data);
        notify({
          title: "Error",
          message: `An error occured while ${operation}: ${errorMessage(error)}`,
          variant: "error",
        });
        posthog.capture("comment_reply_error", {
          ...errorAnalyticsPayload(error),
          operation,
          threadId: comment._id,
          numExistingReplies: comment.messages.length,
          context,
          ...Object.fromEntries(
            Object.entries(payload).map(([key, value]) => [
              "payload_" + (Array.isArray(value) ? "num_" : "") + key,
              Array.isArray(value) ? value.length : value,
            ]),
          ),
        });
      };
    },
    [],
  );

  function getSelectedPersonas() {
    // Checked boxes / defaults from localStorage where the persona is turned on in settings
    const lastUsedPersonas = Object.entries(personaSettings)
      .filter(
        ([key, selected]) =>
          selected &&
          query.data?.aiPersonas.find((p) => p.name.toLowerCase() === key)
            ?.enabled,
      )
      .map(([key]) => key);
    // API defaults for personas that are not in personaSettings yet
    const apiPersonas =
      query.data?.aiPersonas
        .filter((p) => {
          return (
            p.enabled &&
            p.default &&
            !Object.hasOwn(personaSettings, p.name.toLowerCase())
          );
        })
        .map((p) => p.name.toLowerCase()) ?? [];

    return lastUsedPersonas.concat(apiPersonas);
  }

  const handlePersonaChange = async (
    personaId: "brand" | "legal" | "copy",
    value: boolean,
  ) => {
    if (!enterpriseId) return;

    const newSettings = {
      ...personaSettings,
      [personaId]: value,
    };
    setPersonaSettings(newSettings);
    localStorage.setItem(
      getStorageKey(enterpriseId),
      JSON.stringify(newSettings),
    );
  };

  // You must be new here :] don't add any hooks below this
  // line as it may give you a React error #302. This
  // component makes use of a lot of conditionals, so be
  // warned.

  if (ticket == null) {
    return null;
  }

  let allCommentsFromBoardType = sortedComments.filter((c) => {
    if (
      user?.role != "ai" &&
      user?.role != "meaningful-gigs" &&
      c.disposition == TicketCommentDisposition.DISMISSED
    ) {
      return false;
    }
    return c.boardType === TicketCommentBoard.REVISION;
  });

  if (!showDismissedComments) {
    allCommentsFromBoardType = allCommentsFromBoardType.filter(
      (c) => c.disposition !== TicketCommentDisposition.DISMISSED,
    );
  }

  if (!showResolvedComments) {
    allCommentsFromBoardType = allCommentsFromBoardType.filter(
      (c) => c.disposition !== TicketCommentDisposition.RESOLVED,
    );
  }

  const runAIReview = async () => {
    if (currentRevision?.errorDetails?.reason) {
      return notify({
        title: "Your asset was not processed",
        message: `There was an error processing your asset: ${currentRevision.errorDetails.reason}`,
        timeout: 8000,
        variant: "error",
      });
    }
    if (!hasInitiatedAiReview) {
      setAiRunningCurrentStep(0);
      setAiRunningProgressBar(0);
    }
    if (!currentRevision?.isAIReady) {
      return setHasInitiatedAiReview(true);
    }

    setAiReviewRunning(true);
    const shapeId = createShapeId();
    const startTime = performance.now();

    try {
      const startRequestAICommentTime = performance.now();
      posthog.capture(
        "ai_reviewer_start",
        analyticsPayload(
          renderTimeRef.current,
          ticket,
          comments,
          currentRevision,
          user,
          getSelectedPersonas(),
        ),
      );

      let { allComments: newComments } = await requestAIComment({
        ticket_id: ticket?._id as string,
        board_id: currentRevision._id as string,
        personas: getSelectedPersonas(),
      });

      setFinalizingAIReview(true);
      const startRefreshTicketTime = performance.now();
      const startRefreshCommentsTime = performance.now();

      // get most recent comment
      newComments.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });

      // then filter only the ones with this same latest createdAt date
      newComments = newComments.filter(
        (c) => c.reviewId === newComments[0].reviewId,
      );

      newComments.forEach((comment) => {
        if (editor == null) {
          return;
        }
        if (comment.x != undefined && comment.y != undefined) {
          const internalShapeId = createShapeId();
          const avatarShapeId = createShapeId();
          // we need to check if the comment is from AI because AI comments are not taking into account the doc mode offset
          editor.createShapes([
            {
              id: avatarShapeId,
              type: "comment-avatar",
              x: comment?.x,
              y: comment?.y,
              props: {
                h: 25,
                w: 25,
              },
              meta: {
                comment: JSON.stringify(comment),
                linkedCommentId: internalShapeId,
              },
            },
            {
              id: internalShapeId,
              type: "comment",
              x: comment?.x,
              y: (comment?.y ?? 0) + 25 / editor.getZoomLevel(),
              meta: {
                comment: JSON.stringify(comment),
                linkedAvatarId: avatarShapeId,
              },
              props: {
                h: 0,
                w: 0,
                commentId: comment._id,
              },
            },
          ]);
        }
      });

      posthog.capture("ai_reviewer", {
        ...analyticsPayload(
          renderTimeRef.current,
          ticket,
          newComments,
          currentRevision,
          user,
          getSelectedPersonas(),
        ),
        totalDurationSeconds: (performance.now() - startTime) / 1000,
        requestAICommentDurationSeconds:
          (startRefreshTicketTime - startRequestAICommentTime) / 1000,
        refreshTicketDurationSeconds:
          (startRefreshCommentsTime - startRefreshTicketTime) / 1000,
        refreshCommentsDurationSeconds:
          (performance.now() - startRefreshCommentsTime) / 1000,
      });
    } catch (err) {
      console.error(err);
      notify({
        title: "An error occurred while processing the AI Reviewer",
        message: JSON.stringify(err),
        timeout: 8000,
        variant: "error",
      });

      posthog.capture("ai_reviewer_error", {
        ...analyticsPayload(
          renderTimeRef.current,
          ticket,
          comments,
          currentRevision,
          user,
          getSelectedPersonas(),
        ),
        ...errorAnalyticsPayload(err),
        totalDurationSeconds: (performance.now() - startTime) / 1000,
      });
    } finally {
      router.invalidate();
    }
    editor?.deleteShapes([shapeId]);
    setAiReviewRunning(false);
    setFinalizingAIReview(false);

    if (hasInitiatedAiReview) {
      setHasInitiatedAiReview(false);
    }
  };

  if (allCommentsFromBoardType.length === 0) {
    if (aiReviewerAccessKey === "first" || aiReviewerAccessKey === "always") {
      return (
        <section className="grid w-auto place-content-center justify-items-center gap-y-4 text-wrap p-4">
          <Skeleton loading={ticket?.isPending}>
            <Text
              size="3"
              weight="regular"
              align="center"
              className={cx({ hidden: !open })}
            >
              No comments yet. Add your own or run an AI Review to get started.
            </Text>
          </Skeleton>
          {renderAIReviewButton()}
        </section>
      );
    }

    return (
      <section
        className={cx(
          "grid w-auto place-content-center justify-items-center gap-y-4 text-wrap p-4",
          {
            hidden: !open,
          },
        )}
      >
        <Text size="3">No comments yet. Add your own to get started.</Text>
      </section>
    );
  }

  function focusCommentShape(shapeId: TLShapeId) {
    const shapePoints = editor?.getShape(shapeId);

    editor?.run(() => {
      editor.setSelectedShapes([shapeId]);
      editor.centerOnPoint({
        x: shapePoints?.x as number,
        y: shapePoints?.y as number,
      });
    });
  }

  function getShapeFromComment(commentId: string) {
    const shapes = editor?.getCurrentPageShapes() ?? [];
    const thisShape = shapes.find((shape) => {
      if (shape.type !== "comment") {
        return null;
      }

      try {
        const meta = JSON.parse((shape.meta.comment as string) ?? "{}");

        if (
          typeof meta === "object" &&
          meta !== null &&
          "_id" in meta &&
          meta._id === commentId
        ) {
          return shape;
        }
      } catch {
        return null;
      }

      return null;
    });

    return thisShape;
  }

  async function navigateToCommentShape(boardId: string, commentId: string) {
    const nextRevision = revisions.findIndex((rev) => rev._id === boardId);
    dispatch(setActiveCommentId(commentId));
    if (nextRevision !== activeRevisionIndex) {
      setActiveRevisionIndex(nextRevision).then(() => {
        const comment = comments.find((c) => c._id === commentId);
        if (comment) {
          pendingCoordsRef.current = {
            x: comment.x as number,
            y: comment.y as number,
          };
        }
      });
    }
    if (
      props.videoRef.current &&
      comments.find((c) => c._id === commentId)?.videoStart
    ) {
      return props.videoRef.current.handleCommentClicked(
        comments.find((c) => c._id === commentId)?.videoStart as number,
        commentId,
      );
    }
    editor?.run(() => {
      const shapeId = getShapeFromComment(commentId)?.id;

      if (shapeId == null) {
        return;
      }

      focusCommentShape(shapeId);
    });
  }

  function updateShapeWithResponse(
    commentId: string,
    data: GetTicketCommentsResponse[number],
  ) {
    const comment = comments.find((c) => c._id === commentId);
    if (comment == null) return;

    const isSuggestedOpen =
      suggestedImprovementsRef.current?.getAttribute("aria-expanded");
    const isRequiredOpen =
      requiredImprovementsRef.current?.getAttribute("aria-expanded");

    if (!data.isRequired && comment.isRequired && isSuggestedOpen === "false") {
      suggestedImprovementsRef.current?.click();
    }
    if (data.isRequired && !comment.isRequired && isRequiredOpen === "false") {
      requiredImprovementsRef.current?.click();
    }
    const shape = getShapeFromComment(commentId);
    if (shape == null) return;

    editor?.run(() => {
      editor.setSelectedShapes([shape.id]);
      if (
        user?.role != "ai" &&
        user?.role != "meaningful-gigs" &&
        (data.disposition == TicketCommentDisposition.DISMISSED ||
          data.disposition == TicketCommentDisposition.RESOLVED)
      ) {
        editor.deleteShapes([shape.id, shape.meta.linkedAvatarId as TLShapeId]);
        return;
      }

      editor.updateShape({
        ...shape,
        meta: {
          ...shape.meta,
          comment: JSON.stringify(data),
        },
        props: {
          ...shape.props,
          commentId: data._id,
        },
      });
      editor.bringToFront([shape.id]);
    });
  }

  const pendingReviewComments = allCommentsFromBoardType.filter(
    (c) =>
      c.isPending &&
      c.createdBy &&
      (typeof c.createdBy === "string" ? c.createdBy : c.createdBy._id) ===
        user?.userID,
  );
  const currentBoardComments = allCommentsFromBoardType.filter(
    (c) => c.boardId === currentRevision?._id,
  );

  function canSubmitReview() {
    // only MG and AI can leave reviews. If we can't get to this case, then
    // cool.
    return true;
  }

  function handleSubmitReview() {
    if (ticket == null || !canSubmitReview()) {
      return;
    }

    reviewRevisionMutation
      .mutateAsync({
        payload: {
          ticketId: ticket._id,
          boardId: revisions[activeRevisionIndex!]?._id,
          creatives: [],
          approval: false,
        },
      })
      .then(() => {
        router.invalidate();
      });
  }

  if (pendingReviewComments.length > 0) {
    const previousComments = currentBoardComments.filter((c) => !c.isPending);

    return (
      <section className={sectionClasses}>
        <div data-testid="pending-comments">
          <StickyHeader
            label="Pending Review"
            action={
              <Flex gap="2" align="center">
                <Button
                  size="1"
                  onClick={runAIReview}
                  variant="solid"
                  className={cx({
                    hidden: aiReviewerAccessKey !== "always",
                  })}
                  disabled={aiReviewRunning}
                  loading={aiReviewRunning}
                >
                  <MagicWand color="rgb(var(--base-white))" />
                  AI Review
                </Button>

                <Button
                  size="1"
                  onClick={() => handleSubmitReview()}
                  disabled={reviewRevisionMutation.isPending}
                  loading={reviewRevisionMutation.isPending}
                >
                  Finish Review
                </Button>
              </Flex>
            }
          />

          {pendingReviewComments.map((comment) => (
            <Comment
              mentions={comment.mentions as string[]}
              users={users}
              editor={editor}
              rule={comment.rule}
              key={comment._id}
              author={
                comment.createdBy as Exclude<typeof comment.createdBy, string>
              }
              isAI={comment.isAI}
              message={comment.description}
              messageId={comment._id}
              meta={comment}
              replies={comment.messages}
              title={revisions.find((r) => r._id === comment.boardId)?.name}
              onClick={() =>
                navigateToCommentShape(comment.boardId as string, comment._id)
              }
              createdAt={comment.createdAt}
              updatedAt={comment.updatedAt}
              onSuccess={handleCommentReplySuccess(comment, "pending")}
              onError={handleCommentReplyError(comment, "pending")}
              isActive={comment._id === activeCommentId}
              videoStart={comment.videoStart}
              commentId={comment._id}
              aiPersona={comment.aiPersona}
            />
          ))}
        </div>

        <div
          data-testid="previous-comments"
          className={cx({ hidden: previousComments.length === 0 })}
        >
          <StickyHeader label="Previous Comments" />

          {previousComments.map((comment) => (
            <Comment
              mentions={comment.mentions as string[]}
              users={users}
              editor={editor}
              rule={comment.rule}
              key={comment._id}
              author={
                comment.createdBy as Exclude<typeof comment.createdBy, string>
              }
              isAI={comment.isAI}
              message={comment.description}
              messageId={comment._id}
              meta={comment}
              replies={comment.messages}
              onClick={() =>
                navigateToCommentShape(comment.boardId as string, comment._id)
              }
              createdAt={comment.createdAt}
              updatedAt={comment.updatedAt}
              onSuccess={handleCommentReplySuccess(comment, "previous pending")}
              onError={handleCommentReplyError(comment, "previous pending")}
              isActive={comment._id === activeCommentId}
              videoStart={comment.videoStart}
              commentId={comment._id}
              aiPersona={comment.aiPersona}
            />
          ))}
        </div>
      </section>
    );
  }

  function renderAIReviewButton() {
    if (!revisions.length) return null;
    const isDisabled =
      aiReviewRunning || hasInitiatedAiReview || query.isLoading;
    const canChoosePersonas =
      canChooseCommentPersonas() &&
      (query.data?.aiPersonas?.filter((p) => p.enabled) ?? []).length > 1;

    if (!open) {
      return (
        <AuthTooltip>
          <Skeleton loading={ticket?.isPending}>
            <SidebarMenuButton
              asChild
              onClick={runAIReview}
              disabled={isDisabled}
              tooltipClassName="bg-puntt-accent-9"
              tooltipSide="left"
              tooltip={
                <button
                  onClick={runAIReview}
                  disabled={isDisabled}
                  className="flex items-center gap-3 bg-transparent"
                >
                  <Text size="3" className="text-base-white">
                    Start AI Review
                  </Text>
                  <MagicWand size={18} color="white" />
                </button>
              }
            >
              <Button
                variant="solid"
                color="blue"
                className={cx("size-10", {
                  "bg-puntt-neutral-gray-8 p-0": isDisabled,
                  "p-2.5": !isDisabled,
                })}
              >
                {aiReviewRunning || hasInitiatedAiReview ? (
                  <img
                    alt="leap frog loader"
                    src={leapfrogLoader}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <MagicWand size={18} color="white" />
                )}
              </Button>
            </SidebarMenuButton>
          </Skeleton>
        </AuthTooltip>
      );
    }

    return (
      <>
        <AuthTooltip>
          <Skeleton loading={ticket?.isPending}>
            <Popover.Root>
              <Flex width="100%">
                <Button
                  size="4"
                  onClick={runAIReview}
                  variant="solid"
                  className={cx(
                    "relative flex-1 cursor-pointer transition-colors disabled:cursor-not-allowed disabled:text-base-white",
                    {
                      "bg-puntt-neutral-gray-8": isDisabled,
                      "bg-puntt-accent-9 hover:bg-puntt-accent-10": !isDisabled,
                      "rounded-r-none": canChoosePersonas,
                    },
                  )}
                  disabled={isDisabled}
                >
                  {aiReviewRunning || hasInitiatedAiReview ? (
                    <img
                      alt="leap frog loader"
                      src={leapfrogLoader}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <>
                      <MagicWand />
                      Start AI Review
                    </>
                  )}
                </Button>

                <Popover.Trigger
                  disabled={isDisabled}
                  className={cx({
                    "bg-puntt-neutral-gray-8": isDisabled,
                    hidden: !canChoosePersonas,
                  })}
                >
                  <IconButton size="4" className="rounded-l-none">
                    <CaretDown />
                  </IconButton>
                </Popover.Trigger>
              </Flex>

              <Popover.Content align="end" width="288px">
                {/* TODO: hit the API again to get the available personas rather than hardcode them */}
                <Flex
                  gap="2"
                  py="1"
                  className={cx({
                    hidden: !query.data?.aiPersonas.find(
                      (p) => p.name.toLowerCase() === "brand",
                    )?.enabled,
                  })}
                >
                  <Box py="1">
                    <Checkbox
                      defaultChecked={getSelectedPersonas().includes("brand")}
                      onCheckedChange={(checked) =>
                        handlePersonaChange("brand", Boolean(checked))
                      }
                    />
                  </Box>

                  <Box>
                    <Text as="p" size="3" weight="medium">
                      Brand Review
                    </Text>
                    <Text>
                      Focuses on visual design and adherence to{" "}
                      <Link className="puntt-link" asChild>
                        <NavLink to={knowledgeSettingRoute.to}>
                          Brand Guidelines
                        </NavLink>
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Flex
                  gap="2"
                  py="1"
                  className={cx({
                    hidden: !query.data?.aiPersonas.find(
                      (p) => p.name.toLowerCase() === "legal",
                    )?.enabled,
                  })}
                >
                  <Box py="1">
                    <Checkbox
                      defaultChecked={getSelectedPersonas().includes("legal")}
                      onCheckedChange={(checked) =>
                        handlePersonaChange("legal", Boolean(checked))
                      }
                    />
                  </Box>

                  <Box>
                    <Text as="p" size="3" weight="medium">
                      Legal Review
                    </Text>
                    <Text>
                      Focuses on claims and compliance using the{" "}
                      <Link className="puntt-link" asChild>
                        <NavLink
                          to={knowledgeDatabaseRoute.to}
                          search={{ pageSize: 100 }}
                        >
                          Knowledge Database
                        </NavLink>
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Flex
                  gap="2"
                  py="1"
                  className={cx({
                    hidden: !query.data?.aiPersonas.find(
                      (p) => p.name.toLowerCase() === "copy",
                    )?.enabled,
                  })}
                >
                  <Box py="1">
                    <Checkbox
                      defaultChecked={getSelectedPersonas().includes("copy")}
                      onCheckedChange={(checked) =>
                        handlePersonaChange("copy", Boolean(checked))
                      }
                    />
                  </Box>

                  <Box>
                    <Text as="p" size="3" weight="medium">
                      Copy Review
                    </Text>
                    <Text>
                      Focuses on tone and style for text using the{" "}
                      <Link className="puntt-link" asChild>
                        <NavLink to={knowledgeSettingRoute.to}>
                          Brand Guidelines
                        </NavLink>
                      </Link>
                    </Text>
                  </Box>
                </Flex>
              </Popover.Content>
            </Popover.Root>
          </Skeleton>
        </AuthTooltip>
        {renderAIReviewRunning()}
      </>
    );
  }

  function renderPriorityAdjustments() {
    const activeRevisionPriorityAdjustments = currentBoardComments.filter(
      (comment) => comment.isRequired === true,
    );

    return (
      <Disclosure
        defaultOpen={activeRevisionPriorityAdjustments.length > 0}
        as="div"
        className="relative"
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              className={collapsibleTriggerClasses}
              ref={requiredImprovementsRef}
            >
              <Flex gap="2" align="center">
                <Text size="3" weight="medium">
                  Required Changes
                </Text>

                <Badge
                  size="1"
                  variant="solid"
                  color={
                    activeRevisionPriorityAdjustments.length > 0
                      ? "red"
                      : "grass"
                  }
                >
                  {activeRevisionPriorityAdjustments.length}
                </Badge>
              </Flex>

              <CaretLeft
                className={cx("transition-transform", {
                  "-rotate-90": open,
                })}
              />
            </Disclosure.Button>
            <Disclosure.Panel>
              <section className="relative">
                <StickyHeader
                  label={"V" + (revisions.length - activeRevisionIndex!)}
                  className={cx("top-[57px]", {
                    hidden: activeRevisionPriorityAdjustments.length === 0,
                  })}
                />
                {activeRevisionPriorityAdjustments.map((comment) => (
                  <Comment
                    mentions={comment.mentions as string[]}
                    users={users}
                    editor={editor}
                    rule={comment.rule}
                    key={comment._id}
                    author={
                      comment.createdBy as Exclude<
                        typeof comment.createdBy,
                        string
                      >
                    }
                    createdAt={comment.createdAt}
                    isAI={comment.isAI}
                    message={comment.description}
                    messageId={comment._id}
                    meta={comment}
                    replies={comment.messages}
                    onClick={() =>
                      navigateToCommentShape(
                        comment.boardId as string,
                        comment._id,
                      )
                    }
                    updatedAt={comment.updatedAt}
                    onSuccess={handleCommentReplySuccess(comment, "required")}
                    onError={handleCommentReplyError(comment, "required")}
                    isActive={comment._id === activeCommentId}
                    videoStart={comment.videoStart}
                    commentId={comment._id}
                    aiPersona={comment.aiPersona}
                  />
                ))}
                {activeRevisionPriorityAdjustments.length === 0 && (
                  <p className="grid place-content-center text-wrap p-4">
                    This version has no Required Changes yet.
                  </p>
                )}
              </section>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  function renderSuggestedImprovements() {
    const activeRevisionSuggestedImprovements = currentBoardComments.filter(
      (comment) => comment.isRequired === false,
    );

    return (
      <Disclosure
        as="div"
        className="relative"
        defaultOpen={activeRevisionSuggestedImprovements.length > 0}
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              className={collapsibleTriggerClasses}
              ref={suggestedImprovementsRef}
            >
              <Flex gap="2" align="center">
                <Text size="3" weight="medium">
                  Suggested Improvements
                </Text>

                <Badge size="1" variant="solid" color="gray">
                  {activeRevisionSuggestedImprovements.length}
                </Badge>
              </Flex>

              <CaretLeft
                className={cx("transition-transform", {
                  "-rotate-90": open,
                })}
              />
            </Disclosure.Button>
            <Disclosure.Panel>
              <section className="relative">
                <StickyHeader
                  label={"V" + (revisions.length - activeRevisionIndex!)}
                  className={cx("top-[57px]", {
                    hidden: activeRevisionSuggestedImprovements.length === 0,
                  })}
                />
                {activeRevisionSuggestedImprovements.map((comment) => (
                  <Comment
                    mentions={comment.mentions as string[]}
                    users={users}
                    editor={editor}
                    rule={comment.rule}
                    key={comment._id}
                    author={
                      comment.createdBy as Exclude<
                        typeof comment.createdBy,
                        string
                      >
                    }
                    createdAt={comment.createdAt}
                    isAI={comment.isAI}
                    message={comment.description}
                    messageId={comment._id}
                    meta={comment}
                    replies={comment.messages}
                    onClick={() =>
                      navigateToCommentShape(
                        comment.boardId as string,
                        comment._id,
                      )
                    }
                    updatedAt={comment.updatedAt}
                    onSuccess={handleCommentReplySuccess(comment, "suggested")}
                    onError={handleCommentReplyError(comment, "suggested")}
                    isActive={comment._id === activeCommentId}
                    videoStart={comment.videoStart}
                    commentId={comment._id}
                    aiPersona={comment.aiPersona}
                  />
                ))}
                {activeRevisionSuggestedImprovements.length === 0 && (
                  <p className="grid place-content-center text-wrap p-4">
                    This version has no Suggested Improvements yet.
                  </p>
                )}
              </section>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  function renderCommentsWithCounts() {
    const countedComments = new Map<string, number>();
    const uniqueCommentAuthors = new Map<
      string,
      Exclude<(typeof currentBoardComments)[number]["createdBy"], string> & {
        isAI?: boolean;
      }
    >();
    const activeRevisionRequiredChanges = currentBoardComments.filter(
      (comment) => comment.isRequired === true,
    );
    const activeRevisionSuggestedImprovements = currentBoardComments.filter(
      (comment) => comment.isRequired === false,
    );

    [
      ...activeRevisionRequiredChanges,
      ...activeRevisionSuggestedImprovements,
    ].forEach((comment) => {
      const author = comment.createdBy as Exclude<
        typeof comment.createdBy,
        string
      >;

      if (!isNil(author) && !isNil(author._id)) {
        if (comment.isAI) {
          if (
            !uniqueCommentAuthors.has(comment.aiPersona?.avatar ?? "unknown")
          ) {
            uniqueCommentAuthors.set(comment.aiPersona?.avatar ?? "unknown", {
              ...author,
              isAI: true,
              avatar: comment.aiPersona?.avatar,
            });
          }

          countedComments.set(
            comment.aiPersona?.avatar ?? "unknown",
            (countedComments.get(comment.aiPersona?.avatar ?? "unknown") || 0) +
              1,
          );
        } else {
          if (!uniqueCommentAuthors.has(author._id)) {
            uniqueCommentAuthors.set(author._id, author);
          }

          countedComments.set(
            author._id,
            (countedComments.get(author._id) || 0) + 1,
          );
        }
      }
    });

    function renderAIAvatar(persona?: string) {
      switch (persona) {
        case "legal":
          return (
            <Avatar
              data-testid="ai-avatar"
              radius="full"
              size="1"
              variant="solid"
              fallback={<LegalRobot />}
              className="size-6 [&>.rt-AvatarFallback]:bg-teal-teal7 [&>.rt-AvatarFallback]:text-base-black"
            />
          );
        case "copy":
          return (
            <Avatar
              data-testid="ai-avatar"
              radius="full"
              size="1"
              variant="solid"
              fallback={<CopyRobot />}
              className="size-6 [&>.rt-AvatarFallback]:bg-puntt-accent-7 [&>.rt-AvatarFallback]:text-base-black"
            />
          );
        case "brand":
          return (
            <Avatar
              data-testid="ai-avatar"
              radius="full"
              color="amber"
              size="1"
              variant="solid"
              fallback={<BrandRobot />}
              className="size-6"
            />
          );
        default:
          return (
            <Avatar
              data-testid="ai-avatar"
              radius="full"
              color="amber"
              size="1"
              variant="solid"
              fallback={<Robot />}
              className="size-6"
            />
          );
      }
    }

    return Array.from(countedComments.entries()).map(([authorId, count]) => {
      const author = uniqueCommentAuthors.get(authorId)!;

      return (
        <SidebarMenuItem key={authorId} className="flex items-center gap-1">
          {author.isAI ? (
            renderAIAvatar(author.avatar)
          ) : (
            <AvatarWithInitials
              size={6}
              avatar={assetForUser(author.avatar)}
              name={getNameInitials(author.name)}
            />
          )}
          <Text size="1" color="gray">
            {count.toLocaleString()}
          </Text>
        </SidebarMenuItem>
      );
    });
  }

  if (!open) {
    return (
      <SidebarGroup>
        <SidebarMenu className="gap-2">
          <SidebarMenuItem
            data-testid="sidebar-menu-items-run-ai-review"
            data-auth-trigger="run-ai-review"
            className={cx("border-b border-b-puntt-neutral-gray-6 px-4 py-2", {
              hidden:
                (aiReviewerAccessKey === "first" &&
                  currentBoardComments.some((c) => c.isAI)) ||
                aiReviewerAccessKey === "none" ||
                aiReviewerAccessKey === false ||
                aiReviewerAccessKey == null,
            })}
          >
            {renderAIReviewButton()}
          </SidebarMenuItem>

          {renderCommentsWithCounts()}
        </SidebarMenu>
      </SidebarGroup>
    );
  }

  if (revisions.length === 0) {
    return (
      <Box p="8">
        <Text as="p" align="center">
          Create a new version to add comments
        </Text>
      </Box>
    );
  }

  return (
    <section className={sectionClasses}>
      <div
        className={cx("border-b border-b-puntt-neutral-gray-6 px-4 py-2", {
          hidden:
            (aiReviewerAccessKey === "first" &&
              currentBoardComments.some((c) => c.isAI)) ||
            aiReviewerAccessKey === "none" ||
            aiReviewerAccessKey === false ||
            aiReviewerAccessKey == null,
        })}
      >
        {renderAIReviewButton()}
      </div>
      {renderPriorityAdjustments()}
      {renderSuggestedImprovements()}
    </section>
  );
}
