// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CKEditor, useCKEditorCloud } from "@ckeditor/ckeditor5-react";
import { DecoupledEditor as DecoupledEditorType } from "ckeditor5";
import { useState, useEffect, useRef, useMemo } from "react";

import { JOKE_EXTERNAL_COMMENTS, JOKE_INITIAL_DATA } from "./JokeDemoData";
import {
  PostRedlineDemoData,
  RedlineInitialData,
  REDLINE_EXTERNAL_COMMENTS,
} from "./RedlinedFinalDemoData";

// eslint-disable-next-line import/order
import { EDITABLE_DOC_LICENSE_KEY } from "../../../../config/env";
import "./EditableDoc.css";

import { useAppSelector } from "../../../../utils/hooks";

const LICENSE_KEY = EDITABLE_DOC_LICENSE_KEY;

const CLOUD_SERVICES_TOKEN_URL =
  "https://034va16e1ql6.cke-cs.com/token/dev/317d4f7eade9523927d871b25b925b7290d5d09aca5d85015ce5173d3a92?limit=10";

export function EditableDoc({ docType }: { docType: string }) {
  const isJokeDemo = docType === "joke";
  const initialData = isJokeDemo ? JOKE_INITIAL_DATA : RedlineInitialData;
  const EXTERNAL_COMMENTS = isJokeDemo
    ? JOKE_EXTERNAL_COMMENTS
    : REDLINE_EXTERNAL_COMMENTS;
  const user = useAppSelector((state) => state.auth.value);
  const editorContainerRef = useRef(null);
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const [internalEditor, setInternalEditor] =
    useState<DecoupledEditorType | null>(null);
  const editorAnnotationsRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [editorData, setEditorData] = useState(initialData);

  const cloud = useCKEditorCloud({
    version: "44.3.0",
    premium: true,
    ckbox: { version: "2.6.1" },
  });

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const { DecoupledEditor, editorConfig } = useMemo(() => {
    if (cloud.status !== "success" || !isLayoutReady) {
      return {};
    }

    const {
      DecoupledEditor,
      Plugin,
      ButtonView,
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      BalloonToolbar,
      BlockQuote,
      BlockToolbar,
      Bold,
      Bookmark,
      CKBox,
      CKBoxImageEdit,
      CloudServices,
      Code,
      CodeBlock,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FullPage,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      HorizontalLine,
      HtmlEmbed,
      ImageBlock,
      ImageCaption,
      ImageEditing,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      ImageUtils,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Markdown,
      MediaEmbed,
      PageBreak,
      Paragraph,
      PasteFromMarkdownExperimental,
      PasteFromOffice,
      PictureEditing,
      RemoveFormat,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Style,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
    } = cloud.CKEditor;
    const {
      CaseChange,
      Comments,
      MultiLevelList,
      Pagination,
      PasteFromOfficeEnhanced,
      SourceEditingEnhanced,
      TrackChanges,
      TrackChangesData,
      TrackChangesPreview,
    } = cloud.CKEditorPremiumFeatures;

    /**
     * The `UsersIntegration` lets you manage user data and permissions.
     *
     * This is an essential feature when many users work on the same document.
     *
     * To read more about it, visit the CKEditor 5 documentation: https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/users.html.
     */
    class UsersIntegration extends Plugin {
      static get requires() {
        return ["Users"];
      }

      static readonly pluginName = "UsersIntegration";

      init() {
        const usersPlugin = this.editor.plugins.get("Users");

        // These are sample users for demonstration purposes.
        // In your integration make sure to provide user data from your data source.
        const users = [
          { id: "user-1", name: user?.name },
          {
            id: "legal-ai-reviewer",
            name: "Puntt AI: Legal",
            avatar: `${window.location.origin}/LegalAvatar.png`,
          },
          {
            id: "copy-ai-reviewer",
            name: "Puntt AI: Copy",
            avatar: `${window.location.origin}/CopyAvatar.png`,
          },
        ];
        const me = users[0];

        for (const user of users) {
          usersPlugin.addUser(user);
        }

        usersPlugin.defineMe(me.id);
      }
    }

    /**
     * The `CommentsIntegration` lets you synchronize comments in the document with your data source (e.g. a database).
     *
     * To read more about it, visit the CKEditor 5 documentation: https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/comments/comments-integration.html.
     */
    class CommentsIntegration extends Plugin {}

    /**
     * The `TrackChangesIntegration` lets you synchronize suggestions added to the document with your data source (e.g. a database).
     *
     * To read more about it, visit the CKEditor 5 documentation: https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/track-changes/track-changes-integration.html.
     */
    class TrackChangesIntegration extends Plugin {}

    /**
     * The `AnnotationsSidebarToggler` plugin adds an icon to the right side of the editor.
     *
     * It allows to toggle the right annotations bar visibility.
     */
    class AnnotationsSidebarToggler extends Plugin {
      static get requires() {
        return ["AnnotationsUIs"];
      }

      static readonly pluginName = "AnnotationsSidebarToggler";

      init() {
        this.toggleButton = new ButtonView(this.editor.locale);

        const NON_COLLAPSE_ANNOTATION_ICON =
          '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" transform="matrix(-1,0,0,1,0,0)"><path d="M11.463 5.187a.888.888 0 1 1 1.254 1.255L9.16 10l3.557 3.557a.888.888 0 1 1-1.254 1.255L7.26 10.61a.888.888 0 0 1 .16-1.382l4.043-4.042z"></path></svg>';
        const COLLAPSE_ANNOTATION_ICON =
          '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" transform="matrix(1,0,0,1,0,0)"><path d="M11.463 5.187a.888.888 0 1 1 1.254 1.255L9.16 10l3.557 3.557a.888.888 0 1 1-1.254 1.255L7.26 10.61a.888.888 0 0 1 .16-1.382l4.043-4.042z"/></svg>';

        const annotationsUIsPlugin = this.editor.plugins.get("AnnotationsUIs");
        const annotationsContainer =
          this.editor.config.get("sidebar.container");
        const sidebarContainer = annotationsContainer.parentElement;

        this.toggleButton.set({
          label: "Toggle annotations sidebar",
          tooltip: "Hide annotations sidebar",
          tooltipPosition: "se",
          icon: COLLAPSE_ANNOTATION_ICON,
        });

        this.toggleButton.on("execute", () => {
          // Toggle a CSS class on the annotations sidebar container to manage the visibility of the sidebar.
          annotationsContainer.classList.toggle("ck-hidden");

          // Change the look of the button to reflect the state of the annotations container.
          if (annotationsContainer.classList.contains("ck-hidden")) {
            this.toggleButton.icon = NON_COLLAPSE_ANNOTATION_ICON;
            this.toggleButton.tooltip = "Show annotations sidebar";
            annotationsUIsPlugin.switchTo("inline");
          } else {
            this.toggleButton.icon = COLLAPSE_ANNOTATION_ICON;
            this.toggleButton.tooltip = "Hide annotations sidebar";
            annotationsUIsPlugin.switchTo("wideSidebar");
          }

          // Keep the focus in the editor whenever the button is clicked.
          this.editor.editing.view.focus();
        });

        this.toggleButton.render();

        sidebarContainer.insertBefore(
          this.toggleButton.element,
          annotationsContainer,
        );
      }

      destroy() {
        this.toggleButton.element.remove();

        return super.destroy();
      }
    }

    return {
      DecoupledEditor,
      editorConfig: {
        toolbar: {
          items: [
            "previousPage",
            "nextPage",
            "pageNavigation",
            "|",
            "trackChanges",
            "comment",
            "commentsArchive",
            "|",
            "sourceEditingEnhanced",
            "caseChange",
            "findAndReplace",
            "|",
            "heading",
            "style",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "code",
            "removeFormat",
            "|",
            "specialCharacters",
            "horizontalLine",
            "pageBreak",
            "link",
            "bookmark",
            "insertImage",
            "ckbox",
            "mediaEmbed",
            "insertTable",
            "highlight",
            "blockQuote",
            "codeBlock",
            "htmlEmbed",
            "|",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
            "multiLevelList",
            "todoList",
            "outdent",
            "indent",
          ],
          shouldNotGroupWhenFull: false,
        },
        plugins: [
          Alignment,
          Autoformat,
          AutoImage,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          BlockToolbar,
          Bold,
          Bookmark,
          CaseChange,
          CKBox,
          CKBoxImageEdit,
          CloudServices,
          Code,
          CodeBlock,
          Comments,
          Essentials,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          FullPage,
          GeneralHtmlSupport,
          Heading,
          Highlight,
          HorizontalLine,
          HtmlEmbed,
          ImageBlock,
          ImageCaption,
          ImageEditing,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          ImageUtils,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          Markdown,
          MediaEmbed,
          MultiLevelList,
          PageBreak,
          Pagination,
          Paragraph,
          PasteFromMarkdownExperimental,
          PasteFromOffice,
          PasteFromOfficeEnhanced,
          PictureEditing,
          RemoveFormat,
          SourceEditingEnhanced,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Style,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          TrackChanges,
          TrackChangesData,
          TrackChangesPreview,
          Underline,
        ],
        extraPlugins: [
          AnnotationsSidebarToggler,
          UsersIntegration,
          CommentsIntegration,
          TrackChangesIntegration,
        ],
        balloonToolbar: [
          "comment",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertImage",
          "|",
          "bulletedList",
          "numberedList",
        ],
        blockToolbar: [
          "comment",
          "|",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertImage",
          "insertTable",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
        ],
        cloudServices: {
          tokenUrl: CLOUD_SERVICES_TOKEN_URL,
        },
        comments: {
          editorConfig: {
            extraPlugins: [Autoformat, Bold, Italic, List],
          },
        },
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true,
            },
          ],
        },
        image: {
          toolbar: [
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "|",
            "resizeImage",
            "|",
            "ckboxImageEdit",
          ],
        },
        initialData: editorData,
        licenseKey: LICENSE_KEY,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        pagination: {
          pageWidth: "21cm",
          pageHeight: "29.7cm",
          pageMargins: {
            top: "20mm",
            bottom: "20mm",
            right: "12mm",
            left: "12mm",
          },
        },
        placeholder: "Type or paste your content here!",
        sidebar: {
          container: editorAnnotationsRef.current,
        },
        style: {
          definitions: [
            {
              name: "Article category",
              element: "h3",
              classes: ["category"],
            },
            {
              name: "Title",
              element: "h2",
              classes: ["document-title"],
            },
            {
              name: "Subtitle",
              element: "h3",
              classes: ["document-subtitle"],
            },
            {
              name: "Info box",
              element: "p",
              classes: ["info-box"],
            },
            {
              name: "Side quote",
              element: "blockquote",
              classes: ["side-quote"],
            },
            {
              name: "Marker",
              element: "span",
              classes: ["marker"],
            },
            {
              name: "Spoiler",
              element: "span",
              classes: ["spoiler"],
            },
            {
              name: "Code (dark)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-dark"],
            },
            {
              name: "Code (bright)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-bright"],
            },
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
      },
    };
  }, [cloud, isLayoutReady]);
  useEffect(() => {
    if (!internalEditor || cloud.status != "success") return;
    const editor = internalEditor;
    const commentsRepo = editor.plugins.get("CommentsRepository");
    function findParagraphAndPositions(
      editor: any,
      targetParagraphNumber: number,
      startOffset: number,
      endOffset?: number,
    ) {
      const root = editor.model.document.getRoot();

      // Make sure we have content to work with
      if (!root || root.childCount === 0) {
        console.warn("No content found in editor");
        return null;
      }

      // Find the target paragraph
      let targetParagraph = null;
      let paragraphCount = 0;

      // Iterate through root children to find the target paragraph
      for (const child of root.getChildren()) {
        if (child.name === "paragraph") {
          paragraphCount++;
          if (paragraphCount === targetParagraphNumber) {
            targetParagraph = child;
            break;
          }
        }
      }

      if (!targetParagraph) {
        console.warn(
          `Paragraph ${targetParagraphNumber} not found in the document`,
        );
        return null;
      }

      // Get the text length of the paragraph to ensure we don't exceed it
      const paragraphText = Array.from(targetParagraph.getChildren())
        .filter((item) => item.is("$text"))
        .reduce((text, item) => text + item.data, "");

      // Determine safe offsets
      const safeStartOffset = Math.min(startOffset, paragraphText.length);
      const safeEndOffset = endOffset
        ? Math.min(endOffset, paragraphText.length)
        : undefined;

      // Create positions
      const startPosition = editor.model.createPositionAt(
        targetParagraph,
        safeStartOffset,
      );
      const endPosition = safeEndOffset
        ? editor.model.createPositionAt(targetParagraph, safeEndOffset)
        : undefined;

      return { targetParagraph, startPosition, endPosition };
    }

    EXTERNAL_COMMENTS.forEach(({ id, text, paragraph, start, end }) => {
      const { startPosition, endPosition } = findParagraphAndPositions(
        editor,
        paragraph,
        start,
        end,
      );

      if (startPosition && endPosition) {
        editor.model.change((writer) => {
          const range = writer.createRange(startPosition, endPosition);
          commentsRepo.addCommentThread({
            // channelId: DOCUMENT_ID,
            threadId: id,
            comments: [
              {
                commentId: `comment-${id}`,
                authorId: !isJokeDemo
                  ? "legal-ai-reviewer"
                  : "copy-ai-reviewer",
                content: `${text}`,
                createdAt: new Date(),
              },
            ],
          });

          writer.addMarker(`comment:${id}`, {
            range,
            usingOperation: true,
            affectsData: true,
          });
        });
      }
    });
    if (!isJokeDemo) {
      const trackChanges = editor.plugins.get("TrackChanges");

      trackChanges.addSuggestion({
        id: `suggestion-1`,
        type: "insertion",
        authorId: "legal-ai-reviewer",
        createdAt: new Date(),
        data: null,
        attributes: {},
      });
      trackChanges.addSuggestion({
        id: `suggestion-2`,
        type: "deletion",
        authorId: "legal-ai-reviewer",
        createdAt: new Date(),
        data: null,
        attributes: {},
      });
      trackChanges.addSuggestion({
        id: `suggestion-3`,
        type: "insertion",
        authorId: "legal-ai-reviewer",
        createdAt: new Date(),
        data: null,
        attributes: {},
      });
      trackChanges.addSuggestion({
        id: `suggestion-4`,
        type: "deletion",
        authorId: "legal-ai-reviewer",
        createdAt: new Date(),
        data: null,
        attributes: {},
      });

      setEditorData(PostRedlineDemoData);
    }
  }, [isLayoutReady, internalEditor]);
  useEffect(() => {
    if (editorConfig) {
      configUpdateAlert(editorConfig);
    }
  }, [editorConfig]);

  return (
    <div className="main-container">
      <div
        className="editor-container editor-container_document-editor editor-container_include-annotations editor-container_include-pagination editor-container_include-style"
        ref={editorContainerRef}
      >
        <div className="editor-container__toolbar" ref={editorToolbarRef}></div>
        <div className="editor-container__editor-wrapper">
          <div className="editor-container__editor">
            <div ref={editorRef}>
              {DecoupledEditor && editorConfig && (
                <CKEditor
                  onReady={(editor) => {
                    editorToolbarRef.current.appendChild(
                      editor.ui.view.toolbar.element,
                    );
                    setInternalEditor(editor);
                  }}
                  onAfterDestroy={() => {
                    Array.from(editorToolbarRef.current.children).forEach(
                      (child) => child.remove(),
                    );
                  }}
                  editor={DecoupledEditor}
                  config={editorConfig}
                  data={editorData}
                />
              )}
            </div>
          </div>
          <div className="editor-container__sidebar">
            <div ref={editorAnnotationsRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * This function exists to remind you to update the config needed for premium features.
 * The function can be safely removed. Make sure to also remove call to this function when doing so.
 */
function configUpdateAlert(config) {
  if (configUpdateAlert.configUpdateAlertShown) {
    return;
  }

  const isModifiedByUser = (currentValue, forbiddenValue) => {
    if (currentValue === forbiddenValue) {
      return false;
    }

    if (currentValue === undefined) {
      return false;
    }

    return true;
  };

  const valuesToUpdate = [];

  configUpdateAlert.configUpdateAlertShown = true;

  if (!isModifiedByUser(config.licenseKey, "<YOUR_LICENSE_KEY>")) {
    valuesToUpdate.push("LICENSE_KEY");
  }

  if (
    !isModifiedByUser(
      config.cloudServices?.tokenUrl,
      "<YOUR_CLOUD_SERVICES_TOKEN_URL>",
    )
  ) {
    valuesToUpdate.push("CLOUD_SERVICES_TOKEN_URL");
  }

  if (valuesToUpdate.length) {
    window.alert(
      [
        "Please update the following values in your editor config",
        "to receive full access to Premium Features:",
        "",
        ...valuesToUpdate.map((value) => ` - ${value}`),
      ].join("\n"),
    );
  }
}
