import cx from "classnames";
import React, {
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useState,
  type ReactNode,
  type HTMLAttributes,
  type Ref,
} from "react";
import { twMerge } from "tailwind-merge";

import { ButtonNew } from "../button";
import { type ButtonNewProps } from "../button";
import { Icon, Typography } from "../foundation";

/**
 * Dialog
 */
export interface DialogProps extends HTMLAttributes<HTMLDialogElement> {
  /**
   * Callback function for when the Dialog begins to close
   */
  onClose?(evt: CustomEvent<{ action: "close" | string }>): void;
  /**
   * Callback function for when the Dialog has closed
   */
  onClosed?(evt: CustomEvent<{ action: "closed" | string }>): void;
  /**
   * Callback function for when the Dialog begins to open
   */
  onOpen?(evt: CustomEvent<{ action: "open" }>): void;
  /**
   * Callback function for when the Dialog has opened
   */
  onOpened?(evt: CustomEvent<{ action: "opened" }>): void;
  /**
   * Whether or not the Dialog is showing
   */
  open?: boolean;
  /**
   * Optionally, render a title at the top of the Dialog.
   */
  title?: string;
}

export const Dialog = forwardRef(
  (
    {
      onClose,
      onClosed,
      onOpen,
      onOpened,
      open = false,
      title,
      ...rest
    }: DialogProps,
    ref: Ref<HTMLDialogElement>,
  ) => {
    const {
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      children,
      className: hash,
      ...pass
    } = rest; // bypass prop validation with unexposed props
    const dialogSurfaceRef = useRef<HTMLDivElement>(null);
    const chosenAction = useRef<HTMLButtonElement | null>();
    const [isOpen, setIsOpen] = useState(false);

    const classes = twMerge(
      cx(
        "mg-dialog",
        // breaking up the classnames so they don't run off the screen
        "dali-p-6 dali-rounded-lg dali-max-h-screen",
        // size-based top-offset
        "dali-mt-6 sm:dali-mt-9 md:dali-mt-28",
        // size-based x-axis margin
        "dali-mx-6 sm:dali-mx-9 md:dali-mx-auto",
        // size-based max widths
        "dali-w-full md:dali-w-[516px] lg:dali-w-[460px] xl:dali-w-[592px] 2xl:dali-w-[712px]",
        // backdrop styles
        "backdrop:dali-bg-base-black/70",
        hash,
      ),
    );

    const handleClose = useCallback(() => {
      if (onClose) {
        onClose(
          new CustomEvent("onClose", {
            detail: {
              action: chosenAction.current?.dataset.mgDialogAction ?? "close",
            },
          }),
        );
      }

      setIsOpen(false);

      if (onClosed)
        onClosed(
          new CustomEvent("onClosed", {
            detail: {
              action: chosenAction.current?.dataset.mgDialogAction ?? "closed",
            },
          }),
        );

      chosenAction.current = null;
    }, [onClose, onClosed]);

    const handleOpen = useCallback(() => {
      if (onOpen) {
        onOpen(
          new CustomEvent("onOpen", {
            detail: { action: "open" },
          }),
        );
      }

      setIsOpen(true);
    }, [onOpen]);

    const handleOpened = useCallback(() => {
      const surfaceParent = dialogSurfaceRef.current
        ?.parentElement as HTMLDialogElement | null;
      // unnecessary redundancy check since we don't enter this function if
      // surfaceParent is not defined.
      if (surfaceParent == null) {
        return;
      }

      if (surfaceParent.getAttribute("open") === null) {
        surfaceParent.showModal();
      }

      if (onOpened) {
        onOpened(
          new CustomEvent("onOpened", {
            detail: { action: "opened" },
          }),
        );
      }
    }, [onOpened]);

    // handle open and close
    useEffect(() => {
      const surfaceParent = dialogSurfaceRef.current
        ?.parentElement as HTMLDialogElement | null;

      if (open && !isOpen) {
        handleOpen();
      }

      if (isOpen && surfaceParent != null) {
        handleOpened();
      }
    }, [open, handleOpen, handleOpened, isOpen]);

    // restore accessibility controls (Esc and Enter)
    useEffect(() => {
      const dialogSurface = dialogSurfaceRef.current;
      const surfaceParent = dialogSurfaceRef.current
        ?.parentElement as HTMLDialogElement | null;

      if (!dialogSurface) {
        return;
      }

      const closeDialog = (e: Event) => {
        chosenAction.current = e.currentTarget as HTMLButtonElement;
        surfaceParent?.close();
      };
      surfaceParent?.addEventListener("close", handleClose);

      const dialogActionButtons = dialogSurface.querySelectorAll(
        "[data-mg-dialog-action]",
      );
      dialogActionButtons.forEach((button) => {
        button.addEventListener("click", closeDialog);
      });

      return () => {
        surfaceParent?.removeEventListener("close", handleClose);

        dialogActionButtons.forEach((button) => {
          button.removeEventListener("click", closeDialog);
        });
      };
    }, [handleClose]);

    return (
      <dialog {...pass} ref={ref} className={classes} data-testid="dialog">
        <div
          ref={dialogSurfaceRef}
          className={twMerge(cx("mg-dialog__surface", "dali-grid dali-gap-2"))}
          role="alertdialog"
          aria-modal
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
        >
          <header className="dali-flex dali-items-center dali-justify-between">
            <Typography
              as="span"
              size="2xl"
              weight="bold"
              className="dali-text-base-black"
            >
              {title}
            </Typography>

            <button data-mg-dialog-action="cancel" data-testid="x-btn">
              <Icon.XCircle color="rgb(var(--base-black))" size={24} />
            </button>
          </header>
          {children}
        </div>
      </dialog>
    );
  },
);

export interface SimpleDialogProps extends Omit<DialogProps, "title"> {
  /**
   * Optionally, apply a Dialog title (can be a string or JSX)
   */
  title?: string;
  /**
   * Optionally, apply Dialog contents
   */
  body?: ReactNode;
  /**
   * Optionally, override the default accept button label
   */
  acceptLabel?: string | JSX.Element | null;
  /**
   * Optionally, override the default cancel button label
   */
  cancelLabel?: string | JSX.Element | null;
}

export function SimpleDialog({
  title,
  body,
  acceptLabel = "Accept",
  cancelLabel = "Cancel",
  children,
  ...rest
}: SimpleDialogProps) {
  return (
    <Dialog title={title} {...rest}>
      {(!!body || children) && (
        <DialogContent>
          {body}
          {children}
          {(!!cancelLabel || !!acceptLabel) && (
            <DialogActions>
              {!!acceptLabel && (
                <DialogButton
                  data-testid="save-btn"
                  theme="primary"
                  action="accept"
                  isDefaultAction
                >
                  {acceptLabel}
                </DialogButton>
              )}
              {!!cancelLabel && (
                <DialogButton
                  theme="primary"
                  action="cancel"
                  variant="outlined"
                  data-testid="cancel-btn"
                >
                  {cancelLabel}
                </DialogButton>
              )}
            </DialogActions>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}

/**
 * Dialog Contents
 */

export function DialogContent({
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  const { className: hash, ...pass } = rest;
  const classes = cx("mg-dialog__content", hash);

  return (
    <div className={classes} {...pass}>
      {children}
    </div>
  );
}

/**
 * Dialog Actions
 */

export function DialogActions({
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  const { className: hash, ...pass } = rest;
  const classes = twMerge(
    cx(
      "mg-dialog__actions",
      "dali-mt-2.5 md:dali-mt-8 dali-inline-flex dali-gap-2 dali-bg-base-white",
      hash,
    ),
  );

  return (
    <div className={classes} {...pass}>
      {children}
    </div>
  );
}

/**
 * Dialog Buttons
 */
export interface DialogButtonProps extends ButtonNewProps {
  /**
   * An action returned in evt.detail.action to the onClose and onClosed handlers
   */
  action?: string;
  /**
   * Used to indicate a dangerous action
   */
  danger?: boolean;
  /**
   * TODO: indicates that this is the default selected action when pressing Enter
   */
  isDefaultAction?: boolean;
}

export function DialogButton({
  action,
  isDefaultAction,
  ...rest
}: DialogButtonProps) {
  const { className: hash, theme, ...pass } = rest;
  const defaultProp = isDefaultAction
    ? { "data-mg-dialog-button-default": true }
    : {};
  const classes = twMerge(cx("mg-dialog__button", "dali-justify-center", hash));

  return (
    <ButtonNew
      {...pass}
      {...defaultProp}
      theme={theme}
      className={classes}
      data-mg-dialog-action={action}
    />
  );
}

Dialog.displayName = "Dialog";
Dialog.defaultProps = {
  onOpen: () => {
    /* noop */
  },
  onOpened: () => {
    /* noop */
  },
  onClose: () => {
    /* noop */
  },
  onClosed: () => {
    /* noop */
  },
};
