import { createRoute, redirect } from "@tanstack/react-router";

import PunttDashboardLoader from "./loader";
import PunttDashboardView from "./view";

import { getGuidelines } from "../../services/brand-guidelines";
import { getRecentReviews } from "../../services/reviews";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
  canAccessPunttDashboard,
  requiresAuth,
} from "../../utils/auth";
import { createDefaultNetworkRouteSearch } from "../../utils/constants";
import { authLayoutRoute } from "../auth-layout/route";
import { listsRoute } from "../lists/route";
import { myNetworkRoute } from "../network/route";
import { ticketsRoute } from "../tickets/route";
import { aboutMeEditRoute } from "../userProfile/routes/AboutMe/editRoute";

export const indexRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "/",
  beforeLoad({ search }) {
    requiresAuth();

    if (search.redirect) {
      throw redirect({ to: search.redirect });
    }

    if (!canAccessPunttDashboard()) {
      if (canAccessPuntt()) {
        throw redirect({ to: ticketsRoute.to });
      }

      if (canAccessCreativeConnect()) {
        throw redirect({
          to: myNetworkRoute.to,
          search: createDefaultNetworkRouteSearch(),
        });
      }

      if (canAccessLists()) {
        throw redirect({ to: listsRoute.to });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({ to: aboutMeEditRoute.to });
      }
    }
  },
  async loader({ context }) {
    const { queryClient } = context;

    const data = await queryClient.fetchQuery({
      queryKey: ["brand-guidelines"],
      queryFn: getGuidelines,
    });

    const recents = await queryClient.fetchQuery({
      queryKey: ["workflows", "recents"],
      queryFn: getRecentReviews,
    });

    return {
      aiPersonas: data?.aiPersonas ?? [],
      recents: recents ?? [],
    };
  },
  component: PunttDashboardView,
  // We make this the new loader? I dunno... Better than what we have.
  pendingComponent: PunttDashboardLoader,
});
