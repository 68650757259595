import { Outlet } from "@tanstack/react-router";

import { AppSidebar } from "../AppSidebar";
import { AuthenticationDialog } from "../AuthenticationDialog";

export default function PunttLayout() {
  // Hooks go here if we need them

  /////
  // No hooks beyond this point
  /////

  return (
    <main className="relative grid h-screen grid-cols-[auto_1fr] overflow-hidden">
      <AuthenticationDialog />

      <AppSidebar />

      <Outlet />
    </main>
  );
}
