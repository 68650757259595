import { Logo } from "@mg/dali/src";
import { Heading, Strong, Text } from "@radix-ui/themes";
// eslint-disable-next-line import/named
import { Link } from "@tanstack/react-router";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import { useAppSelector } from "../../utils/hooks";
import { traceId } from "../../utils/http";
import { loginRoute } from "../login/route";
import { ticketsRoute } from "../tickets/route";

export const NotFoundComponent = () => {
  const posthog = usePostHog();
  const user = useAppSelector((state) => state.auth.value);

  useEffect(() => {
    posthog?.capture("not_found", {
      trace_id: traceId,
    });
  }, []); // Runs only once on mount

  return (
    <main className="relative">
      <div className="sticky top-0 z-[3]">
        <header className="sticky top-0 z-[1] flex items-center justify-center border-b border-b-carbon-300 bg-base-white p-3 shadow-3">
          <Logo variant="puntt" className="h-8" data-testid="logo" />
        </header>
      </div>
      <div className="flex flex-col items-center p-5">
        <Heading as="h1" size="6" className="p-5 text-center">
          Page not found
        </Heading>
        <div className="flex justify-center">
          <Text as="span" className="mb-1 mt-2">
            Please go back or{" "}
            <Link
              to={user?.email ? ticketsRoute.to : loginRoute.to}
              className="puntt-link transition-colors hover:text-egyptian-blue-700 active:text-egyptian-blue-800"
            >
              {user?.email ? "return to the homepage" : "log in"}
            </Link>{" "}
            to continue.
          </Text>
        </div>
        <Text
          as="p"
          size="2"
          className="mx-auto max-w-3xl pt-4 text-carbon-500"
        >
          <Strong>Error ID:</Strong> {traceId}
        </Text>
      </div>
    </main>
  );
};
