import { Robot } from "@phosphor-icons/react";
import { Avatar } from "@radix-ui/themes";
import cx from "classnames";

import { ReactComponent as BrandRobot } from "../../icons/BrandRobot.svg";
import { ReactComponent as CopyRobot } from "../../icons/CopyRobot.svg";
import { ReactComponent as LegalRobot } from "../../icons/LegalRobot.svg";

export function renderPersonaAvatar(persona: string, block = false) {
  switch (persona) {
    case "legal":
    case "Legal":
      return (
        <Avatar
          data-testid="ai-avatar"
          radius="full"
          size="1"
          variant="solid"
          fallback={<LegalRobot />}
          className={cx(
            "size-6 [&>.rt-AvatarFallback]:bg-teal-teal7 [&>.rt-AvatarFallback]:text-base-black",
            { block },
          )}
        />
      );
    case "copy":
    case "Copy":
    case "text":
    case "Text":
    case "typography":
    case "Typography":
      return (
        <Avatar
          data-testid="ai-avatar"
          radius="full"
          size="1"
          variant="solid"
          fallback={<CopyRobot />}
          className={cx(
            "size-6 [&>.rt-AvatarFallback]:bg-puntt-accent-7 [&>.rt-AvatarFallback]:text-base-black",
            { block },
          )}
        />
      );
    case "brand":
    case "Brand":
      return (
        <Avatar
          data-testid="ai-avatar"
          radius="full"
          color="amber"
          size="1"
          variant="solid"
          fallback={<BrandRobot />}
          className={cx("size-6", { block })}
        />
      );
    default:
      return (
        <Avatar
          data-testid="ai-avatar"
          radius="full"
          color="amber"
          size="1"
          variant="solid"
          fallback={<Robot />}
          className={cx("size-6", { block })}
        />
      );
  }
}

export function mapRuleToPersona(rule?: string) {
  if (rule === "text") return "copy";
  if (rule === "brand" || rule === "legal") return rule;
  return "";
}
