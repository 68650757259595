import { useMutation, useQuery } from "@tanstack/react-query";
import { type TLShapeId, type Editor } from "@tldraw/tldraw";
import { useEffect } from "react";

import { TicketComment } from "../../routes/tickets/components/CommentTool/CommentPinUtil";
import { updateGuidelines } from "../../services/brand-guidelines";
import {
  addReviewersToTicket,
  createComment,
  createProject,
  createRevision,
  deleteCommentMessage,
  editComment,
  fetchTicketComments,
  generateBrief,
  generateMoodBoard,
  getBriefTemplates,
  getProject,
  getProjects,
  reviewTicket,
  updateProject,
  updateRevision,
  sendToDesign,
  getPromptFromTitle,
  fetchDownloadableRevisionAsset,
  fetchRecommendedCreatives,
  fetchRecommendedReviewers,
  approveTicket,
  validateBrief,
  shareTicket,
  deleteTicket,
  createFolder,
  getFoldersAndTickets,
  deleteFolder,
  updateFolder,
  shareTicket2,
  requestAskPunttComment,
  getDownloadTicketLink,
  combineTickets,
  deleteRevision,
  getTicketVersionStatus,
} from "../../services/projects";
import { getWorkflows } from "../../services/workflows";
import { isNil } from "../fp";
import { useAppDispatch, useAppSelector } from "../hooks";
import { mergePunttProjectState } from "../slices/punttProjects";
import {
  setComments,
  setRevisions,
  setTicket,
  updateCommentById,
} from "../slices/ticket";

export function useProjects() {
  const query = useQuery({
    queryKey: ["projects"],
    queryFn: getProjects,
  });

  return query;
}

export function useProjectMutation() {
  const mutation = useMutation({
    mutationKey: ["create-project"],
    mutationFn: createProject,
  });

  return mutation;
}

export function useProject(ticketId: string) {
  const dispatch = useAppDispatch();

  const query = useQuery({
    queryKey: ["tickets", ticketId],
    queryFn: () => getProject(ticketId),
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setTicket(query.data));
    }
  }, [dispatch, query.data]);

  return query;
}

export function useGetTicketMutation() {
  return useMutation({
    mutationKey: ["ticket"],
    mutationFn: getProject,
  });
}

export function useEditProjectMutation(ticketId?: string) {
  const mutation = useMutation({
    mutationKey: ["ticket", ticketId],
    mutationFn: updateProject,
  });

  return mutation;
}

export function useBriefTemplates() {
  const query = useQuery({
    queryKey: ["brief-templates"],
    queryFn: getBriefTemplates,
  });

  return query;
}

export function useGenerateBriefMutation() {
  const mutation = useMutation({
    mutationKey: ["ai-brief-generator"],
    mutationFn: generateBrief,
  });

  return mutation;
}

export function useValidateBriefMutation() {
  const mutation = useMutation({
    mutationKey: ["validate-brief"],
    mutationFn: validateBrief,
  });

  return mutation;
}

export function useGenerateMoodBoardMutation() {
  const mutation = useMutation({
    mutationKey: ["mood-board-generator"],
    mutationFn: generateMoodBoard,
  });

  return mutation;
}

export function useNewCommentMutation() {
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state) => state.ticket.comments);
  const mutation = useMutation({
    mutationKey: ["new-comment"],
    mutationFn: createComment,
  });

  useEffect(() => {
    if (mutation.data != null) {
      const commentsCopy = comments.map((comment) => {
        return comment._id === mutation.data._id ? mutation.data : comment;
      });

      dispatch(setComments(commentsCopy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mutation.data]);

  return mutation;
}

export function useGetTicketComments(params: {
  ticketId: string;
  query: string;
}) {
  const dispatch = useAppDispatch();

  const query = useQuery({
    queryKey: ["ticket-comments", params.ticketId, params.query],
    queryFn: () => fetchTicketComments(params),
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setComments(query.data));
    }
  }, [dispatch, query.data]);

  return query;
}

export function useEditCommentMutation() {
  const dispatch = useAppDispatch();
  const mutation = useMutation({
    mutationKey: ["edit-comment"],
    mutationFn: editComment,
  });

  useEffect(() => {
    if (mutation.data != null) {
      dispatch(updateCommentById(mutation.data));
    }
  }, [dispatch, mutation.data]);

  return mutation;
}

export function useUpdateGuidelinesMutation() {
  const mutation = useMutation({
    mutationKey: ["update-brief"],
    mutationFn: updateGuidelines,
  });

  return mutation;
}

export function useDeleteCommentMutation({
  parentCommentId,
  editor,
}: {
  parentCommentId: string;
  editor: Editor | null | undefined;
}) {
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state) => state.ticket.comments);
  const mutation = useMutation({
    mutationKey: ["delete-project"],
    mutationFn: deleteCommentMessage,
  });
  function getShapeFromComment(commentId: string) {
    if (editor == null) return null;
    const shapes = editor.getCurrentPageShapes();
    const thisShape = shapes.find((shape) => {
      if (shape.type !== "comment") {
        return null;
      }

      const meta = JSON.parse(
        (shape.meta.comment as string) ?? "{}",
      ) as TicketComment;

      // we will encounter this error when we get there, in
      // which we will wrap this in a try...catch block.

      if (meta._id === commentId) {
        return shape;
      }

      return null;
    });

    return thisShape;
  }
  useEffect(() => {
    if (mutation.status === "success") {
      const replaceCommentIndex = comments.findIndex(
        (comment) => comment._id === parentCommentId,
      );
      const shape = getShapeFromComment(parentCommentId);

      // we deleted a parent comment, then we need to remove
      // the entire comment from the slice.
      if (parentCommentId === mutation.variables.messageId) {
        if (shape) {
          editor?.setSelectedShapes([shape.id]);
          editor?.deleteShapes([
            shape.id,
            shape.meta?.linkedAvatarId as TLShapeId,
          ]);
        }
        const commentsCopy = [...comments];
        commentsCopy.splice(replaceCommentIndex, 1);

        dispatch(setComments(commentsCopy));
        return;
      }

      // otherwise, we deleted a message and we need to just remove
      // that one message from the list. This is a pain in the ass
      // because reads from slices are read-only, so we have to
      // stringify-parse method in order to mutate a deep array.
      const commentsCopy = JSON.parse(
        JSON.stringify(comments),
      ) as typeof comments;
      const parentComment = commentsCopy[replaceCommentIndex];
      const repliesCopy = parentComment.messages.slice();
      const replaceMessageIndex = repliesCopy.findIndex(
        (message) => message._id === mutation.variables.messageId,
      );
      repliesCopy.splice(replaceMessageIndex, 1);
      parentComment.messages = repliesCopy;

      if (shape != null) {
        editor?.bringToFront([shape.id]);
        editor?.updateShape({
          ...shape,
          meta: {
            ...shape.meta,
            comment: JSON.stringify(parentComment),
          },
          props: {
            ...shape.props,
            commentId: parentCommentId,
          },
        });
        editor?.setSelectedShapes([shape.id]);
      }

      commentsCopy.splice(replaceCommentIndex, 1, parentComment);

      dispatch(setComments(commentsCopy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mutation.status, mutation.variables, parentCommentId]);

  return mutation;
}

export function useRevisionMutation() {
  const mutation = useMutation({
    mutationKey: ["create-revision"],
    mutationFn: createRevision,
  });

  return mutation;
}

export function useUpdateRevisionMutation() {
  const mutation = useMutation({
    mutationKey: ["update-revision"],
    mutationFn: updateRevision,
  });

  return mutation;
}

export function useDeleteRevisionMutation(ticketId: string) {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: ["delete-revision"],
    mutationFn: (revisionId: string) => deleteRevision(ticketId, revisionId),
    onSuccess(data) {
      if (data == null) {
        return;
      }

      const { boards } = data;

      dispatch(setRevisions(boards));
    },
  });
}

export function useAddReviewersMutation() {
  const mutation = useMutation({
    mutationKey: ["add-reviewers"],
    mutationFn: addReviewersToTicket,
  });

  return mutation;
}

export function useApproveTicketMutation(ticketId: string) {
  const mutation = useMutation({
    mutationKey: ["approve-ticket", ticketId],
    mutationFn: approveTicket,
  });

  return mutation;
}

export function useReviewRevisionMutation() {
  const mutation = useMutation({
    mutationKey: ["review-revision"],
    mutationFn: reviewTicket,
  });

  return mutation;
}

export function useSendToDesignMutation() {
  const mutation = useMutation({
    mutationKey: ["send-to-design"],
    mutationFn: sendToDesign,
  });

  return mutation;
}

export function usePromptFromTitleMutation() {
  const mutation = useMutation({
    mutationKey: ["prompt-title"],
    mutationFn: getPromptFromTitle,
  });

  return mutation;
}

export function useGetDownloadableAsset() {
  const mutation = useMutation({
    mutationKey: ["revision-asset"],
    mutationFn: fetchDownloadableRevisionAsset,
  });

  return mutation;
}

export function useFetchRecommendedCreatives(ticketId: string) {
  const query = useQuery({
    queryKey: ["ticket-recommended-creatives", ticketId],
    queryFn: () => fetchRecommendedCreatives(ticketId),
  });

  return query;
}

export function useFetchRecommendedReviewers(ticketId: string) {
  const query = useQuery({
    queryKey: ["ticket-recommended-reviewers", ticketId],
    queryFn: () => fetchRecommendedReviewers(ticketId),
  });

  return query;
}

export function useGetAndSendUrl() {
  const mutation = useMutation({
    mutationKey: ["send-url"],
    mutationFn: shareTicket,
  });

  return mutation;
}

export function useDeleteTicketMutation() {
  return useMutation({
    mutationKey: ["delete-ticket"],
    mutationFn: deleteTicket,
  });
}

export function useWorkflows() {
  const query = useQuery({
    queryFn: getWorkflows,
    queryKey: ["workflows"],
  });

  return query;
}

export function useCreateFolderMutation() {
  const mutation = useMutation({
    mutationKey: ["create-folder"],
    mutationFn: createFolder,
  });

  return mutation;
}

export function useFoldersAndTicketsMutation(folderId?: string) {
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.punttProjects.folders);
  const tickets = useAppSelector((state) => state.punttProjects.tickets);

  return useMutation({
    mutationFn: getFoldersAndTickets,
    mutationKey: ["folders-and-tickets", folderId],
    onSuccess(data) {
      dispatch(
        mergePunttProjectState({
          folders: [...folders, ...(data.folders ?? [])],
          tickets: [...tickets, ...data.tickets],
          more: data.more,
          isLoadingMore: false,
        }),
      );
    },
  });
}

export function useDeleteFolderMutation() {
  const mutation = useMutation({
    mutationKey: ["delete-folder"],
    mutationFn: deleteFolder,
  });
  return mutation;
}

export function useUpdateFolderMutation() {
  const mutation = useMutation({
    mutationKey: ["update-folder"],
    mutationFn: updateFolder,
  });
  return mutation;
}

export function useShareTicketLink() {
  return useMutation({
    mutationKey: ["tickets", "share", "link"],
    mutationFn: shareTicket2,
  });
}

export function useShareTicket() {
  return useMutation({
    mutationKey: ["tickets", "share"],
    mutationFn: shareTicket2,
  });
}

export const useCreateAskPunttComment = () => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state) => state.ticket.comments);

  return useMutation({
    mutationKey: ["create-ask-puntt-comment"],
    mutationFn: requestAskPunttComment,
    onSuccess(data) {
      if (!isNil(data)) {
        const replaceCommentIndex = comments.findIndex(
          (comment) => comment._id === data._id,
        );
        const commentsCopy = [...comments];
        commentsCopy.splice(replaceCommentIndex, 1, data);

        dispatch(setComments(commentsCopy));
      }
    },
  });
};

export function useDownloadTicketLink() {
  return useMutation({
    mutationKey: ["tickets", "download"],
    mutationFn: getDownloadTicketLink,
  });
}

export const useCombineTickets = () => {
  return useMutation({
    mutationKey: ["combine-tickets"],
    mutationFn: combineTickets,
  });
};

export function useTicketVersionStatusMutation(ticketId: string) {
  return useMutation({
    mutationKey: ["tickets", ticketId, "status"],
    mutationFn(versionId: string) {
      return getTicketVersionStatus({ ticketId, versionId });
    },
  });
}
