import { Button, Grid, Heading, Text, TextField, Link } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { Link as RouterLink } from "@tanstack/react-router";
import cx from "classnames";
import { type FormEventHandler, useState } from "react";

import { LoginLayout } from "../../components/layouts/login";
import { sendPasswordResetEmail } from "../../services/auth";
import { loginRoute } from "../login/route";

function useForgotPasswordMutation() {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const forgotPasswordMutation = useMutation({
    mutationKey: ["forgot-password"],
    mutationFn: sendPasswordResetEmail,
    onError: async (error: Response) => {
      const data = (await error.json()) as { message: string; stack: string };
      setErrorMessage(data.message);
    },
  });

  return { ...forgotPasswordMutation, errorMessage };
}

export function ForgotPasswordView() {
  const { errorMessage, ...mutation } = useForgotPasswordMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form as HTMLFormElement);

    mutation.mutate(formData.get("email") as string);
  };

  if (mutation.isSuccess) {
    return (
      <LoginLayout>
        <Heading as="h1" weight="medium" className="mb-4">
          Thank you for requesting a password reset. Please check your email to
          complete the process.
        </Heading>

        <RouterLink to={loginRoute.to} data-testid="login-existing-link">
          <Link size="2" className="puntt-link underline-offset-4">
            <span className="font-medium underline decoration-puntt-accent-5">
              Log In
            </span>{" "}
            to an existing account
          </Link>
        </RouterLink>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <Heading
        as="h1"
        weight="medium"
        className="mb-8 text-base-black"
        data-testid="reset-password-description"
      >
        Reset your password.
      </Heading>

      <form onSubmit={handleSubmit} className="mb-4 grid gap-6">
        <Grid gap="3">
          <Text weight="medium" data-testid="email-field-label">
            Work Email
          </Text>
          <TextField.Root
            required
            name="email"
            id="email-input"
            placeholder="name@work-email.com"
            type="email"
            autoComplete="true"
            disabled={mutation.isPending}
            autoFocus
            className="[&>input]:w-full"
            data-testid="email-field"
          />
        </Grid>

        <Text
          color="red"
          size="2"
          data-testid="mfa-error-message"
          className={cx({ hidden: !mutation.isError })}
        >
          {errorMessage}
        </Text>

        <Button
          type="submit"
          disabled={mutation.isPending}
          loading={mutation.isPending}
          className="mt-2 [&>span]:justify-center"
          data-testid="form-submit-cta"
        >
          Reset Password
        </Button>
      </form>

      <RouterLink to={loginRoute.to} data-testid="login-existing-link">
        <Link size="2" className="puntt-link underline-offset-4">
          <span className="font-medium underline decoration-puntt-accent-5">
            Log In
          </span>{" "}
          to an existing account
        </Link>
      </RouterLink>
    </LoginLayout>
  );
}
