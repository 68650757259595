import { UploadSimple } from "@phosphor-icons/react";
import { Flex, Text } from "@radix-ui/themes";
// eslint-disable-next-line import/named

import { TutorialHeader } from "./components/TutorialHeader";

import { HighlightModal } from "../../components/HighlightModal";
import TutorialThumb from "../../images/tutorial-main.png";
import { useAppSelector } from "../../utils/hooks";
import { TicketCard } from "../tickets/components/TicketGrid";

export function TutorialTickets() {
  const sampleTicket = {
    _id: "670945a8181bcfce90d913ae",
    enterprise: "65566c3b3b17d6059e5390e2",
    status: "wip",
    title: "Demo Image",
    workflow: {
      name: "MG Design Review only",
      enterprise: "65566c3b3b17d6059e5390e2",
      steps: [
        {
          name: "Design Review",
          type: "review",
          config: {
            canMarketingAccess: true,
            requiresApproval: {
              canAssignReviewers: {
                roles: [
                  "ops",
                  "ENTERPRISE_OPS",
                  "marketing",
                  "ENTERPRISE_MARKETING",
                  "creative",
                  "ENTERPRISE_CREATIVE",
                ],
                userIds: [],
              },
              canBeAssigned: {
                roles: [],
                userIds: [],
              },
              canAlwaysApprove: {
                roles: ["admin"],
                userIds: [],
              },
              notifyOfRequestToAssign: {
                roles: ["admin"],
                userIds: [],
              },
              requiredReviewersForApproval: 1,
              requireReApprovalAfterRevisions: true,
            },
          },
          startedAt: "2024-10-11T15:35:04.437Z",
        },
      ],
      _id: "66607f71a759d3c591213d41",
      createdAt: "2024-06-05T15:08:33.069Z",
      updatedAt: "2024-06-05T15:08:33.069Z",
    },
    workflowStep: 0,
    participants: [
      {
        _id: "615f5203e1cb445ef2486b77",
        name: "Steph Nachemja-Bunton",
        avatar: "users/5f69b04fc370db2265353ef8/avatar.png",
      },
    ],
    revisionBoards: [],
    createdAt: "2024-10-11T15:35:04.455Z",
    updatedAt: "2024-10-11T15:35:04.455Z",
    briefBoard: {
      _id: "",
      createdAt: "2024-10-11T18:08:01.374Z",
      updatedAt: "2024-10-11T18:08:01.374Z",
    },
    isPending: false,
    thumbnails: [TutorialThumb],
    documentImportType: "PNG",
    totalFiles: 1,
    totalRevisions: 1,
  };

  const { tutorialStep } = useAppSelector((state) => state.onboarding);

  return (
    <>
      <article className="h-screen gap-6 overflow-hidden bg-puntt-neutral-gray-8 px-10 py-6">
        {/* This is used in both the no tickets view and the "File Upload" option in the "New" dropdown */}

        <TutorialHeader />

        <Flex gap="5" wrap="wrap">
          <HighlightModal
            tooltipPosition={{ top: 30, left: 150 }}
            order={0}
            show
          >
            <div className="size-0" />
          </HighlightModal>
          <HighlightModal
            tooltipPosition={{ top: 0, left: 325 }}
            order={2}
            show
          >
            <TicketCard
              {...sampleTicket}
              onDeleteTicket={() => null}
              onTicketCardClick={() => null}
              onFolderCardClick={() => null}
              handleUploadFiles={() => null}
              // @ts-expect-error TS2322: this is just a tutorial, doesn't matter
              revisionMutation={{}}
              onDeleteFolder={() => null}
              onRenameFolder={() => null}
            />
          </HighlightModal>
        </Flex>
        {tutorialStep == 1 && (
          <div className="fixed inset-x-0 bottom-0 z-[400] ml-[300px] flex h-1/4 flex-col justify-end bg-base-white bg-gradient-to-t from-violet-violet7 to-transparent text-puntt-accent-10 transition-all">
            <div className="m-6 flex flex-col items-center justify-end gap-6 rounded-xl border-2 border-dashed border-violet-violet8 p-6">
              <UploadSimple size={36} />
              <Text size="6">Drop Files or Folders to Upload</Text>
            </div>
          </div>
        )}
      </article>
    </>
  );
}
