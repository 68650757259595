import {
  // eslint-disable-next-line import/named
  createRootRouteWithContext,
  Outlet,
  useSearch,
  useRouter,
} from "@tanstack/react-router";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import { CustomErrorComponent } from "./customErrorComponent";
import { NotFoundComponent } from "./notFoundComponent";

import { AnalyticsProvider } from "../../utils/analytics";
import { useAppSelector } from "../../utils/hooks";
import { searchParser } from "../../utils/parsers/search";
import { useEnterprises } from "../../utils/queries/enterprises";
import { type queryClient } from "../../utils/queryClient";
import { derive } from "../../utils/slices/auth";
import { store } from "../../utils/store";
import { generateUUID } from "../../utils/uuid";

export const rootRoute = createRootRouteWithContext<{
  queryClient: typeof queryClient;
}>()({
  validateSearch(search: Record<string, unknown>) {
    return searchParser.parse(search);
  },
  beforeLoad() {
    store.dispatch(derive());
  },
  component: function Component() {
    const user = useAppSelector((state) => state.auth.value);
    const posthog = usePostHog();
    const search = useSearch({ strict: false });
    const { data: enterprises } = useEnterprises();
    const router = useRouter();

    const currentEnterprise = enterprises?.find(
      (e) => e._id === user?.enterpriseId,
    );

    // @ts-expect-error TS2339: `window` is strictly typed
    window.UnsafeDeps = Object.assign({}, window.UnsafeDeps, {
      user,
      enterprise: currentEnterprise,
    });

    useEffect(() => {
      if (user != null) {
        posthog?.identify(user.userID, {
          email: user.email,
          name: user.name,
          role: user.role,
          enterpriseId: currentEnterprise?._id ?? user?.enterpriseId,
          enterpriseName:
            currentEnterprise?.name ?? user.enterpriseName ?? user.company,
          visitId: generateUUID(),
        });

        posthog?.group("enterprise", currentEnterprise?._id as string);
      }
    }, [posthog, user]);

    // remove the token from the URL if it is a shared ticket token
    if (search.t != null && (!user?.ticketId || !user?.folderId)) {
      const { t: _t, ...rest } = search;
      router.navigate({
        to: router.state.location.pathname,
        search: rest,
        replace: true,
      });
      return null;
    }

    return (
      <AnalyticsProvider>
        <Outlet />
      </AnalyticsProvider>
    );
  },
  errorComponent: CustomErrorComponent,
  notFoundComponent: NotFoundComponent,
});
