import { usePostHog } from "posthog-js/react";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactNode,
} from "react";

type Context = Record<string, any>;

interface AnalyticsContextType {
  setContext: (context: Context) => void;
  clearContext: () => void;
  capture: (event: string, eventContext?: Context) => void;
  startTimeRef?: React.MutableRefObject<number | null>;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
  undefined,
);
//TODO: Add extended functionality for timing events
export const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const posthog = usePostHog();
  const [context, setContextState] = useState<Context>({});

  const setContext = (newContext: Context) => {
    setContextState((prevContext) => ({ ...prevContext, ...newContext }));
  };

  const clearContext = () => {
    setContextState({});
  };

  const capture = (event: string, eventContext?: Context) => {
    const mergedContext = { ...context, ...eventContext };
    posthog.capture(event, mergedContext);
  };

  return (
    <AnalyticsContext.Provider value={{ setContext, clearContext, capture }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (componentName?: string): AnalyticsContextType => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  const { setContext, clearContext, capture } = context;

  const startTimeRef = useRef<number | null>(null);
  // If we're rendering a bunch of the same component, don't report the same duration event over and over for all of them
  const memoizedComponentName = useMemo(() => componentName, [componentName]);

  useEffect(() => {
    startTimeRef.current = performance.now();

    return () => {
      if (startTimeRef.current && memoizedComponentName) {
        const duration = performance.now() - startTimeRef.current;
        capture(`${memoizedComponentName}_duration`, { duration });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedComponentName]);

  return { setContext, clearContext, capture, startTimeRef };
};

export function errorAnalyticsPayload(error: unknown) {
  if (!(error instanceof Error)) {
    return {
      error_message: String(error),
      error_name: "Non-Error thrown",
      error_stack: "",
    };
  }

  return {
    error_message: typeof error.message === "undefined" ? "" : error.message,
    error_name: typeof error.name === "undefined" ? "" : error.name,
    error_stack: typeof error.stack === "undefined" ? "" : error.stack,
    ...(error.cause instanceof Error
      ? {
          error_cause_message:
            typeof error.cause.message === "undefined"
              ? ""
              : error.cause.message,
          error_cause_name:
            typeof error.cause.name === "undefined" ? "" : error.cause.name,
          error_cause_stack:
            typeof error.cause.stack === "undefined" ? "" : error.cause.stack,
        }
      : { error_cause: String(error.cause) }),
  };
}
