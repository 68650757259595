import { ArrowLineLeft, ArrowLineRight } from "@phosphor-icons/react";
import { IconButton } from "@radix-ui/themes";
import cx from "classnames";
import { type ComponentProps, type ElementRef, forwardRef } from "react";

import { useSidebar } from "./useSidebar";

type ExtendedComponentProps = ComponentProps<typeof IconButton>;

export const SidebarTrigger = forwardRef<
  ElementRef<typeof IconButton>,
  ExtendedComponentProps
>((props, ref) => {
  const { className, onClick, ...rest } = props;
  const { open, toggleSidebar } = useSidebar();

  return (
    <IconButton
      ref={ref}
      data-sidebar="trigger"
      variant="surface"
      color="gray"
      radius="full"
      size="1"
      className={cx(
        "absolute inset-y-1/2 z-20 -translate-y-1/2 opacity-0 transition-all ease-out group-hover:opacity-100 group-data-[side=left]:-right-3 group-data-[side=right]:-left-3",
        className,
      )}
      onClick={(event) => {
        onClick?.(event);
        toggleSidebar();
      }}
      {...rest}
    >
      {open ? (
        <ArrowLineLeft className="group-data-[side=right]:rotate-180" />
      ) : (
        <ArrowLineRight className="group-data-[side=right]:rotate-180" />
      )}
      <span className="sr-only">Toggle Sidebar</span>
    </IconButton>
  );
});

SidebarTrigger.displayName = "SidebarTrigger";
