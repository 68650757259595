import React, { useState, useEffect, useCallback, useReducer } from "react";

import { Snackbar } from "./Snackbar";
import { type SnackbarProps } from "./Snackbar";

import { ArrayEmitter } from "../foundation/base";

export interface SnackbarQueueProps<T> extends Omit<SnackbarProps, "message"> {
  /**
   * An array of event-emitting Snackbars
   */
  messages: ArrayEmitter<T>;
}

export function SnackbarQueue<T>({
  messages,
  ...defaultSnackbarProps
}: SnackbarQueueProps<T>) {
  const currentMessage = messages.array[0];
  const SNACKBAR_ANIMATE_DURATION = 300;

  const [timesNotified, forceUpdate] = useReducer((value) => value + 1, 0);
  const [message, setMessage] = useState(messages.array[0]);

  const removeMessage = useCallback(
    (m: T) => {
      if (m) {
        messages.remove(m);
      }
    },
    [messages],
  );

  useEffect(() => {
    let timerId: number;

    const doChange = () => {
      if (messages.array[0] !== message) {
        forceUpdate();

        timerId = window.setTimeout(
          () => setMessage(messages.array[0]),
          SNACKBAR_ANIMATE_DURATION,
        );
      }
    };

    messages.on("change", doChange);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      messages.off("change", doChange);
    };
  }, [messages, message]);

  const {
    message: body = "",
    title = "",
    onClose,
    ...messageSnackbarProps
  } = (message as SnackbarProps) || {};

  // We can consider an `open` state if we have a message
  // in the queue and the current message is the message in
  // our state.
  const open = message && message === currentMessage;

  return (
    <Snackbar
      {...defaultSnackbarProps}
      {...messageSnackbarProps}
      open={open}
      message={body}
      title={title || `Notified ${timesNotified} times`}
      onClose={(evt) => {
        if (onClose) {
          onClose(evt);
        }

        removeMessage(message);
      }}
    />
  );
}

export const createSnackbarQueue = () => {
  const messages = new ArrayEmitter<SnackbarProps>();

  return {
    messages,
    clearAll: () => messages.empty(),
    notify: (message: SnackbarProps) => {
      messages.push(message);

      return {
        close: () => {
          messages.remove(message);
        },
      };
    },
  };
};
