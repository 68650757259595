/**
 * This tooltip is different than the pre-themed version that comes from
 * @radix-ui/themes. We use a custom tooltip on the app sidebar where we need
 * more control over the styling. It should only be used within the context of a
 * sidebar in its collapsed state.
 */

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import cx from "classnames";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";

export const TooltipProvider = TooltipPrimitive.Provider;
export const Tooltip = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;

export const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>((props, ref) => {
  const { className, sideOffset = -40, ...rest } = props;

  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={twMerge(
          cx(
            "group/menu-item-tooltip puntt-link z-50 overflow-hidden rounded-md bg-puntt-neutral-gray-4 px-3 py-2 shadow-md",
            "data-[side=right]:animate-reveal-in-from-left",
            "data-[side=right]:data-[state=closed]:animate-reveal-out-from-left",
            "data-[side=left]:animate-reveal-in-from-right",
            "data-[side=left]:data-[state=closed]:animate-reveal-out-from-right",
            className,
          ),
        )}
        {...rest}
      />
    </TooltipPrimitive.Portal>
  );
});

TooltipContent.displayName = TooltipPrimitive.Content.displayName;
