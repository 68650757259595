import { Icon } from "@mg/dali/src";
import cx from "classnames";
import { type HTMLAttributes, useState, useRef, useCallback } from "react";

import { generateUUID } from "../utils/uuid";

type CarouselProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Automatically advances to the next item in the carousel; by default, the
   * images are advanced every 3000 milliseconds, but can be overridden by
   * supplying a number as the `autoPlay` value; defaults to `false`
   */
  autoPlay?: boolean | number;
  /**
   * Aligns carousel images within the scroll-snap carousel element; defaults to
   * "start"
   */
  cellAlign?: "none" | "start" | "end" | "center";
  /**
   * Enables dragging and flicking. Enabled by default when the carousel has 2
   * or more slides.
   */
  draggable?: boolean;
  /**
   * An array of valid image sources
   */
  images: string[];
  /**
   * Zero-based index of the initially selected carousel image
   */
  initialIndex?: number;
  /**
   * Pauses auto play when the mouse is over the container; defaults to `true`,
   * regardless of `autoPlay` state.
   */
  pauseAutoPlayOnHover?: boolean;
  /**
   * At the end of cells, wrap around to the other end for infinite scrolling;
   * defaults to `false`
   */
  wrapAround?: boolean;
};

const snapAlign: Record<NonNullable<CarouselProps["cellAlign"]>, string> = {
  none: "snap-align-none",
  start: "snap-start",
  end: "snap-end",
  center: "snap-center",
};

export function Carousel(props: CarouselProps) {
  const {
    // TODO: nice to have, not a need to have
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    autoPlay = false,
    cellAlign = "start",
    className,
    // TODO: implement for mobile
    draggable,
    images,
    initialIndex = 0,
    // TODO: nice to have, not a need to have
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pauseAutoPlayOnHover = true,
    wrapAround = false,
    ...rest
  } = props;

  const containerId = useRef(generateUUID());
  const carouselRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const baseControlClasses =
    "hover:bg-carbon-100/50 rounded-lg disabled:opacity-30";

  function scrollIntoView(index: number) {
    if (carouselRef.current == null) {
      return;
    }

    const child = carouselRef.current.children[index];

    if (child != null) {
      child.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }

  const handleNext = useCallback(() => {
    return setCurrentIndex((curr) => {
      const isLast = curr === images.length - 1;
      let next = curr;

      if (isLast && wrapAround) {
        next = 0;
      } else if (!isLast) {
        next += 1;
      }

      scrollIntoView(next);
      return next;
    });
  }, [images, wrapAround]);

  const handlePrevious = useCallback(() => {
    return setCurrentIndex((curr) => {
      const isFirst = curr === 0;
      let next = curr;

      if (isFirst && wrapAround) {
        next = images.length - 1;
      } else if (!isFirst) {
        next = curr - 1;
      }

      scrollIntoView(next);
      return next;
    });
  }, [images, wrapAround]);

  function handleDotClick(index: number) {
    scrollIntoView(index);
    return setCurrentIndex(index);
  }

  return (
    <div className={cx("relative", className)}>
      <figure
        ref={carouselRef}
        className={cx(
          "carousel-container",
          // scroll snapping
          "flex snap-x snap-mandatory overflow-x-auto scroll-smooth rounded-[inherit]",
        )}
        {...rest}
        data-container-id={containerId.current}
      >
        {images.map((image, i) => (
          <img
            key={`.${i}.${image}`}
            src={image}
            alt={image}
            className={cx(
              "h-full w-full flex-none object-cover",
              snapAlign[cellAlign],
            )}
            loading="lazy"
            fetchPriority={image.endsWith(".gif") ? "low" : "auto"}
          />
        ))}
      </figure>

      <Icon.IconContext.Provider
        value={{ weight: "fill", color: "rgb(var(--base-black))", size: 16 }}
      >
        <section className="absolute bottom-0 flex w-full items-center justify-between bg-carbon-50 px-4 py-1.5">
          <button
            // TODO: this breaks scroll to book end images unless `wrapAround`
            // is enabled
            // disabled={
            //   images.length === 1 || (!wrapAround && currentIndex === 0)
            // }
            onClick={(e) => {
              e.stopPropagation();
              handlePrevious();
            }}
            className={cx(baseControlClasses, "p-1")}
          >
            <Icon.CaretLeft />
          </button>

          <nav className="flex">
            {Array.from({ length: images.length }, (_, i) => (
              <button
                disabled={images.length === 1}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDotClick(i);
                }}
                key={i}
                className={cx(baseControlClasses, "p-2")}
              >
                <span
                  className={cx("block h-1.5 w-1.5 rounded-full", {
                    "bg-carbon-400": currentIndex !== i,
                    "bg-base-black": currentIndex === i,
                  })}
                />
              </button>
            ))}
          </nav>

          <button
            // TODO: this breaks scroll to book end images unless `wrapAround`
            // is enabled
            // disabled={
            //   images.length === 1 ||
            //   (!wrapAround && currentIndex === images.length - 1)
            // }
            onClick={(e) => {
              e.stopPropagation();
              handleNext();
            }}
            className={cx(baseControlClasses, "p-1")}
          >
            <Icon.CaretRight />
          </button>
        </section>
      </Icon.IconContext.Provider>
    </div>
  );
}
