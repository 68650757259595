import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import * as Tooltip from "@radix-ui/react-tooltip";
import {
  // eslint-disable-next-line import/named
  Link,
  // eslint-disable-next-line import/named
  createRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import cx from "classnames";
import { EditorState } from "draft-js";
// @ts-expect-error TS7016: Could not find a declaration file for module 'draft-js-export-markdown'.
import { stateToMarkdown } from "draft-js-export-markdown";
import { useEffect, useRef, useState } from "react";

import { AvatarWithInitials } from "../../components/AvatarWithInitials";
import { Markdown } from "../../components/Markdown";
import { RichEditor } from "../../components/RichEditor";
import {
  useMessages,
  useSendMessage,
  useThread,
} from "../../utils/queries/messages";
import { messagesRoute, useMessagesContext } from "../messages/route";
import { profileRoute } from "../profile/route";

export const threadRoute = createRoute({
  getParentRoute: () => messagesRoute,
  path: "$threadId",
  component: function Component() {
    const { threadId } = useParams({
      from: "/authenticated/messages/$threadId",
    });
    const navigate = useNavigate();
    const sendMessageMutation = useSendMessage();
    const [message, setMessage] = useState(() => EditorState.createEmpty());
    const thread = useThread(threadId);
    const messages = useMessages();
    const lastMessageRef = useRef<HTMLDivElement>(null);
    const { mobileDrawerVisible, setMobileDrawerVisible } =
      useMessagesContext();

    useEffect(() => {
      if (lastMessageRef.current) {
        lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thread.data?.messages, lastMessageRef.current]);

    useEffect(() => {
      setMobileDrawerVisible(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId]);

    if (thread.isLoading) {
      return (
        <div className="absolute inset-0 h-screen w-screen flex-1 bg-base-white md:relative md:size-full">
          <Typography
            size="xl"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center"
          >
            Loading...
            <Icon.CircleNotch className="mx-auto animate-spin" />
          </Typography>
        </div>
      );
    }

    if (!thread.data) return null;

    const sendMessage = () => {
      const contentState = message.getCurrentContent();
      const payload = {
        content: stateToMarkdown(contentState),
        recipientIds: thread.data.recipients.map((r) => r._id),
        threadId,
        bcc: [],
      };

      sendMessageMutation.mutate(
        { payload },
        {
          async onSuccess() {
            setMessage(EditorState.createEmpty());
            thread.refetch();
            messages.refetch();
          },
        },
      );
    };

    return (
      <div
        className={cx(
          "absolute inset-0 flex w-screen flex-1 flex-col bg-base-white pb-24 transition-transform md:static md:w-full md:pb-0",
          {
            "translate-x-3/4": mobileDrawerVisible,
          },
        )}
      >
        <div className="flex items-center justify-between gap-x-2 overflow-x-auto border-b border-carbon-200 px-5 py-4">
          {thread.data?.recipients.slice(0, 3).map((recipient, i) => (
            <div className="flex items-center gap-x-2" key={recipient._id}>
              <AvatarWithInitials
                avatar={recipient.avatar}
                name={recipient.name}
                size={11}
              />
              <Typography
                size="xl"
                weight="medium"
                className={cx({
                  "mr-2": i !== thread.data.recipients.length - 1,
                })}
              >
                {recipient.name}
              </Typography>
            </div>
          ))}
          {thread.data?.recipients.length > 3 && (
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div className="flex size-11 items-center justify-center rounded-full bg-carbon-200">
                    <Typography>
                      +{thread.data.recipients.length - 3}
                    </Typography>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="TooltipContent" sideOffset={5}>
                    <div className="mt-2 flex flex-col gap-y-2">
                      {thread.data?.recipients.slice(3).map((recipient) => (
                        <div
                          className="flex items-center gap-x-2"
                          key={recipient._id}
                        >
                          <AvatarWithInitials
                            avatar={recipient.avatar}
                            name={recipient.name}
                            size={11}
                          />
                          <Typography size="xl" weight="medium">
                            {recipient.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                    <Tooltip.Arrow className="TooltipArrow" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          )}
          <ButtonNew
            variant="ghost"
            onClick={() => {
              navigate({ to: messagesRoute.to });
            }}
          >
            <Icon.X size={24} />
          </ButtonNew>
        </div>
        <div className="flex-1 shrink overflow-y-auto">
          {thread.data.messages.map((m, i) => {
            const inputDateObj = new Date(m.createdAt);
            const messageTime = `${inputDateObj
              .getHours()
              .toString()
              .padStart(2, "0")}:${inputDateObj
              .getMinutes()
              .toString()
              .padStart(2, "0")}`;

            return (
              <div
                key={`${m.createdAt}.${i}`}
                className="relative mb-1 px-5 py-3 text-base-black"
                ref={
                  i === thread.data.messages.length - 1 ? lastMessageRef : null
                }
              >
                <div className="flex gap-2">
                  <div>
                    <AvatarWithInitials
                      avatar={m.sender.avatar}
                      name={m.sender.name}
                      size={11}
                    />
                  </div>
                  <div className="mt-1 w-full">
                    <div className="flex w-full justify-between">
                      <Typography>
                        <Link
                          className="puntt-link inline"
                          to={profileRoute.to}
                          params={{ profileId: m.sender._id }}
                        >
                          <Typography as="span" weight="medium">
                            {m.sender.name}
                          </Typography>
                        </Link>
                      </Typography>
                      <Typography as="span" size="xs">
                        {messageTime}
                      </Typography>
                    </div>
                    <Markdown>{m.content}</Markdown>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex w-full items-end px-4 pb-4">
          <RichEditor
            editorState={message}
            onChange={setMessage}
            placeholder="Send a message"
            className="mr-4"
            actions={[
              <ButtonNew
                size="sm"
                variant="outlined"
                key="send-message"
                onClick={sendMessage}
                disabled={
                  !message.getCurrentContent().hasText() ||
                  sendMessageMutation.isPending
                }
                isLoading={sendMessageMutation.isPending}
              >
                <Icon.PaperPlaneRight />
              </ButtonNew>,
            ]}
          />
        </div>
      </div>
    );
  },
  errorComponent() {
    return "Uh oh! That one isn't found";
  },
});
